import React from 'react';
import { ThemeProvider } from '@mui/material';
import { muiTheme } from './styles/matherial-theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from 'react-router-dom';
import { router } from 'router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const queryClient = new QueryClient();

export const App: React.FC = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={muiTheme}>
                <div className="bg-[#181818] py-1 text-left !font-sans text-[#262626]">
                    <div className="mx-auto max-w-6xl">
                        <RouterProvider router={router} />
                    </div>
                </div>
                <ToastContainer />
            </ThemeProvider>
        </QueryClientProvider>
    );
};

console.log('may 20, 2024, 18:33');
