import { TRACKING_CLASSNAME_FOR_VIEW_AND_INQUIRY_BUTTON } from 'gtag/analytics.seo';
import React from 'react';
import { clsxm } from 'utils/clsxm';
import { StyledButton } from '../components/StyledButton';

export const BookingFormWrapper: React.FC<{
    onSubmit: () => void;
    buttonText: string;
    children: React.ReactNode;
    disabled?: boolean;
    loading?: boolean;
}> = ({ children, onSubmit, buttonText, disabled, loading }) => {
    return (
        <form onSubmit={onSubmit}>
            {children}
            <div className="mt-10">
                <StyledButton
                    className={clsxm(
                        'flex w-full items-center justify-center gap-4',
                        TRACKING_CLASSNAME_FOR_VIEW_AND_INQUIRY_BUTTON,
                    )}
                    type="submit"
                    loading={loading}
                    disabled={disabled}>
                    {buttonText}
                </StyledButton>
            </div>
        </form>
    );
};
