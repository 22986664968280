import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PixelConversionSignal } from 'components/PixelConversionSignal';
import { Check } from '@mui/icons-material';

export const MembershipCardSentBlock: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="mt-3 flex justify-center rounded-[1.25rem] p-8 text-white">
            <div className="flex flex-col">
                <div className="flex flex-col items-center gap-4 md:flex-row">
                    <div className="flex h-[48px] w-[48px] items-center justify-center rounded-full bg-accent">
                        <Check />
                    </div>
                    <div className="flex flex-col gap-1">
                        <Typography className="text-xl font-semibold uppercase text-slate-100">
                            {t('membershipCard.requestSent')}
                        </Typography>
                        <Typography className="text-sm text-slate-500">
                            {t('weWillGetBack')}
                        </Typography>
                    </div>
                </div>
                <PixelConversionSignal />
            </div>
        </div>
    );
};
