import { useInquiryQueryParams } from 'api/main.api.hook';
import { useEffect, useState } from 'react';

type AdSource = {
    utm_source?: string;
    utm_campaign?: string;
    utm_medium?: string;
    utm_content?: string;
    utm_term?: string;
};

type SimpleFormValuesType = {
    ['From 1']?: string;
    ['From 2']?: string;
    ['To 1']?: string;
    ['To 2']?: string;
    ['Departure Date 1']?: string;
    ['Departure Date 2']?: string;
    ['Return Date']?: string;
    TripType: 'Multi City' | 'Round Trip' | 'One Way';
} & AdSource;

const AD_SOURCE_STORAGE_KEY_WIDGET_OSDB = 'adSource';
export const SIMPLE_FORM_STORAGE_KEY = 'formValues';

export const useSaveAdSourceParams = () => {
    const [queryParams] = useInquiryQueryParams();

    useEffect(() => {
        if (Object.values(queryParams)?.find(v => !!v)) {
            localStorage.setItem(AD_SOURCE_STORAGE_KEY_WIDGET_OSDB, JSON.stringify(queryParams));
        }
    }, [queryParams]);
};

export const useGetSimpleFormValues = () => {
    const [simpleFormValues, setSimpleFormValues] = useState<SimpleFormValuesType | null>(null);

    useEffect(() => {
        const simpleFormSource = localStorage.getItem(SIMPLE_FORM_STORAGE_KEY);
        if (simpleFormSource) {
            try {
                const formValues: SimpleFormValuesType = JSON.parse(simpleFormSource);
                setSimpleFormValues(formValues);
            } catch {}
        }
    }, []);

    return simpleFormValues;
};

export const useGetCurrentAdSourceParams = () => {
    const [queryParams] = useInquiryQueryParams();

    if (Object.values(queryParams)?.find(v => !!v)) {
        return queryParams;
    }

    const source = localStorage.getItem(AD_SOURCE_STORAGE_KEY_WIDGET_OSDB);
    if (source) {
        try {
            const adSource: AdSource = JSON.parse(source);
            return adSource;
        } catch {}
    }

    const adSourceSimpleForm = useGetSimpleFormValues();

    if (adSourceSimpleForm) {
        return adSourceSimpleForm;
    }

    return null;
};
