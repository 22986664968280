import { detect } from 'detect-browser';
import { useMemo } from 'react';

export const useDeviceDetails = () => {
    const browser = useMemo(() => detect(), []);

    return {
        deviceSizeType: calcDeviceSize(),
        deviceBrowserName: browser?.name || undefined,
        deviceBrowserVersion: browser?.version || undefined,
        deviceOperatingSystem: browser?.os || undefined,
    };
};

const calcDeviceSize = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    let type = 'Mobile';
    if (width > 1024) {
        type = 'Desktop';
    } else if (width > 768) {
        type = 'Laptop';
    } else if (width > 480) {
        type = 'Tablet';
    }
    return `${type} ${width}x${height}`;
};

/*

320px — 480px: Mobile devices
481px — 768px: iPads, Tablets
769px — 1024px: Small screens, laptops
1025px — 1200px: Desktops, large screens
1201px and more —  Extra large screens, TV
*/
