import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageSwitcher } from '../components/LanguageSwitcher';
import { Typography, Tabs, Tab, Select, MenuItem } from '@mui/material';
import { OneWayForm } from './OneWayForm';
import { RoundTripForm } from './RounTripForm';
import { MultiCityForm } from './MultiCityForm';
import { Flight } from '../Flight/Flight';
import { Map } from '../components/map/Map';
import { EDefaultTab, SearchFormInDto, WidgetConfigOutDto } from '../swagger-types';
import { useBookingSearch, useGetWidgetConfig } from '../api/main.api.hook';
import { ContainerLoader } from '../components/container-loader/ContainerLoader';
import { InquireForm } from '../InquireForm/InquireForm';
import { BookingSearchResultWrapper } from './BookingSearchResultWrapper';
import { CURRENCIES, CURRENCY_KEY } from '../exchange-manager/exchange-manager';
import { useQuoteForm } from './useQuoteForm';
import { useGetHeightOfIframe } from '../hooks/useGetHeightOfIframe';
import { differenceInDays } from 'date-fns';
import { useHandler } from 'hooks/useHandler.hook';
import { gtag } from 'gtag/gtag';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { AdSource } from './AdSource';
import { useGetSimpleFormValues } from 'hooks/localStorage.hooks';
import { mainApi } from 'api/main.api';
import { LeadCaptureFlow } from './lead-capture/LeadCaptureFlow';
import { PersistantLeadProvider } from './lead-capture/useSharedLeadState';
import { useGoogleId } from 'gtag/useGoogleId';
import { MembershipCardFlow } from './membership-card/MembershipCardFlow';

const SEARCH_RESULTS_ID = 'search_results_start';

export const QuoteForm: React.FC<{
    configLabel: string;
}> = ({ configLabel }) => {
    const { data: widgetConfig, isFetching, error } = useGetWidgetConfig(configLabel);
    const [skipLeadForm, setSkipLeadForm] = useState(false);

    if ((isFetching && !widgetConfig) || !widgetConfig || error) {
        return (
            <div className="h-screen rounded-[1.25rem] bg-white md:h-[420px]">
                <ContainerLoader
                    loading={isFetching && !widgetConfig}
                    noData={!widgetConfig}
                    error={error}
                />
            </div>
        );
    }

    const renderBody = () => {
        if (widgetConfig.enableMembershipCard) {
            return (
                <div className="p-2">
                    <MembershipCardFlow configLabel={configLabel} />
                </div>
            );
        }

        if (widgetConfig.enableLeadCapture && !skipLeadForm) {
            return (
                <div className="p-2">
                    <LeadCaptureFlow
                        configLabel={configLabel}
                        onComplete={() => {
                            setSkipLeadForm(true);
                        }}
                    />
                </div>
            );
        }

        return <InquiryFlow configLabel={configLabel} widgetConfig={widgetConfig} />;
    };

    return (
        <QueryParamProvider adapter={ReactRouter6Adapter}>
            <PersistentGoogleEffectsProvider />
            <PersistantLeadProvider>{renderBody()}</PersistantLeadProvider>
            <AdSource />
        </QueryParamProvider>
    );
};

export const PersistentGoogleEffectsProvider: React.FC = () => {
    // call useGoogleId to persist IDs on device
    useGoogleId();
    return null;
};

const InquiryFlow: React.FC<{
    configLabel: string;
    widgetConfig: WidgetConfigOutDto;
}> = ({ configLabel, widgetConfig }) => {
    const { t } = useTranslation();
    const [tab, setTab] = useState(EDefaultTab.ROUND_TRIP);
    const [inquiredIds, setInquiredIds] = useState<string[]>([]);
    const [inquiredForm, setInquiredForm] = useState(false);
    const [currency, setCurrency] = useState<CURRENCY_KEY>('USD');
    const [searchForm, setSearchForm] = useState<SearchFormInDto>();
    const [loadingDefaultData, setLoadingDefaultData] = useState(false);

    const {
        airports,
        passengers,
        oneWayData,
        roundTripData,
        multiCityData,
        onPassengersChange,
        onOneWayDataChange,
        onRoundTripDataChange,
        onMultiCityDataChange,
    } = useQuoteForm(tab);

    // const { data: widgetConfig, isFetching, error } = useGetWidgetConfig(configLabel);
    const { mutate: bookingSearch, isLoading, data: searchHits } = useBookingSearch();

    const refForGetHeightOfIframe = useGetHeightOfIframe();

    const simpleFormValues = useGetSimpleFormValues();

    useEffect(() => {
        if (widgetConfig?.defaultTab) {
            gtag.defaultTripType({ value: widgetConfig.defaultTab }, { configLabel });
            switch (true) {
                case simpleFormValues?.TripType === 'One Way' && widgetConfig.tabOneWayEnabled:
                    setTab(EDefaultTab.ONE_WAY);
                    break;
                case simpleFormValues?.TripType === 'Round Trip' &&
                    widgetConfig.tabRoundTripEnabled:
                    setTab(EDefaultTab.ROUND_TRIP);
                    break;
                case simpleFormValues?.TripType === 'Multi City' &&
                    widgetConfig.tabMultiCityEnabled:
                    setTab(EDefaultTab.MULTI_CITY);
                    break;
                default:
                    setTab(widgetConfig.defaultTab);
            }
            setSearchForm(undefined);
            setInquiredIds([]);
        }
    }, [widgetConfig?.defaultTab]);

    useEffect(() => {
        if (
            simpleFormValues &&
            (simpleFormValues['From 1'] ||
                simpleFormValues['From 2'] ||
                simpleFormValues['To 1'] ||
                simpleFormValues['To 2'] ||
                simpleFormValues['Departure Date 1'] ||
                simpleFormValues['Departure Date 1'] ||
                simpleFormValues['Return Date'] ||
                simpleFormValues?.TripType)
        ) {
            const firstStartAirport = simpleFormValues['From 1']
                ? mainApi.getAirportByTerm(simpleFormValues['From 1'])
                : null;

            const firstEndAirport = simpleFormValues['To 1']
                ? mainApi.getAirportByTerm(simpleFormValues['To 1'])
                : null;

            const secondStartAirport = simpleFormValues['From 2']
                ? mainApi.getAirportByTerm(simpleFormValues['From 2'])
                : null;

            const secondEndAirport = simpleFormValues['To 2']
                ? mainApi.getAirportByTerm(simpleFormValues['To 2'])
                : null;

            setLoadingDefaultData(true);
            Promise.allSettled([
                firstStartAirport,
                firstEndAirport,
                secondStartAirport,
                secondEndAirport,
            ])
                .then(data => {
                    const oneWayData = {
                        startAirport: data[0].status === 'fulfilled' ? data[0].value : null,
                        endAirport: data[1].status === 'fulfilled' ? data[1].value : null,
                        date:
                            simpleFormValues['Departure Date 1'] &&
                            !isNaN(new Date(simpleFormValues['Departure Date 1']).getTime())
                                ? new Date(simpleFormValues['Departure Date 1'])
                                : null,
                    };

                    if (simpleFormValues?.TripType === 'Round Trip') {
                        onRoundTripDataChange({
                            ...oneWayData,
                            returnDate:
                                simpleFormValues['Return Date'] &&
                                !isNaN(new Date(simpleFormValues['Return Date']).getTime())
                                    ? new Date(simpleFormValues['Return Date'])
                                    : null,
                        });
                        return;
                    }

                    onMultiCityDataChange([
                        oneWayData,
                        {
                            startAirport: data[2].status === 'fulfilled' ? data[2].value : null,
                            endAirport: data[3].status === 'fulfilled' ? data[3].value : null,
                            date:
                                simpleFormValues['Departure Date 2'] &&
                                !isNaN(new Date(simpleFormValues['Departure Date 2']).getTime())
                                    ? new Date(simpleFormValues['Departure Date 2'])
                                    : null,
                        },
                    ]);
                })
                .finally(() => {
                    setLoadingDefaultData(false);
                });
        }
        return () => setLoadingDefaultData(false);
    }, [simpleFormValues]);

    const confirmButtonText = useMemo(
        () =>
            widgetConfig?.showSearchResults ? t('viewEstimatesAndInquire') : t('continueToInquire'),
        [t, widgetConfig?.showSearchResults],
    );

    const emptyLegsMayBeAvailable = useMemo(() => {
        const legs = searchForm?.legs;
        if (legs && legs.length) {
            const firstLeg = legs[0];
            const lastLeg = legs[legs.length - 1];
            if (tab === EDefaultTab.ONE_WAY) {
                return true;
            } else if (tab === EDefaultTab.ROUND_TRIP && firstLeg?.date && firstLeg?.returnDate) {
                return differenceInDays(new Date(firstLeg.returnDate), new Date(firstLeg.date)) > 3;
            } else if (tab === EDefaultTab.MULTI_CITY && firstLeg?.date && lastLeg?.date) {
                return differenceInDays(new Date(lastLeg.date), new Date(firstLeg.date)) > 3;
            }
        }

        return false;
    }, [tab, searchForm?.legs]);

    const onSubmit = useHandler((Dto: SearchFormInDto) => {
        gtag.viewEstimates({}, { configLabel, withTotalEvent: true });
        if (widgetConfig?.showSearchResults) {
            const { passengers, pets, legs } = Dto;
            bookingSearch(
                {
                    legs: legs.map(leg => ({
                        ...leg,
                        pax: passengers,
                        pets,
                    })),
                },
                {
                    onSuccess: () => {
                        setSearchForm(Dto);
                    },
                },
            );
        } else {
            setSearchForm(Dto);
        }
    });

    useEffect(() => {
        if (!searchHits) {
            return;
        }
        const target = document.getElementById(SEARCH_RESULTS_ID);
        if (!target) {
            return;
        }
        target.scrollIntoView();
    }, [searchHits]);

    if (loadingDefaultData && !oneWayData?.startAirport && !oneWayData?.endAirport) {
        return (
            <div className="h-screen rounded-[1.25rem] bg-white md:h-[420px]">
                <ContainerLoader loading />
            </div>
        );
    }

    const {
        tabOneWayEnabled,
        tabRoundTripEnabled,
        tabMultiCityEnabled,
        showSearchResults,
        doNotDiscloseEstimate,
        showContactBeforeEstimate,
    } = widgetConfig;

    return (
        <div ref={refForGetHeightOfIframe} className="p-2">
            <div className="flex w-full flex-col gap-4 rounded-[1.25rem] bg-white px-2 py-4 shadow-custom md:flex-row md:px-6 lg:w-auto lg:gap-14">
                <div className="flex-[12] md:flex-[6]">
                    <div className="mb-7 flex items-center justify-between capitalize">
                        <Typography className="text-2xl font-semibold text-neutral-800">
                            {t('searchFlight')}
                        </Typography>
                        <div className="flex items-center gap-6">
                            <LanguageSwitcher configLabel={configLabel} />
                            <Select
                                value={currency}
                                onChange={e => {
                                    setCurrency(e.target.value as CURRENCY_KEY);
                                    gtag.changeCurrency(
                                        { currency: e.target.value },
                                        { configLabel },
                                    );
                                }}>
                                {CURRENCIES.map(currency => (
                                    <MenuItem key={currency} value={currency} disableGutters>
                                        {currency}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    </div>
                    <Tabs
                        className="mb-8"
                        value={tab}
                        classes={{
                            flexContainer: 'justify-center md:justify-start',
                        }}
                        onChange={(_, value) => {
                            setTab(value);
                            gtag.changeTripType({ type: value }, { configLabel });
                            setSearchForm(undefined);
                            setInquiredIds([]);
                            setInquiredForm(false);
                        }}>
                        {tabOneWayEnabled && (
                            <Tab label={t('oneWay')} value={EDefaultTab.ONE_WAY} />
                        )}
                        {tabRoundTripEnabled && (
                            <Tab label={t('roundTrip')} value={EDefaultTab.ROUND_TRIP} />
                        )}
                        {tabMultiCityEnabled && (
                            <Tab label={t('multiCity')} value={EDefaultTab.MULTI_CITY} />
                        )}
                    </Tabs>
                    {tab === EDefaultTab.ONE_WAY && (
                        <OneWayForm
                            configLabel={configLabel}
                            data={oneWayData}
                            onDataChange={onOneWayDataChange}
                            passengers={passengers}
                            onPassengersChange={onPassengersChange}
                            onSubmit={onSubmit}
                            buttonText={confirmButtonText}
                            disabled={isLoading}
                            loading={isLoading}
                        />
                    )}
                    {tab === EDefaultTab.ROUND_TRIP && (
                        <RoundTripForm
                            configLabel={configLabel}
                            data={roundTripData}
                            onDataChange={onRoundTripDataChange}
                            passengers={passengers}
                            onPassengersChange={onPassengersChange}
                            onSubmit={onSubmit}
                            buttonText={confirmButtonText}
                            disabled={isLoading}
                            loading={isLoading}
                        />
                    )}
                    {tab === EDefaultTab.MULTI_CITY && (
                        <MultiCityForm
                            configLabel={configLabel}
                            data={multiCityData}
                            onDataChange={onMultiCityDataChange}
                            passengers={passengers}
                            onPassengersChange={onPassengersChange}
                            onSubmit={onSubmit}
                            buttonText={confirmButtonText}
                            disabled={isLoading}
                            loading={isLoading}
                        />
                    )}
                </div>
                <div className="flex-[12] md:flex-[6]">
                    <Map airports={airports} />
                </div>
            </div>
            <div id={SEARCH_RESULTS_ID} />
            {searchForm &&
                !isLoading &&
                (showSearchResults ? (
                    searchHits?.length ? (
                        <BookingSearchResultWrapper
                            loading={isLoading}
                            showSearchResults={showSearchResults}
                            emptyLegsMayBeAvailable={emptyLegsMayBeAvailable}
                            total={searchHits?.length || 0}>
                            {searchHits?.map(flight => (
                                <Flight
                                    key={flight.aircraft.id}
                                    tab={tab}
                                    flight={flight}
                                    searchForm={searchForm}
                                    configLabel={configLabel}
                                    widgetConfig={widgetConfig}
                                    doNotDiscloseEstimate={doNotDiscloseEstimate}
                                    inquired={inquiredIds.includes(flight.aircraft.id)}
                                    showContactBeforeEstimate={
                                        showContactBeforeEstimate && !inquiredForm
                                    }
                                    onConfirm={() => {
                                        setInquiredIds(prevIds => [...prevIds, flight.aircraft.id]);
                                        setInquiredForm(true);
                                    }}
                                    currency={currency}
                                />
                            ))}
                        </BookingSearchResultWrapper>
                    ) : (
                        <>
                            <div className="mt-10 rounded-[1.25rem] bg-white p-6">
                                <Typography className="font-semibold text-gray-800">
                                    {t('noResultsFound')}
                                </Typography>
                                <Typography className="text-sm text-gray-600">
                                    {t('sendUsMessage')}
                                </Typography>
                            </div>
                            <div className="mt-10">
                                <InquireForm
                                    open
                                    tab={tab}
                                    currency={currency}
                                    searchForm={searchForm}
                                    configLabel={configLabel}
                                    widgetConfig={widgetConfig}
                                    onClose={() => setSearchForm(undefined)}
                                    inquired={inquiredForm}
                                    onConfirm={() => setInquiredForm(true)}
                                />
                            </div>
                        </>
                    )
                ) : (
                    <BookingSearchResultWrapper
                        showSearchResults={showSearchResults}
                        total={searchHits?.length || 0}>
                        <InquireForm
                            open
                            tab={tab}
                            currency={currency}
                            searchForm={searchForm}
                            configLabel={configLabel}
                            widgetConfig={widgetConfig}
                            onClose={() => setSearchForm(undefined)}
                            inquired={inquiredForm}
                            onConfirm={() => setInquiredForm(true)}
                        />
                    </BookingSearchResultWrapper>
                ))}
        </div>
    );
};
