import React, { useMemo, useState } from 'react';
import { GoogleMap, useJsApiLoader, Marker, Polyline, InfoWindowF } from '@react-google-maps/api';
import { defaultTheme } from './Map.theme';
import { AirportOutDto } from '../../swagger-types';
import styles from './Map.module.scss';
import { renderAirportName } from '../../utils/airport.utils';
import { AirportIcon } from '../../assets/icons';
import { Typography } from '@mui/material';
import { ContainerLoader } from '../container-loader/ContainerLoader';
import { MapSideEffects } from './MapSideEffects';
import { isEmpty } from 'lodash';
import { getGoogle } from 'google/google-maps.utils';

const lineSymbol = {
    path: 'M 0,-1 0,1',
    strokeOpacity: 1,
    scale: 4,
};

const defaultOptions = {
    panControl: true,
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: true,
    streetViewControl: false,
    rotateControl: false,
    clickableIcons: false,
    keyboardShortcuts: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    fullscreenControl: false,
    // map bounds
    restriction: {
        latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
    },
    minZoom: 1,
    maxZoom: 12,
    styles: defaultTheme,
};

const center = {
    lat: 0,
    lng: 0,
};

export const Map: React.FC<{ airports: AirportOutDto[] }> = ({ airports }) => {
    const [activeAirportId, setActiveAirportId] = useState<string | null>(null);

    const coordinates = useMemo(
        () =>
            airports.map(({ latitude, longitude }) => ({
                lat: latitude,
                lng: longitude,
            })),
        [airports],
    );

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyAo5r0QGk0pCqJYBEtQv1NJ9gpsV01rHKc',
    });

    const maybeGoogle = getGoogle();

    const createdPolyline =
        isLoaded &&
        maybeGoogle &&
        new maybeGoogle.maps.Polyline({
            path: coordinates,
        });

    const isRenderPolyline = !isEmpty(createdPolyline) && Boolean(coordinates.length);

    return isLoaded && maybeGoogle ? (
        <GoogleMap
            mapContainerClassName={styles.root}
            center={center}
            zoom={1}
            onClick={() => setActiveAirportId(null)}
            options={defaultOptions}>
            <MapSideEffects markers={coordinates} />
            {airports.map((airport, index) => (
                <Marker
                    key={index}
                    position={{
                        lat: airport.latitude,
                        lng: airport.longitude,
                    }}
                    onClick={() => setActiveAirportId(airport.id)}
                    icon={{
                        url: index
                            ? require('../../assets/icons/destination-airport-icon.png')
                            : require('../../assets/icons/start-airport-icon.png'),
                        scaledSize: new maybeGoogle.maps.Size(20, 20),
                        anchor: new maybeGoogle.maps.Point(10, 10),
                    }}>
                    {activeAirportId == airport.id ? (
                        <InfoWindowF onCloseClick={() => setActiveAirportId(null)}>
                            <div className="flex items-center gap-1 py-1">
                                <AirportIcon />
                                <Typography className="text-gray-800">
                                    {renderAirportName(airport)}
                                </Typography>
                            </div>
                        </InfoWindowF>
                    ) : null}
                </Marker>
            ))}
            {isRenderPolyline && (
                <Polyline
                    path={coordinates}
                    options={{
                        strokeOpacity: 0,
                        icons: [
                            {
                                icon: lineSymbol,
                                offset: '0',
                                repeat: '20px',
                            },
                        ],
                    }}
                />
            )}
        </GoogleMap>
    ) : (
        <div className={styles.root}>
            <ContainerLoader loading />
        </div>
    );
};
