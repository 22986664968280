import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
    ContactFormInDto,
    LegsPublicInDto,
    MembershipCardInfoInDto,
    SearchHitsOutDto,
    WidgetConfigOutDto,
} from '../swagger-types';
import { EQueryConfigNames } from './main.types';
import { mainApi } from './main.api';
import { gtagNormalize } from 'gtag/gtag-normalize.utils';
import { gtag } from 'gtag/gtag';
import { ExtendedInquiryInDto } from 'gtag/gtag.type';
import { StringParam, useQueryParams } from 'use-query-params';
import { IS_NOT_PROD_ENV } from 'environment/typedEnv';

export const useGetWidgetConfig = (
    configLabel: string,
    options?: UseQueryOptions<
        unknown,
        Error,
        WidgetConfigOutDto,
        [EQueryConfigNames.WIDGET_CONFIG, string]
    >,
) => {
    return useQuery(
        [EQueryConfigNames.WIDGET_CONFIG, configLabel],
        async () => {
            const { data } = await mainApi.getWidgetConfig(configLabel);
            if (IS_NOT_PROD_ENV) {
                console.log('loaded widget config', configLabel, data);
            }
            return data;
        },
        {
            staleTime: 3000,
            ...options,
        },
    );
};

export const useBookingSearch = (
    options: UseMutationOptions<SearchHitsOutDto[], Error, LegsPublicInDto> = {},
) => {
    return useMutation(
        async Dto => {
            const { result } = await mainApi.bookingSearch(Dto);
            return result || [];
        },
        {
            ...options,
        },
    );
};

export const useBookingInquiry = (
    options: UseMutationOptions<
        unknown,
        Error,
        ExtendedInquiryInDto & { configLabel: string }
    > = {},
) => {
    return useMutation(
        async ({ meta, configLabel, ...dto }) => {
            gtag.sendInquiry(gtagNormalize.inquiry({ meta, ...dto }), {
                configLabel,
                withTotalEvent: true,
            });
            return await mainApi.bookingInquiry(dto);
        },
        {
            ...options,
        },
    );
};

export const useCreateLeadCapture = () => {
    return useMutation(
        async ({ dto, configLabel }: { dto: ContactFormInDto; configLabel: string }) => {
            gtag.submitLeadForm(gtagNormalize.lead(dto), { configLabel, withTotalEvent: true });
            return await mainApi.captureLead(dto);
        },
    );
};

export const useRequestMembershipCard = () => {
    return useMutation(
        async ({ dto, configLabel }: { dto: MembershipCardInfoInDto; configLabel: string }) => {
            gtag.requestMembershipCard(gtagNormalize.membershipCard(dto), {
                configLabel,
                withTotalEvent: true,
            });
            return await mainApi.requestMembershipCard(dto);
        },
    );
};

export const useInquiryQueryParams = () => {
    return useQueryParams({
        utm_source: StringParam,
        utm_campaign: StringParam,
        utm_medium: StringParam,
        utm_content: StringParam,
        utm_term: StringParam,
    });
};
