// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Map_root__Rh41x{width:100%;height:100%;min-height:180px;max-height:372px;border-radius:4px}", "",{"version":3,"sources":["webpack://./src/components/map/Map.module.scss"],"names":[],"mappings":"AAAA,iBAAM,UAAU,CAAC,WAAW,CAAC,gBAAgB,CAAC,gBAAgB,CAAC,iBAAiB","sourcesContent":[".root{width:100%;height:100%;min-height:180px;max-height:372px;border-radius:4px}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Map_root__Rh41x"
};
export default ___CSS_LOADER_EXPORT___;
