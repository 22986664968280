import React from 'react';
import { PassengersInput } from '../components/passengers-input/PassengersInput';
import { useTranslation } from 'react-i18next';
import { PassengersInDto, RoundTripInDto } from './useQuoteForm';
import { AirportAutocomplete } from './AirportAutocomplete';
import { Controller, useForm } from 'react-hook-form';
import { RoundTripFormSchema } from '../api/main.form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { SearchFormInDto } from '../swagger-types';
import { BookingFormWrapper } from './BookingFormWrapper';
import { DatePickerInput } from '../components/date-picker-input/DatePickerInput';
import { gtag } from 'gtag/gtag';

export const RoundTripForm: React.FC<{
    configLabel: string;
    data: RoundTripInDto;
    onDataChange: (data: RoundTripInDto) => void;
    passengers: PassengersInDto;
    onPassengersChange: (passengers: PassengersInDto) => void;
    onSubmit: (Dto: SearchFormInDto) => void;
    buttonText: string;
    disabled?: boolean;
    loading?: boolean;
}> = ({
    configLabel,
    data,
    onDataChange,
    passengers,
    onPassengersChange,
    onSubmit,
    buttonText,
    disabled,
    loading,
}) => {
    const { t } = useTranslation();
    const { handleSubmit, control, watch } = useForm<RoundTripFormSchema>({
        resolver: yupResolver(RoundTripFormSchema),
        defaultValues: {
            ...data,
            passengers,
        },
    });

    const { date, returnDate } = watch();

    return (
        <BookingFormWrapper
            buttonText={buttonText}
            disabled={disabled}
            loading={loading}
            onSubmit={handleSubmit(formData => {
                const {
                    date,
                    startAirport,
                    endAirport,
                    returnDate,
                    passengers: { passengers, pets },
                } = formData;
                if (date && returnDate && startAirport && endAirport && passengers && pets) {
                    onSubmit({
                        legs: [
                            {
                                date: date.toISOString(),
                                returnDate: returnDate.toISOString(),
                                startAirportId: startAirport.id,
                                endAirportId: endAirport.id,
                            },
                        ],
                        passengers,
                        pets,
                    });
                }
            })}>
            <div className="grid gap-4 md:grid-cols-2">
                <Controller
                    control={control}
                    name="startAirport"
                    render={({ field: { onChange, value }, fieldState }) => (
                        <AirportAutocomplete
                            value={value}
                            fullWidth
                            disabled={disabled}
                            InputProps={{
                                label: t('from'),
                                placeholder: t('from'),
                                errorMessage: t(fieldState.error?.message || ''),
                            }}
                            onChange={(_, value) => {
                                onDataChange({
                                    ...data,
                                    startAirport: value || null,
                                });
                                onChange(value || null);
                                gtag.selectMainStartAirport(
                                    { icao: value?.icaoCode || '-' },
                                    { configLabel },
                                );
                            }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="endAirport"
                    render={({ field: { onChange, value }, fieldState }) => (
                        <AirportAutocomplete
                            value={value}
                            fullWidth
                            disabled={disabled}
                            InputProps={{
                                label: t('to'),
                                placeholder: t('to'),
                                errorMessage: t(fieldState.error?.message || ''),
                            }}
                            onChange={(_, value) => {
                                onDataChange({
                                    ...data,
                                    endAirport: value || null,
                                });
                                onChange(value || null);
                                gtag.selectMainEndAirport(
                                    { icao: value?.icaoCode || '-' },
                                    { configLabel },
                                );
                            }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="date"
                    render={({ field: { onChange, value, ref }, fieldState }) => (
                        <DatePickerInput
                            ref={ref}
                            disabled={disabled}
                            inputProps={{
                                label: t('departure'),
                                errorMessage: t(fieldState.error?.message || ''),
                            }}
                            selected={value}
                            showTimeSelect
                            placeholderText={t('departure')}
                            timeFormat="HH:mm"
                            onChange={date => {
                                onDataChange({
                                    ...data,
                                    date: date || null,
                                });
                                onChange(date || null);
                                gtag.changeDepartureDate(
                                    { date: date?.toJSON() || '-' },
                                    { configLabel },
                                );
                            }}
                            minDate={new Date()}
                            maxDate={returnDate}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="returnDate"
                    render={({ field: { onChange, value, ref }, fieldState }) => (
                        <DatePickerInput
                            ref={ref}
                            disabled={disabled}
                            inputProps={{
                                label: t('return'),
                                errorMessage: t(fieldState.error?.message || ''),
                            }}
                            selected={value}
                            showTimeSelect
                            timeFormat="HH:mm"
                            placeholderText={t('return')}
                            onChange={date => {
                                onDataChange({
                                    ...data,
                                    returnDate: date || null,
                                });
                                onChange(date || null);
                                gtag.changeReturnDate(
                                    { date: date?.toJSON() || '-' },
                                    { configLabel },
                                );
                            }}
                            minDate={date || new Date()}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="passengers"
                    render={({ field: { onChange, value, ref }, fieldState }) => (
                        <PassengersInput
                            ref={ref}
                            value={value}
                            disabled={disabled}
                            onChange={passengers => {
                                onPassengersChange(passengers);
                                onChange(passengers);
                                gtag.changePaxPetsInfo(
                                    gtag.mapper.normalizePassengers(passengers),
                                    { configLabel },
                                );
                            }}
                            inputProps={{
                                label: t('passengers'),
                                placeholder: t('passengers'),
                                errorMessage: t(fieldState.error?.message || ''),
                                className: 'col-span-2',
                            }}
                        />
                    )}
                />
            </div>
        </BookingFormWrapper>
    );
};
