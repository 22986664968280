import styles from './Input.module.scss';
import { get } from 'lodash';
import React from 'react';
import { useToggle } from 'react-use';

import { FormControlUnstyled } from '@mui/base';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import {
    InputBase,
    InputBaseProps,
    InputLabelProps,
    InputLabel,
    FormHelperText,
    FormHelperTextProps,
    InputAdornment,
    IconButton,
} from '@mui/material';
import { clsxm } from '../../utils/clsxm';

export interface InputProps extends Omit<InputBaseProps, 'ref'> {
    helperText?: React.ReactNode | undefined;
    errorMessage?: string | boolean | undefined;
    label?: string | React.ReactNode;
    labelRequired?: boolean;
    labelProps?: InputLabelProps;
    labelVariant?: 'regular' | 'futura';
    helperTextProps?: FormHelperTextProps;
    variant?: 'outlined' | 'filled' | 'standard';
    inputSize?: 'large' | 'medium' | 'small';
    inAutocomplete?: boolean;
    withEndAdornmentBG?: boolean;
    bold?: boolean;
    inputBaseClassName?: string;
    darkMode?: boolean;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
    (
        {
            helperText,
            errorMessage,
            label,
            required,
            labelProps,
            labelVariant = 'regular',
            helperTextProps,
            className,
            classes,
            variant = 'standard',
            inputSize = 'medium',
            withEndAdornmentBG = false,
            bold = false,
            inputBaseClassName,
            darkMode,
            ...props
        },
        ref,
    ) => {
        const [hiddenPassword, toggleVisibility] = useToggle(true);

        return (
            <FormControlUnstyled
                className={clsxm(styles.root, className)}
                error={!!errorMessage || props.error}>
                {label && (
                    <InputLabel
                        htmlFor={props.id}
                        disableAnimation
                        {...labelProps}
                        className={clsxm(
                            styles.label,
                            get(styles, `${labelVariant}`),
                            labelProps?.className,
                        )}>
                        {required && <span>*</span>}
                        {label}
                    </InputLabel>
                )}

                <InputBase
                    error={!!errorMessage || props.error}
                    className={clsxm(
                        styles.input,
                        get(styles, inputSize),
                        get(styles, variant),
                        'schema-light dark:schema-dark',
                        bold && '!font-semibold',
                        darkMode && 'text-white',
                        inputBaseClassName,
                    )}
                    classes={{
                        ...classes,
                        focused: styles.focused,
                        error: styles.error,
                    }}
                    inputRef={ref}
                    {...props}
                    type={
                        props.type === 'password'
                            ? hiddenPassword
                                ? 'password'
                                : 'text'
                            : props.type
                    }
                    endAdornment={
                        props.type === 'password' ? (
                            <InputAdornment position="end" className={styles['end-adornment']}>
                                <IconButton disableRipple onClick={toggleVisibility}>
                                    {hiddenPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ) : (
                            props.endAdornment && (
                                <InputAdornment
                                    position="end"
                                    className={clsxm(
                                        'ml-0 h-full max-h-full rounded-r',
                                        withEndAdornmentBG && 'bg-gray-100',
                                    )}>
                                    {props.endAdornment}
                                </InputAdornment>
                            )
                        )
                    }
                />
                {errorMessage && (
                    <FormHelperText className={styles['error-text']} {...helperTextProps}>
                        {errorMessage}
                    </FormHelperText>
                )}
                {!errorMessage && helperText && (
                    <FormHelperText {...helperTextProps}>{helperText}</FormHelperText>
                )}
            </FormControlUnstyled>
        );
    },
);
