import { forwardRef, useCallback, useState } from 'react';

import { Popover, PopoverOrigin } from '@mui/material';

import { Input, InputProps } from '../input/Input';
import { useUncontrolled } from '../../hooks/useUncontrolled.hook';
import { PassengersForm } from './PassengersForm';
import { useTranslation } from 'react-i18next';
import { PassengersInDto } from '../../QuoteForm/useQuoteForm';

type RenderInputProps = {
    value: PassengersInDto;
    handleFocus(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
};

export type PassengersInputProps = {
    inputProps?: InputProps;
    position?: 'left' | 'center' | 'right';
    value?: PassengersInDto;
    onChange?: (value: PassengersInDto) => void;
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    renderInput?(props: RenderInputProps): React.ReactNode;
    disableOpenOnFocus?: boolean;
    disabled?: boolean;
};

export const PassengersInput = forwardRef<HTMLInputElement, PassengersInputProps>(
    (
        {
            inputProps,
            position = 'center',
            anchorOrigin,
            transformOrigin,
            onChange,
            renderInput,
            disabled,
            value,
            disableOpenOnFocus,
        },
        ref,
    ) => {
        const { t } = useTranslation();
        const [_value, handleValueChange] = useUncontrolled({
            value,
            rule: val => typeof val === 'object',
            onChange,
        });
        const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
        const [anchorProps] = useState<{
            anchorOrigin: PopoverOrigin;
            transformOrigin: PopoverOrigin;
        }>({
            anchorOrigin: anchorOrigin ?? {
                vertical: 'bottom',
                horizontal: position,
            },
            transformOrigin: transformOrigin ?? {
                vertical: 'top',
                horizontal: position,
            },
        });

        const handleClose = useCallback(() => {
            setAnchorEl(null);
        }, []);

        const handleFocus = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (disabled || disableOpenOnFocus) {
                return null;
            }
            setAnchorEl(event.currentTarget);
        };

        const parsePassengersValueToString = ({ passengers, pets }: PassengersInDto) => {
            let string = '';

            if (passengers) {
                string += `${passengers} ${t('passenger', { count: passengers })}`;
            }
            if (pets.length) {
                string += string.length ? ', ' : '';
                string += `${pets.length} ${t('pet', { count: pets.length })}`;
            }
            return string;
        };

        return (
            <>
                {renderInput ? (
                    renderInput({
                        handleFocus,
                        value: _value || {
                            passengers: 0,
                            pets: [],
                        },
                    })
                ) : (
                    <Input
                        ref={ref}
                        onClick={handleFocus}
                        value={_value ? parsePassengersValueToString(_value) : ''}
                        bold
                        disabled={disabled}
                        {...inputProps}
                    />
                )}

                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={anchorProps.anchorOrigin}
                    transformOrigin={anchorProps.transformOrigin}
                    classes={{
                        paper: 'mt-3 mb-3',
                    }}>
                    <PassengersForm
                        value={
                            _value || {
                                passengers: 0,
                                pets: [],
                            }
                        }
                        onChange={handleValueChange}
                    />
                </Popover>
            </>
        );
    },
);
