import { forwardRef } from 'react';

import { DatePicker, DatePickerProps } from '../date-picker/DatePicker';

import { Input, InputProps } from '../input/Input';
import { useUncontrolled } from '../../hooks/useUncontrolled.hook';
import { DATE_FORMAT } from '../../constants/date';

export type DatePickerInputProps = DatePickerProps & {
    inputProps?: Omit<InputProps, 'placeholder'>;
    formatDate?: string;
};

export const DatePickerInput = forwardRef<HTMLInputElement, DatePickerInputProps>(
    ({ inputProps, selected, formatDate = DATE_FORMAT, onChange, ...rest }, ref) => {
        const [_value, handleDateChange] = useUncontrolled({
            value: selected,
            rule: val => typeof val === 'object',
            onChange,
        });

        return (
            <DatePicker
                customInput={<Input ref={ref} bold {...inputProps} />}
                selected={_value}
                onChange={handleDateChange}
                dateFormat={formatDate}
                onFocus={e => e.target.blur()}
                {...rest}
            />
        );
    },
);
