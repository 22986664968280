import React from 'react';
import { Input, InputProps } from '../input/Input';
import { FormHelperText, MenuItem, Select } from '@mui/material';
import ReactCountryFlag from 'react-country-flag';
import LanguageIcon from '@mui/icons-material/Language';
import ReactPhoneInput, { Props as ReactPhoneInputProps } from 'react-phone-number-input';
import { getCountryCallingCode } from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css';
import styles from './PhoneInput.module.scss';
import { clsxm } from '../../utils/clsxm';
import { omit } from 'lodash';

export interface PhoneInputProps extends ReactPhoneInputProps<InputProps> {
    errorMessage?: string;
    darkMode?: boolean;
}

export const PhoneInput = React.forwardRef<HTMLInputElement, PhoneInputProps>(
    ({ className, errorMessage, darkMode, ...props }, ref) => {
        return (
            <div>
                <ReactPhoneInput
                    className={clsxm(styles.root, className)}
                    error={Boolean(errorMessage)}
                    inputComponent={Input}
                    numberInputProps={{
                        inputBaseClassName: clsxm('pl-16', darkMode && 'text-white'),
                    }}
                    countrySelectComponent={({ options, onChange, value, ...props }) => (
                        // TODO: Positioning bug happens in development mode only. React 18 and Strict mode,
                        //  component re-renders twice which might cause this issue. Works fine for the production build.
                        //  GitHub Issue - https://github.com/mui/material-ui/issues/33308
                        <Select
                            {...omit(props, ['iconComponent'])}
                            className={styles.select}
                            ref={ref}
                            value={value || 'ZZ'}
                            onChange={e => onChange(e.target.value || undefined)}
                            renderValue={(value: string) =>
                                value !== 'ZZ' ? (
                                    <ReactCountryFlag
                                        countryCode={value}
                                        className="mr-2"
                                        svg
                                        style={{
                                            fontSize: 24,
                                        }}
                                    />
                                ) : (
                                    <LanguageIcon className="h-6 w-6" />
                                )
                            }
                            MenuProps={{
                                classes: { paper: '!max-h-[300px]' },
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                },
                                elevation: 1,
                            }}>
                            {options.map(({ label, value }: { label: string; value: string }) => (
                                <MenuItem value={value} key={label}>
                                    <ReactCountryFlag
                                        countryCode={value}
                                        svg
                                        className="mr-2"
                                        style={{
                                            fontSize: 24,
                                        }}
                                    />
                                    {label}
                                    {value && ` + ${getCountryCallingCode(value as any)}`}
                                </MenuItem>
                            ))}
                            <MenuItem value={'ZZ'} className="hidden" />
                        </Select>
                    )}
                    type="tel"
                    {...props}
                />
                {errorMessage && (
                    <FormHelperText className="mt-1.5 !text-[#fc3030]">
                        {errorMessage}
                    </FormHelperText>
                )}
            </div>
        );
    },
);
