// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PhoneInput_root__veizP{position:relative}.PhoneInput_root__veizP .MuiOutlinedInput-notchedOutline{border:none}.PhoneInput_root__veizP .PhoneInput_select__w2aYJ{position:absolute;bottom:0;z-index:50}.PhoneInput_root__veizP .PhoneInput_select__w2aYJ .MuiSelect-select{padding-left:0}", "",{"version":3,"sources":["webpack://./src/components/phone-input/PhoneInput.module.scss"],"names":[],"mappings":"AAAA,wBAAM,iBAAiB,CAAC,yDAA+C,WAAW,CAAC,kDAAc,iBAAiB,CAAC,QAAQ,CAAC,UAAU,CAAC,oEAAwC,cAAc","sourcesContent":[".root{position:relative}.root :global .MuiOutlinedInput-notchedOutline{border:none}.root .select{position:absolute;bottom:0;z-index:50}.root .select :global .MuiSelect-select{padding-left:0}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "PhoneInput_root__veizP",
	"select": "PhoneInput_select__w2aYJ"
};
export default ___CSS_LOADER_EXPORT___;
