import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import clsx from 'clsx';
import { clsxm } from '../../utils/clsxm';
import style from './style.module.scss';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CachedImg } from 'components/CachedImg';

export const Carousel: React.VFC<{
    images: {
        url: string;
    }[];
    initialIndex?: number;
}> = ({ images, initialIndex = 0 }) => {
    const { t } = useTranslation();
    const [index, setIndex] = useState(initialIndex < images.length ? initialIndex : 0);
    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const el = scrollRef.current;
        if (!el) {
            return;
        }

        let alive = true;
        const start = Date.now();
        const duration = 300;
        const initial = el.scrollLeft;
        const target = index * 155;

        const animation = () => {
            const now = Date.now();
            let time = Math.pow((now - start) / duration, 2);
            if (!alive) {
                return;
            }

            if (time > 1) {
                time = 1;
                alive = false;
            }
            requestAnimationFrame(animation);
            const delta = target - initial;
            el.scrollLeft = initial + delta * time;
        };

        animation();
        return () => {
            alive = false;
        };
    }, [index, scrollRef]);

    const isPrevDisabled = index < 1;
    const isNextDisabled = index >= images.length - 1;

    const goPrev = useCallback(() => {
        if (isPrevDisabled) {
            return;
        }
        setIndex(index => index - 1);
    }, [isPrevDisabled]);
    const goNext = useCallback(() => {
        if (isNextDisabled) {
            return;
        }
        setIndex(index => index + 1);
    }, [isNextDisabled]);

    const mainUrl = images[index]?.url;

    const [mainImageQueue, setMainImageQueue] = useState<
        {
            url: string;
            key: string;
        }[]
    >([]);

    useEffect(() => {
        if (!mainUrl) {
            return;
        }
        setMainImageQueue(prev => [
            {
                url: mainUrl,
                key: new Date().toJSON(),
            },
            ...prev.slice(0, 2),
        ]);
    }, [mainUrl]);

    const swipeHandlers = useSwipeable({
        onSwipedLeft: goNext,
        onSwipedRight: goPrev,
        preventScrollOnSwipe: true,
        trackMouse: true,
    });

    const renderSliderImages = () => {
        return (
            <div className={clsxm(style.slider, 'relative pt-2')}>
                <div className={'flex flex-1 space-x-2 overflow-auto p-2'} ref={scrollRef}>
                    {images.map((e, i) => {
                        return (
                            <div key={i} className="relative h-[48px] w-[48px]">
                                <CachedImg
                                    src={e.url}
                                    alt="property"
                                    onClick={() => setIndex(i)}
                                    // layout="fill"
                                    className={clsxm(
                                        'box-content h-full w-full cursor-pointer rounded border border-gray-300 object-cover transition',
                                        i === index && 'border-accent',
                                    )}
                                    draggable={false}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    return (
        <div className="py-3">
            <div className={clsx(style.mainWrap)} {...swipeHandlers}>
                {mainImageQueue.length ? (
                    mainImageQueue.map((e, i) => {
                        return (
                            <CachedImg
                                key={e.key}
                                src={e.url}
                                alt="selected"
                                data-key={e.key}
                                className={clsxm(style.main, i > 0 && style.fadeOut)}
                                draggable="false"
                            />
                        );
                    })
                ) : (
                    <div className="flex h-full items-center justify-center">
                        <Typography className="text-gray-600">{t('noImages')}</Typography>
                    </div>
                )}
            </div>
            {renderSliderImages()}
        </div>
    );
};
