import { PassengersInDto } from 'QuoteForm/useQuoteForm';
import { IEventCaptureLead, IEventRequestMembershipCard, IEventSendInquiry } from './gtag.type';

type GtagFn = (event: string, payload: object) => void;

// "BJS_" prefix
const EMPTY_GTAG: GtagFn = (event, payload, prefix = 'BJS_') => {
    console.log(`gtag('${prefix}${event}', ${JSON.stringify(payload, null, 4)})`);
};

const getGtag = (): { gtag: GtagFn; dataLayer: any[] } => {
    const empty_array: any[] = [];
    // use gtag from window on PROD if available
    const dataLayer: any[] = (window as any).dataLayer || empty_array;
    const isDataLayerNotConnected = dataLayer === empty_array;
    try {
        const gtag: GtagFn = (event, payload) => {
            // structure of dataLayer item depends on Google Tag Manager version
            dataLayer.push({ event, ...payload });
            if (isDataLayerNotConnected) {
                console.log(`gtag('${event}', ${JSON.stringify(payload, null, 4)})`);
            }
        };
        return { gtag, dataLayer };
    } catch {
        console.log('dataLayer not available');
        return { gtag: EMPTY_GTAG, dataLayer };
    }
};

const { gtag: _gtag } = getGtag();

const buildEventDispatcher =
    <T extends object>(name: string) =>
    (
        payload: T,
        { configLabel, withTotalEvent }: { configLabel: string; withTotalEvent?: boolean },
    ) => {
        _gtag(`${name}_${configLabel}`, payload);
        if (withTotalEvent) {
            // total event is label-agnostic to calculate all same events
            // across different labels under the same event name
            _gtag(`${name}_total`, payload);
        }
    };

/**
 * Google Tag Analytics manager to dispatch typed events
 */
export const gtag = {
    mapper: {
        normalizePassengers: (passengers: PassengersInDto) => ({
            pax: passengers.passengers,
            pets: passengers.pets.map(e => e.details).join(', ') || undefined,
        }),
    },

    // meta
    changeTripType: buildEventDispatcher<{ type: string }>('change_trip_type'),
    changeLanguage: buildEventDispatcher<{ language: string }>('change_language'),
    changeCurrency: buildEventDispatcher<{ currency: string }>('change_currency'),
    defaultTripType: buildEventDispatcher<{ value: string }>('default_trip_type'),

    // search form
    selectMainStartAirport: buildEventDispatcher<{ icao: string }>('select_main_start_airport'),
    selectMainEndAirport: buildEventDispatcher<{ icao: string }>('select_main_end_airport'),
    selectMultiCityStartAirport: buildEventDispatcher<{ icao: string; legIndex: number }>(
        'select_multi_city_start_airport',
    ),
    selectMultiCityEndAirport: buildEventDispatcher<{ icao: string; legIndex: number }>(
        'select_multi_city_end_airport',
    ),
    changeMultiCityDate: buildEventDispatcher<{ date: string; legIndex: number }>(
        'change_multi_city_date',
    ),
    changeDepartureDate: buildEventDispatcher<{ date: string }>('change_departure_date'),
    changeReturnDate: buildEventDispatcher<{ date: string }>('change_return_date'),
    changePaxPetsInfo: buildEventDispatcher<{ pax: number; pets?: string }>('change_pax_pets_info'),
    viewEstimates: buildEventDispatcher('view_estimates'),

    // aircraft
    openInquiryForm: buildEventDispatcher<{ aicraftType: string }>('open_inquiry_form'),

    // lead form
    submitLeadForm: buildEventDispatcher<IEventCaptureLead>('submit_lead_form'),
    requestQuote: buildEventDispatcher('request_quote'),
    initiateCall: buildEventDispatcher<{ value: string }>('initiate_call'),

    // membership card
    enterFirstName: buildEventDispatcher<{ value: string }>('enter_first_name'),
    enterLastName: buildEventDispatcher<{ value: string }>('enter_last_name'),
    enterCompany: buildEventDispatcher<{ value: string }>('enter_company'),
    selectCardProgram: buildEventDispatcher<{ value: string }>('select_card_program'),
    requestMembershipCard:
        buildEventDispatcher<IEventRequestMembershipCard>('request_membership_card'),

    // inquiry form
    enterName: buildEventDispatcher<{ value: string }>('enter_name'),
    enterEmail: buildEventDispatcher<{ value: string }>('enter_email'),
    enterPhone: buildEventDispatcher<{ value: string }>('enter_phone'),
    enterComment: buildEventDispatcher<{ value: string }>('enter_comment'),
    sendInquiry: buildEventDispatcher<IEventSendInquiry>('send_inquiry'),
};
