import { ERROR_KEY } from 'constants/errors';
import { InferType, object, string, array, number, date, ValidationError, boolean } from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { AirportOutDtoSchema } from './main.schema';

const validateArraySequence = (legs: LegFormSchema[]) => {
    legs.forEach((leg, index, arr) => {
        const nextLeg = arr[index + 1];
        if (index + 1 < arr.length && leg.date && nextLeg?.date && leg.date >= nextLeg.date) {
            throw new ValidationError(ERROR_KEY.ILLOGICAL_DATE_RANGE, leg, `legs.${index}.date`);
        }
    });
    return true;
};

export const InquireFormSchema = object({
    name: string()
        .required(ERROR_KEY.REQUIRED_FIELD)
        .test('last-name-required', ERROR_KEY.LAST_NAME_REQUIRED, name => {
            return Boolean(name.match(/^[a-z\.]+ [a-z]+/i));
        }),
    email: string().email(ERROR_KEY.INVALID_EMAIL).required(ERROR_KEY.REQUIRED_FIELD),
    phone: string()
        .required(ERROR_KEY.REQUIRED_FIELD)
        .test('phone-number', ERROR_KEY.INVALID_PHONE_NUMBER, phone =>
            isValidPhoneNumber(phone || ''),
        ),
    comment: string(),
    hasFlownBefore: boolean().default(false),
});

export type InquireFormSchema = InferType<typeof InquireFormSchema>;

export const PetsFormSchema = object({
    pets: array()
        .of(
            object({
                details: string().default(''),
            }).test('test', ERROR_KEY.REQUIRED_FIELD, (pet, { parent }) => {
                return !Boolean(parent.length > 1 && !Boolean(pet.details));
            }),
        )
        .required(),
});

export type PetsFormSchema = InferType<typeof PetsFormSchema>;

export const PassengersFormSchema = object({
    passengers: object({
        passengers: number().required(),
    })
        .concat(PetsFormSchema)
        .test('at-least-one-human', ERROR_KEY.AT_LEAST_ONE_HUMAN, ({ passengers }) => {
            return passengers !== 0;
        })
        .required(),
});

export type PassengersFormSchema = InferType<typeof PassengersFormSchema>;

export const LegFormSchema = object({
    startAirport: AirportOutDtoSchema.nullable()
        .defined(ERROR_KEY.REQUIRED_FIELD)
        // if 'null' value should show required message
        .test('required', ERROR_KEY.REQUIRED_FIELD, startAirport => Boolean(startAirport)),
    endAirport: AirportOutDtoSchema.nullable()
        .defined(ERROR_KEY.REQUIRED_FIELD)
        // if 'null' value should show required message
        .test('required', ERROR_KEY.REQUIRED_FIELD, endAirport => Boolean(endAirport))
        .test(
            'the-same-airport',
            ERROR_KEY.AIRPORTS_ARE_THE_SAME,
            (
                endAirport,
                {
                    parent: { startAirport },
                }: { parent: { startAirport: AirportOutDtoSchema | null } },
            ) => {
                return endAirport?.id !== startAirport?.id;
            },
        ),
    date: date()
        .nullable()
        // if 'null' value should show required message
        .test('required', ERROR_KEY.REQUIRED_FIELD, date => Boolean(date))
        .defined(ERROR_KEY.REQUIRED_FIELD),
});

export type LegFormSchema = InferType<typeof LegFormSchema>;

export const OneWayFormSchema = LegFormSchema.concat(PassengersFormSchema);

export type OneWayFormSchema = InferType<typeof OneWayFormSchema>;

export const RoundTripFormSchema = object({
    returnDate: date()
        .nullable()
        .defined(ERROR_KEY.REQUIRED_FIELD)
        .test('required', ERROR_KEY.REQUIRED_FIELD, returnDate => Boolean(returnDate))
        .test(
            'the-same-date',
            ERROR_KEY.DATES_ARE_THE_SAME,
            (returnDate, { parent: { date } }: { parent: { date: Date | null } }) => {
                return returnDate?.getTime() !== date?.getTime();
            },
        )
        .test(
            'return-date-before-start',
            ERROR_KEY.RETURN_DATES_BEFORE_START,
            (returnDate, { parent: { date } }: { parent: { date: Date | null } }) => {
                if (returnDate && date) {
                    return returnDate.getTime() > date.getTime();
                }
            },
        ),
})
    .concat(LegFormSchema)
    .concat(PassengersFormSchema);

export type RoundTripFormSchema = InferType<typeof RoundTripFormSchema>;

export const MultiCityFormSchema = object({
    legs: array()
        .of(LegFormSchema)
        .required()
        .min(2)
        .test(
            'date-next-leg-before-date-prev-leg',
            ERROR_KEY.ILLOGICAL_DATE_RANGE,
            validateArraySequence,
        ),
}).concat(PassengersFormSchema);

export type MultiCityFormSchema = InferType<typeof MultiCityFormSchema>;
