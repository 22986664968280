import { AirportOutDto } from '../swagger-types';

// TODO: clarify name
export const renderAirportName = ({
    airportName,
    iataCode,
    icaoCode,
    countryName,
    cityName,
}: AirportOutDto) => {
    let name = `${icaoCode}, ${airportName}`;

    if (iataCode) {
        name = `${iataCode}/${name}`;
    }
    if (countryName) {
        name += `, ${countryName}`;
    }
    if (cityName) {
        name += `, ${cityName}`;
    }
    return name;
};
