/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Error {
    message?: string;
}

export interface ErrorResponse {
    /** @format int64 */
    timestamp?: number;
    /** @format int32 */
    status?: number;
    errors?: Error[];
}

export interface LegPublicInDto {
    /**
     * @format date-time
     * @default "2023-03-01T00:00:00.909Z"
     */
    date: string;
    /**
     * @format date-time
     * @default "2023-03-01T00:00:00.909Z"
     */
    returnDate?: string;
    /**
     * @format uuid
     * @default "dd2bf7c5-9c47-4397-8bdb-58a7160fa3e4"
     */
    startAirportId: string;
    /**
     * @format uuid
     * @default "8f87b4de-b8d1-4b0e-8d89-a728d03b177a"
     */
    endAirportId: string;
    /**
     * @format int32
     * @default 2
     */
    pax: number;
    pets?: PetInDto[];
}

export interface LegsPublicInDto {
    legs?: LegPublicInDto[];
}

export interface PetInDto {
    /** @default "dog, 8kg" */
    details: string;
}

export interface AircraftOutDto {
    /** @format uuid */
    id: string;
    type: string;
    /** @format int64 */
    retailRateCent?: number;
    webpageUrl?: string;
    /** @format int32 */
    lowPax?: number;
    /** @format int32 */
    highPax?: number;
    /** @format float */
    cruiseSpeed?: number;
    /** @format float */
    range?: number;
    /** @format float */
    endurance?: number;
    /** @format float */
    runwayLength?: number;
    /** @format float */
    lowPriceAdjustment?: number;
    /** @format float */
    highPriceAdjustment?: number;
    isPublished: boolean;
    isAlwaysDisplay: boolean;
    features?: FeatureOutDto[];
    description?: string;
    luggage?: string;
    pictureIds?: string[];
    /** @format date-time */
    createdDate: string;
    /** @format date-time */
    updatedDate?: string;
}

export interface AirportOutDto {
    /** @format uuid */
    id: string;
    airportName: string;
    iataCode?: string;
    icaoCode: string;
    faaCode?: string;
    /** @format double */
    latitude: number;
    /** @format double */
    longitude: number;
    countryName?: string;
    countryIso2?: string;
    cityName?: string;
    jetA?: boolean;
    airportType?: EAirportType;
    airportUsage?: EAirportUsage;
    landingFacilityType?: ELandingFacilityType;
    timezone?: string;
    fax?: string;
    email?: string;
    phone?: string;
    otherPhone?: string;
    url?: string;
    avgas100?: boolean;
    avgas80?: boolean;
}

export interface CommonResponseSearchHitsOutDto {
    result: SearchHitsOutDto[];
}

export interface DayChargesOutDto {
    /** @format date-time */
    date: string;
    /** @format int64 */
    amount: number;
    isMinimumRuleApplied: boolean;
}

export enum EAirportType {
    CIVIL = 'CIVIL',
    JOINT = 'JOINT',
    MILITARY = 'MILITARY',
    NON_STD = 'NON_STD',
}

export enum EAirportUsage {
    PU = 'PU',
    PR = 'PR',
}

export enum ECostBasis {
    LANDING = 'LANDING',
    LEG = 'LEG',
    FLIGHT = 'FLIGHT',
}

export enum ECostType {
    LANDING_FEES_AVG = 'LANDING_FEES_AVG',
    HANDLING_FEES_INTERNATIONAL = 'HANDLING_FEES_INTERNATIONAL',
    ADDED_TIME_MINUTES = 'ADDED_TIME_MINUTES',
}

export enum ECostUnit {
    USD = 'USD',
    MINUTE = 'MINUTE',
}

export enum ELandingFacilityType {
    AIRPORT = 'AIRPORT',
    HELIPORT = 'HELIPORT',
    SEAPLANE_BASE = 'SEAPLANE_BASE',
}

export enum EPricingRule {
    BY_HOURS = 'BY_HOURS',
}

export enum ERuleScope {
    ONE_WAY = 'ONE_WAY',
    ROUND_TRIP = 'ROUND_TRIP',
}

export enum ERuleTrigger {
    PAX_HOURS = 'PAX_HOURS',
}

export interface FeatureOutDto {
    /** @format uuid */
    id: string;
    name: string;
}

export interface FlightAssociatedCostsOutDto {
    costType: ECostType;
    costBasis: ECostBasis;
    costUnit: ECostUnit;
    /** @format double */
    value: number;
}

export interface LegAssociatedCostsOutDto {
    costType: ECostType;
    costBasis: ECostBasis;
    costUnit: ECostUnit;
    /** @format double */
    value: number;
}

export interface LegOutDto {
    date: string;
    startAirport: AirportOutDto;
    endAirport: AirportOutDto;
    /** @format int32 */
    pax: number;
    pets?: PetOutDto[];
    /** @format date-time */
    duration: string;
    /** @format int64 */
    baseBudget: number;
    isFerry: boolean;
    /** @format int32 */
    fuelStopsNeeded: number;
    distance: number;
    /** @format date-time */
    timeInTheAir: string;
    /** @format int64 */
    quoteAdjusted: number;
    legAssociatedCosts: LegAssociatedCostsOutDto[];
}

export interface PetOutDto {
    details: string;
}

export interface SearchHitsOutDto {
    aircraft: AircraftOutDto;
    legs: LegOutDto[];
    /** @format int64 */
    minimumFeePerDayCharges: number;
    /** @format int64 */
    preTotalBudget: number;
    /** @format int64 */
    totalBudget: number;
    /** @format int64 */
    totalLowBudgetRange: number;
    /** @format int64 */
    totalHighBudgetRange: number;
    /** @format date-time */
    totalPaxTime: string;
    /** @format date-time */
    totalTime: string;
    flightAssociatedCosts: FlightAssociatedCostsOutDto[];
    totalAssociatedCosts: TotalAssociatedCostsOutDto[];
    triggeredPricingRule?: TriggeredPricingRuleOutDto[];
    dayCharges: DayChargesOutDto[];
}

export interface TotalAssociatedCostsOutDto {
    costType: ECostType;
    costBasis: ECostBasis;
    costUnit: ECostUnit;
    /** @format double */
    value: number;
}

export interface TriggeredPricingRuleOutDto {
    rule: EPricingRule;
    value: number;
    trigger: ERuleTrigger;
    triggerValue: number;
    scope: ERuleScope;
    /** @format int64 */
    amount: number;
}

export interface WidgetConfigLabelRenameInDto {
    oldLabel: string;
    newLabel: string;
}

export enum EDefaultTab {
    ONE_WAY = 'ONE_WAY',
    ROUND_TRIP = 'ROUND_TRIP',
    MULTI_CITY = 'MULTI_CITY',
}

export interface WidgetConfigOutDto {
    /** @format uuid */
    id: string;
    showContactBeforeEstimate: boolean;
    showSearchResults: boolean;
    doNotDiscloseEstimate: boolean;
    tabOneWayEnabled: boolean;
    tabRoundTripEnabled: boolean;
    tabMultiCityEnabled: boolean;
    enableLeadCapture: boolean;
    enableMembershipCard: boolean;
    defaultTab: EDefaultTab;
    label: string;
    /** @format date-time */
    updatedDate: string;
    /** @format date-time */
    createdDate: string;
}

export interface CustomerInDto {
    firstName: string;
    lastName: string;
    /** @example "12345678912" */
    phone: string;
    /** @example "Kharkiv, Ukraine" */
    address?: string;
    /** @format date-time */
    birthDate?: string;
    /** @format uuid */
    avatarId?: string;
    /** @example "OSDB" */
    company?: string;
    gender?: EGender;
    maritalStatus?: EMaritalStatus;
    language?: string;
}

export enum EGender {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    OTHER = 'OTHER',
}

export enum EMaritalStatus {
    MARRIED = 'MARRIED',
    SINGLE = 'SINGLE',
    DIVORCED = 'DIVORCED',
    WIDOWED = 'WIDOWED',
    OTHER = 'OTHER',
    PREFER_NOT_TO_SAY = 'PREFER_NOT_TO_SAY',
}

export interface CustomerOutDto {
    /** @format uuid */
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    address?: string;
    /** @format date-time */
    birthDate?: string;
    role: ERole;
    /** @format uuid */
    avatarId?: string;
    status: EUserStatus;
    company?: string;
    /** @format date-time */
    createdDate: string;
    settings: UserSettingOutDto[];
    gender?: EGender;
    maritalStatus?: EMaritalStatus;
    language?: string;
}

export enum ERole {
    ROLE_ADMIN = 'ROLE_ADMIN',
    ROLE_CUSTOMER = 'ROLE_CUSTOMER',
}

export enum ESettingType {
    AUTH2FA = 'AUTH_2FA',
}

export enum EUserStatus {
    INACTIVE = 'INACTIVE',
    ACTIVE = 'ACTIVE',
    BANNED = 'BANNED',
    DELETED = 'DELETED',
}

export interface UserSettingOutDto {
    /** @format uuid */
    id: string;
    type: ESettingType;
    isEnabled: boolean;
}

export interface AdSource {
    utmSource?: string;
    utmCampaign?: string;
    utmContent?: string;
    utmMedium?: string;
    utmTerm?: string;
}

export enum EStage {
    PENDING = 'PENDING',
    SOURCING = 'SOURCING',
    QUOTE_CREATION = 'QUOTE_CREATION',
    AIRCRAFT_SELECTION = 'AIRCRAFT_SELECTION',
    CONTACT_SENT = 'CONTACT_SENT',
    CONFIRMED_TRIP = 'CONFIRMED_TRIP',
    FINALIZED_TRIP = 'FINALIZED_TRIP',
    FLOWN = 'FLOWN',
    CLOSED_WON = 'CLOSED_WON',
}

export enum ETypeOfTrip {
    ONE_WAY = 'ONE_WAY',
    ROUND_TRIP = 'ROUND_TRIP',
    MULTI_CITY = 'MULTI_CITY',
}

export interface PageResponseTripReportOutDto {
    result: TripReportOutDto[];
    /** @format int64 */
    total: number;
}

export interface SearchFormOutDto {
    /** @format date-time */
    date: string;
    /** @format date-time */
    arrivalDate: string;
    startAirport: AirportOutDto;
    endAirport: AirportOutDto;
    duration: string;
    /** @format int32 */
    passengers: number;
    pets?: PetOutDto[];
    /** @format int32 */
    fuelStopsNeeded: number;
}

export interface TripReportOutDto {
    id: string;
    aircraft?: AircraftOutDto;
    searchForm: SearchFormOutDto[];
    typeOfTrip: ETypeOfTrip;
    adSource?: AdSource;
    stage: EStage;
    /** @format double */
    highPriceRange: number;
    /** @format double */
    lowPriceRange: number;
    /** @format date-time */
    updatedDate: string;
    /** @format date-time */
    createdDate: string;
}

export interface PageResponseTripReportAdminOutDto {
    result: TripReportAdminOutDto[];
    /** @format int64 */
    total: number;
}

export interface TripReportAdminOutDto {
    id: string;
    user: UserOutDto;
    aircraft?: AircraftOutDto;
    searchForm: SearchFormOutDto[];
    typeOfTrip: ETypeOfTrip;
    adSource?: AdSource;
    stage: EStage;
    /** @format double */
    highPriceRange: number;
    /** @format double */
    lowPriceRange: number;
    /** @format date-time */
    updatedDate: string;
    /** @format date-time */
    createdDate: string;
}

export interface UserOutDto {
    /** @format uuid */
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    address?: string;
    birthDate?: string;
    role: ERole;
    /** @format uuid */
    avatarId?: string;
    status: EUserStatus;
    /** @format date-time */
    createdDate: string;
    settings: UserSettingOutDto[];
    gender?: EGender;
    maritalStatus?: EMaritalStatus;
    language?: string;
}

export interface TripSearchFormInDto {
    /** @format date-time */
    date: string;
    /** @format date-time */
    arrivalDate: string;
    /** @format uuid */
    arrivalAirportId: string;
    /** @format uuid */
    departureAirportId: string;
    /** @format int32 */
    passengers: number;
    petDetails?: PetInDto[];
}

export interface TripUpdateInDto {
    /** @format uuid */
    userId: string;
    /** @format uuid */
    aircraftId: string;
    /** @uniqueItems true */
    searchForm: TripSearchFormInDto[];
    /** @format double */
    highPriceRange: number;
    /** @format double */
    lowPriceRange: number;
}

export interface AircraftAssociatedCostCalculationInDto {
    costTypeBasis: CostTypeBasis;
    /**
     * @format double
     * @default 1000
     */
    value: number;
}

export interface AircraftCalculationInDto {
    /** @format uuid */
    id: string;
    type: string;
    /** @format int64 */
    retailRateCent?: number;
    webpageUrl?: string;
    /** @format int32 */
    lowPax?: number;
    /** @format int32 */
    highPax?: number;
    /** @format float */
    cruiseSpeed?: number;
    /** @format float */
    range?: number;
    /** @format float */
    endurance?: number;
    /** @format float */
    runwayLength?: number;
    /** @format float */
    lowPriceAdjustment?: number;
    /** @format float */
    highPriceAdjustment?: number;
    /** @format double */
    minimumHoursPerDay?: number;
    /** @format double */
    imperativeMinimumDailyRate?: number;
    /** @format double */
    ferryRate?: number;
    associatedCosts?: AircraftAssociatedCostCalculationInDto[];
    pricingRules?: PricingRuleInDto[];
    /** @format int32 */
    minimumChargePerLegCent?: number;
    isPublished: boolean;
    isAlwaysDisplay: boolean;
    features?: FeatureInDto[];
    specialRoutes?: SpecialRoutesInDto[];
    description?: string;
    luggage?: string;
    pictureIds?: string[];
    /** @format date-time */
    createdDate: string;
    /** @format date-time */
    updatedDate?: string;
}

export interface AircraftCalculationLegsInDto {
    legs: LegPrivateInDto[];
    aircraft: AircraftCalculationInDto;
}

export interface AirportCalculationInDto {
    /** @format uuid */
    id: string;
    airportName: string;
    iataCode?: string;
    faaCode?: string;
    icaoCode: string;
    /** @format double */
    latitude: number;
    /** @format double */
    longitude: number;
    countryName?: string;
    countryIso2?: string;
    cityName?: string;
    jetA?: boolean;
    airportType?: EAirportType;
    airportUsage?: EAirportUsage;
    landingFacilityType?: ELandingFacilityType;
    timezone?: string;
    fax?: string;
    email?: string;
    phone?: string;
    otherPhone?: string;
    url?: string;
    avgas100?: boolean;
    avgas80?: boolean;
}

export interface CostTypeBasis {
    /** @format uuid */
    id?: string;
    costType: ECostType;
    costBasis: ECostBasis;
    costUnit: ECostUnit;
}

export interface FeatureInDto {
    /** @format uuid */
    id?: string;
    name: string;
}

export interface LegPrivateInDto {
    /**
     * @format date-time
     * @default "2022-11-02T00:00:00.909Z"
     */
    date: string;
    /**
     * @format date-time
     * @default "2022-12-02T00:00:00.909Z"
     */
    returnDate?: string;
    startAirport: AirportCalculationInDto;
    endAirport: AirportCalculationInDto;
    /**
     * @format int32
     * @default 2
     */
    pax: number;
    pets?: PetInDto[];
}

export interface PricingRuleInDto {
    rule: EPricingRule;
    /**
     * @format double
     * @default 1000
     */
    value: number;
    trigger: ERuleTrigger;
    /**
     * @format double
     * @default 1000
     */
    triggerValue: number;
    scope: ERuleScope;
}

export interface SpecialRoutesInDto {
    /** @format uuid */
    id?: string;
    airportLeft: AirportCalculationInDto;
    airportRight: AirportCalculationInDto;
    /** @format double */
    radiusLeft: number;
    /** @format double */
    radiusRight: number;
    color: string;
    /**
     * @format double
     * @default 8000
     */
    lowPrice: number;
    /**
     * @format double
     * @default 10000
     */
    highPrice: number;
}

export interface AdminInDto {
    firstName: string;
    lastName: string;
    /** @example "1234567891" */
    phone: string;
    /** @example "Kharkiv, Ukraine" */
    address?: string;
    /** @format date-time */
    birthDate?: string;
    /** @format uuid */
    avatarId?: string;
    gender?: EGender;
    maritalStatus?: EMaritalStatus;
    language?: string;
}

export interface AircraftFeatureInDto {
    features?: FeatureInDto[];
}

export interface ContactInDto {
    /** @default "Oleksandr" */
    firstName: string;
    /** @default "Kogutenko" */
    lastName: string;
    /** @default "okogutenko@osdb.io" */
    email: string;
    /** @default "+380500000000" */
    phone: string;
    /** @default false */
    hasFlownBefore: boolean;
}

export interface ContactOutDto {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    hasFlownBefore: boolean;
}

export enum EActivateMembership {
    ASAP = 'ASAP',
    WITHIN_ONE_MONTH = 'WITHIN_ONE_MONTH',
    ONE_TWO_MONTHS = 'ONE_TWO_MONTHS',
    THREE_SIX_MONTHS = 'THREE_SIX_MONTHS',
    JUST_LOOKING = 'JUST_LOOKING',
}

export enum EMembershipCardType {
    ASCENT = 'ASCENT',
    CARDINAL = 'CARDINAL',
    SOVEREIGNTY = 'SOVEREIGNTY',
    UNDECIDED = 'UNDECIDED',
}

export interface MembershipCardContactFormInDto {
    deviceSizeType?: string;
    deviceBrowserName?: string;
    deviceBrowserVersion?: string;
    deviceOperatingSystem?: string;
    /** @default "okogutenko+lead@osdb.io" */
    email: string;
    /**
     * @default "+380999999911"
     * @pattern ^\+[1-9]{1,3}[0-9]{7,11}$
     */
    phone: string;
    /** @default "Oleksandr" */
    firstName: string;
    /** @default "Kogutenko" */
    lastName: string;
    /** @default "OSDB" */
    company?: string;
    hasAJetCard: boolean;
    cardType: EMembershipCardType;
    activateMembership: EActivateMembership;
}

export interface MembershipCardInfoInDto {
    contactForm: MembershipCardContactFormInDto;
    adSource?: AdSource;
    googleClientId?: string;
    googleClickId?: string;
}

export interface ContactFormInDto {
    deviceSizeType?: string;
    deviceBrowserName?: string;
    deviceBrowserVersion?: string;
    deviceOperatingSystem?: string;
    /** @default "okogutenko+lead@osdb.io" */
    email: string;
    /**
     * @default "+380999999911"
     * @pattern ^\+[1-9]{1,3}[0-9]{7,11}$
     */
    phone: string;
    /** @default "Oleksandr Kogutenko" */
    name: string;
    /** @default "OSDB" */
    company?: string;
    /**
     * @minLength 0
     * @maxLength 255
     * @default "Some comment"
     */
    comment?: string;
    /** @default false */
    hasFlownBefore: boolean;
    adSource?: AdSource;
    googleClientId?: string;
    googleClickId?: string;
}

export interface InquiryPaxLegsInDto {
    /** @format int32 */
    indexLeg: number;
    /** @default "26:44" */
    flightTime: string;
}

export interface InquiryPriceRangeInDto {
    /** @default "10.0" */
    lowPrice: string;
    /** @default "80.0" */
    highPrice: string;
    /** @default "USD" */
    currency: string;
}

export interface SearchFormInDto {
    legs: SearchFormLegsInDto[];
    /**
     * @format int32
     * @default 2
     */
    passengers: number;
    pets?: PetInDto[];
}

export interface SearchFormLegsInDto {
    /**
     * @format date-time
     * @default "2022-10-02T00:00:00.909Z"
     */
    date: string;
    /**
     * @format date-time
     * @default "2022-10-02T00:00:00.909Z"
     */
    returnDate?: string;
    /** @format uuid */
    startAirportId: string;
    /** @format uuid */
    endAirportId: string;
}

export interface SelectedAircraftInDto {
    priceRange: InquiryPriceRangeInDto;
    paxLegs: InquiryPaxLegsInDto[];
    /** @format uuid */
    virtualFleetId: string;
}

export interface TripInDto {
    deviceSizeType?: string;
    deviceBrowserName?: string;
    deviceBrowserVersion?: string;
    deviceOperatingSystem?: string;
    notifyEmails?: string[];
    searchForm: SearchFormInDto;
    selectedAircraft?: SelectedAircraftInDto;
    contactForm: ContactFormInDto;
    widgetConfig: WidgetConfigInquiryInDto;
    adSource?: AdSource;
    googleClientId?: string;
    googleClickId?: string;
    tripType?: string;
}

export interface WidgetConfigInquiryInDto {
    label: string;
    showContactBeforeEstimate?: boolean;
    showSearchResults?: boolean;
    doNotDiscloseEstimate?: boolean;
    tabOneWayEnabled?: boolean;
    tabRoundTripEnabled?: boolean;
    tabMultiCityEnabled?: boolean;
    enableLeadCapture?: boolean;
    defaultTab?: EDefaultTab;
}

/** @example "COOKIE" */
export enum EAuthType {
    COOKIE = 'COOKIE',
    TOKEN = 'TOKEN',
}

export interface SignUpStepTwoInDto {
    /** @example "ssmith@osdb.io" */
    email: string;
    emailActivationCode: string;
    authType?: EAuthType;
}

export interface AuthOutDto {
    /** @format uuid */
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    address?: string;
    /** @format date-time */
    birthDate?: string;
    company?: string;
    role: ERole;
    /** @format uuid */
    avatarId?: string;
    status: EUserStatus;
    /** @format date-time */
    createdDate: string;
    settings: UserSettingOutDto[];
    accessToken?: string;
    refreshToken?: string;
    gender?: EGender;
    maritalStatus?: EMaritalStatus;
    language?: string;
}

export interface SignUpStepOneInDto {
    /** @example "ssmith@osdb.io" */
    email: string;
    /** @example "ssmith@osdb.io" */
    password: string;
    /** @example "Smith" */
    firstName: string;
    /** @example "Simon" */
    lastName: string;
    /** @example "12345678912" */
    phone?: string;
    /** @example "Kharkiv, Ukraine" */
    address?: string;
    /** @format date-time */
    birthDate?: string;
    /** @example "OSDB" */
    company?: string;
    gender?: EGender;
    maritalStatus?: EMaritalStatus;
    language?: string;
}

export interface SignInStepOneInDto {
    /** @example "dberchiyan@osdb.io" */
    email: string;
    /** @example "dberchiyan@osdb.io" */
    password: string;
    authType?: EAuthType;
}

export interface SignInStepTwoInDto {
    /** @example "dberchiyan@osdb.io" */
    email: string;
    confirmCode: string;
    authType?: EAuthType;
}

export interface UpdateTokensInDto {
    refreshToken: string;
}

export interface AuthTokensOutDto {
    accessToken: string;
    refreshToken: string;
}

export interface InvitationSignUpInDto {
    /** @format uuid */
    invitationToken: string;
    password: string;
    firstName: string;
    lastName: string;
    /** @example "12345678912" */
    phone?: string;
    /** @example "Kharkiv, Ukraine" */
    address?: string;
    /** @format date-time */
    birthDate?: string;
    /** @example "OSDB" */
    company?: string;
    authType?: EAuthType;
    gender?: EGender;
    maritalStatus?: EMaritalStatus;
    language?: string;
}

export interface ResetPasswordInDto {
    /** @example "dberchiyan@osdb.io" */
    email: string;
    confirmCode: string;
    /** @example "dberchiyan@osdb.io" */
    newPassword: string;
}

export interface ForgotPasswordInDto {
    /** @example "dberchiyan@osdb.io" */
    email: string;
}

export interface InviteInDto {
    email: string;
    /**
     * @pattern ROLE_CUSTOMER
     * @example "ROLE_CUSTOMER"
     */
    role?: string;
}

export interface FileOutDto {
    /** @format uuid */
    id: string;
    fileName: string;
    /** @format date-time */
    createdDate: string;
}

export interface WidgetConfigInDto {
    label?: string;
    showContactBeforeEstimate?: boolean;
    showSearchResults?: boolean;
    doNotDiscloseEstimate?: boolean;
    tabOneWayEnabled?: boolean;
    tabRoundTripEnabled?: boolean;
    tabMultiCityEnabled?: boolean;
    enableLeadCapture?: boolean;
    enableMembershipCard?: boolean;
    defaultTab?: EDefaultTab;
}

export interface UserStatusInDto {
    /**
     * @pattern ACTIVE|BANNED|DELETED
     * @example "ACTIVE"
     */
    status: string;
}

export interface InvitationStatusInDto {
    /**
     * @pattern PENDING|CANCELED
     * @example "PENDING"
     */
    status: string;
}

export interface ChangePasswordInDto {
    /** @example "dberchiyan@osdb.io" */
    currPassword: string;
    /** @example "dberchiyan@osdb.io" */
    newPassword: string;
}

export interface CEStepTwoInDto {
    currEmailConfirmCode: string;
    newEmailConfirmCode: string;
}

export interface CEStepOneInDto {
    newEmail: string;
}

export interface TwoFAStepTwoInDto {
    confirmCode: string;
    isEnabled: boolean;
}

export interface FleetConfigInDto {
    /** @format int32 */
    allowFuelStops: number;
}

export interface FleetConfigOutDto {
    /** @format int32 */
    allowFuelStops: number;
}

export interface AircraftAssociatedCostsInDto {
    /**
     * @format uuid
     * @default "bc0b3fb1-06da-47ff-9555-4bc11afab433"
     */
    costTypeBasisId: string;
    /**
     * @format double
     * @default 1000
     */
    value: number;
}

export interface AircraftInDto {
    type: string;
    /** @format int64 */
    retailRateCent?: number;
    webpageUrl?: string;
    /** @format int32 */
    lowPax?: number;
    /** @format int32 */
    highPax?: number;
    /** @format float */
    cruiseSpeed?: number;
    /** @format float */
    range?: number;
    /** @format float */
    endurance?: number;
    /** @format float */
    runwayLength?: number;
    /** @format float */
    lowPriceAdjustment?: number;
    /** @format float */
    highPriceAdjustment?: number;
    /** @format double */
    minimumHoursPerDay?: number;
    /** @format double */
    imperativeMinimumDailyRate?: number;
    /** @format double */
    ferryRate?: number;
    associatedCosts?: AircraftAssociatedCostsInDto[];
    pricingRules?: PricingRuleInDto[];
    /** @format int32 */
    minimumChargePerLegCent?: number;
    isPublished: boolean;
    isAlwaysDisplay: boolean;
    features?: FeatureInDto[];
    specialRoutes?: SpecialRoutesInDto[];
    description?: string;
    luggage?: string;
    pictureIds?: string[];
}

export interface AircraftAdminOutDto {
    /** @format uuid */
    id: string;
    type: string;
    /** @format int64 */
    retailRateCent?: number;
    webpageUrl?: string;
    /** @format int32 */
    lowPax?: number;
    /** @format int32 */
    highPax?: number;
    /** @format float */
    cruiseSpeed?: number;
    /** @format float */
    range?: number;
    /** @format float */
    endurance?: number;
    /** @format float */
    runwayLength?: number;
    /** @format float */
    lowPriceAdjustment?: number;
    /** @format float */
    highPriceAdjustment?: number;
    isPublished: boolean;
    isAlwaysDisplay: boolean;
    features?: FeatureOutDto[];
    specialRoutes?: SpecialRouteOutDto[];
    /** @format double */
    minimumHoursPerDay?: number;
    /** @format double */
    imperativeMinimumDailyRate?: number;
    /** @format double */
    ferryRate?: number;
    associatedCosts?: AircraftAssociatedCostOutDto[];
    pricingRules?: PricingRuleOutDto[];
    description?: string;
    luggage?: string;
    /** @format int32 */
    minimumChargePerLegCent?: number;
    pictureIds?: string[];
    /** @format date-time */
    createdDate: string;
    /** @format date-time */
    updatedDate?: string;
}

export interface AircraftAssociatedCostOutDto {
    costTypeBasis: CostTypeBasisOutDto;
    /** @format double */
    value: number;
}

export interface CostTypeBasisOutDto {
    /** @format uuid */
    id: string;
    costType: ECostType;
    costBasis: ECostBasis;
    costUnit: ECostUnit;
}

export interface PricingRuleOutDto {
    /** @format uuid */
    id: string;
    rule: EPricingRule;
    /** @format double */
    value: number;
    trigger: ERuleTrigger;
    /** @format double */
    triggerValue: number;
    scope: ERuleScope;
}

export interface SpecialRouteOutDto {
    /** @format uuid */
    id: string;
    airportLeft: AirportOutDto;
    airportRight: AirportOutDto;
    /** @format double */
    radiusLeft: number;
    /** @format double */
    radiusRight: number;
    color: string;
    /** @format double */
    lowPrice: number;
    /** @format double */
    highPrice: number;
}

export interface PageResponseWidgetConfigOutDto {
    result: WidgetConfigOutDto[];
    /** @format int64 */
    total: number;
}

export interface PageResponseAirportOutDto {
    result: AirportOutDto[];
    /** @format int64 */
    total: number;
}

export enum EFileType {
    CSV = 'CSV',
    EXCEL = 'EXCEL',
}

export enum EInvitationStatus {
    PENDING = 'PENDING',
    CANCELED = 'CANCELED',
    REGISTERED = 'REGISTERED',
}

export interface PageResponseUserInvitationOutDto {
    result: UserInvitationOutDto[];
    /** @format int64 */
    total: number;
}

export interface UserInvitationOutDto {
    /** @format uuid */
    id: string;
    email: string;
    /** @format uuid */
    token: string;
    role: ERole;
    status: EInvitationStatus;
    /** @format date-time */
    createdDate: string;
    /** @format uuid */
    invitedBy: string;
}

export interface CommonResponseSpecialRouteOutDto {
    result: SpecialRouteOutDto[];
}

export interface TripStageDto {
    stage?: string;
}

export interface CommonResponseFeatureOutDto {
    result: FeatureOutDto[];
}

export interface PageResponseCustomerOutDto {
    result: CustomerOutDto[];
    /** @format int64 */
    total: number;
}

export interface UserSettingListOutDto {
    settings: UserSettingOutDto[];
}

export interface UserSessionListOutDto {
    sessions: UserSessionOutDto[];
}

export interface UserSessionOutDto {
    /** @format uuid */
    id: string;
    ipAddress: string;
    platform: string;
    isCurrent: boolean;
    /** @format date-time */
    createdDate: string;
}

export interface CommonResponseAircraftAdminOutDto {
    result: AircraftAdminOutDto[];
}

export interface CommonResponseCostTypeBasisOutDto {
    result: CostTypeBasisOutDto[];
}

export interface PageResponseUserOutDto {
    result: UserOutDto[];
    /** @format int64 */
    total: number;
}
