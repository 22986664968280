import { IS_NOT_PROD_ENV } from 'environment/typedEnv';

/* Render this component to give a signal for the pixel to track conversion */
export const PixelConversionSignal: React.FC = () => {
    if (IS_NOT_PROD_ENV) {
        // keep element render always to test layout rendering on DEV env
        // e.g. flex layout may stretch this image to full available size
        return <img src="/favicon.ico" width="1" height="1" className="opacity-0" />;
    }
    return <img src="https://pm.geniusmonkey.com/gm.png?id=226603202" width="1" height="1" />;
};
