import { createRef, useEffect } from 'react';

export const useGetHeightOfIframe = () => {
    const refForGetHeightOfIframe = createRef<HTMLDivElement>();

    useEffect(() => {
        if (window && window.parent) {
            const parentURL =
                window.location != window.parent.location
                    ? document.referrer
                    : document.location.href;

            window.parent.postMessage(
                { heightOfIframe: refForGetHeightOfIframe.current?.clientHeight || 0 },
                parentURL,
            );
        }
    }, [refForGetHeightOfIframe]);

    return refForGetHeightOfIframe;
};
