import { typedEnv } from 'environment/typedEnv';

export enum FIAT_TICKERS {
    USD = 'USD',
    EUR = 'EUR',
    GBP = 'GBP',
}

enum CRYPTO_TICKERS {
    BTCUSDT = 'BTCUSDT',
    ETHUSDT = 'ETHUSDT',
    SOLUSDT = 'SOLUSDT',
}

const INITIAL_RATES = {
    USD: 1,
    EUR: 0,
    GBP: 0,
    BTC: 0,
    ETH: 0,
    SOL: 0,
};

const FIAT_KEYS = Object.keys(FIAT_TICKERS);
export const checkIsFiatCurrency = (name: string) => FIAT_KEYS.includes(name);

const CRYPTO_KEYS = Object.keys(INITIAL_RATES).filter(e => !checkIsFiatCurrency(e));
export const checkIsCryptoCurrency = (name: string) => CRYPTO_KEYS.includes(name);

export const CURRENCIES = Object.keys(INITIAL_RATES);
export type CURRENCY_KEY = keyof typeof INITIAL_RATES;

type MutableObject<Type> = {
    -readonly [Property in keyof Type]: Type[Property];
};

/**
 * Instantiate manager only once
 */
export class ExchangeManager {
    readonly rates = { ...INITIAL_RATES } as const;
    private isReady = false;
    private mutableRates = this.rates as MutableObject<typeof this.rates>;
    private loadingPromise: Promise<unknown> | undefined;

    checkIsReady() {
        return this.isReady;
    }

    checkIsLoading() {
        return Boolean(this.loadingPromise);
    }

    /**
     * idea: proxy request via backend to cache rates
     * because rates on service updated daily
     * https://exchangerate.host/product
     * so it makes sense to cache on backend like every 4 hours
     * to fit inside free plan limits + do not expose API key on frontend
     **/
    private async loadFiatRates() {
        // https not supported in free plan
        // const requestURL = 'http://api.exchangerate.host/live';
        const requestURL = `${typedEnv.REACT_APP_API_BASE_URL}public/currencies/rates`;
        const params = new URLSearchParams();
        params.set('currencies', [FIAT_TICKERS.USD, FIAT_TICKERS.EUR, FIAT_TICKERS.GBP].join(','));
        params.set('source', 'USD');
        // free API key registered via temp email and password fetaha1121@vasteron.com
        params.set('access_key', 'e6adb428fedcbcf4f3366318dde6e723');
        const res = await fetch(`${requestURL}?${params.toString()}`);
        const data: {
            quotes?: {
                USDEUR: number;
                USDGBP: number;
            };
        } = await res.json();

        if (!data.quotes) {
            throw new Error('no currency quotes');
        }

        Object.entries(data.quotes).forEach(([key, price]) => {
            if (key === 'USDEUR') {
                this.mutableRates.EUR = 1 / price;
                return;
            }
            if (key === 'USDGBP') {
                this.mutableRates.GBP = 1 / price;
                return;
            }
            if (key === 'USDUSD') {
                return;
            }
            throw new Error('unhandled fiat ticker mapping');
        });
        // console.log('fiat quotes', data.quotes);
    }

    private async loadCryptoRates() {
        const requestURL = 'https://api.binance.com/api/v3/ticker/price';
        const params = new URLSearchParams();
        params.set(
            'symbols',
            `[${[CRYPTO_TICKERS.BTCUSDT, CRYPTO_TICKERS.ETHUSDT, CRYPTO_TICKERS.SOLUSDT]
                .map(e => `"${e}"`)
                .join(',')}]`,
        );
        const res = await fetch(`${requestURL}?${params.toString()}`);
        const data: {
            symbol: CRYPTO_TICKERS;
            price: string;
        }[] = await res.json();

        data.forEach(e => {
            if (e.symbol === CRYPTO_TICKERS.BTCUSDT) {
                this.mutableRates.BTC = 1 / +e.price;
                return;
            }
            if (e.symbol === CRYPTO_TICKERS.ETHUSDT) {
                this.mutableRates.ETH = 1 / +e.price;
                return;
            }
            if (e.symbol === CRYPTO_TICKERS.SOLUSDT) {
                this.mutableRates.SOL = 1 / +e.price;
                return;
            }
            throw new Error('unhandled crypto ticker mapping');
        });
        // console.log('crypto rates', data);
    }

    /**
     * Load rates or use cached data.
     * Safe to call this method multiple times at once without making multiple network requests.
     */
    private async safelyLoadAllRates() {
        if (this.isReady) {
            return;
        }
        if (this.loadingPromise) {
            await this.loadingPromise;
            return;
        }
        try {
            this.loadingPromise = Promise.all([this.loadFiatRates(), this.loadCryptoRates()]);
            await this.loadingPromise;
            this.isReady = true;
        } finally {
            this.loadingPromise = undefined;
        }
    }

    async prepareRates() {
        await this.safelyLoadAllRates();
    }

    async getCurrencyPrice(fromUSDAmount: number, intoCurrency: CURRENCY_KEY) {
        if (intoCurrency === 'USD') {
            return fromUSDAmount;
        }
        await this.loadingPromise;
        return this.rates[intoCurrency] * fromUSDAmount;
    }

    getCurrencyPriceSync(fromUSDAmount: number, intoCurrency: CURRENCY_KEY) {
        if (intoCurrency === 'USD') {
            return fromUSDAmount;
        }
        this.loadingPromise;
        return this.rates[intoCurrency] * fromUSDAmount;
    }
}

export const exchangeManager = new ExchangeManager();
exchangeManager.prepareRates();

// DEMO of usage
// const run = async () => {
//     const em = new ExchangeManager();
//     await em.prepareRates();
//     const usd = 200;
//     const currency: CURRENCY_KEY = 'ETH';
//     const result = em.getCurrencyPriceSync(usd, currency);
//     console.log(`${usd} USD is ${result} ${currency}`);
//     console.log(em.rates);
// };
// run();
