import { truncate } from 'lodash';

import { Dialog as MuiDialog, DialogProps, Typography, IconButton } from '@mui/material';
import { clsxm } from '../../utils/clsxm';
import { Close as CloseIcon } from '@mui/icons-material';

type Props = {
    headerTitle?: string;
    paperClassName?: string;
} & DialogProps;

export const Dialog: React.FC<Props> = ({
    children,
    open,
    headerTitle,
    onClose,
    paperClassName,
    ...props
}) => {
    return (
        <MuiDialog
            open={open}
            PaperProps={{
                className: clsxm(
                    'rounded-[40px] py-6 px-4 !m-2 !max-w-[1000px]',
                    'md:py-12 md:px-14',
                    paperClassName,
                ),
            }}
            onClose={onClose}
            {...props}
            disablePortal>
            <div className="flex items-center justify-between">
                <Typography noWrap>{truncate(headerTitle, { length: 120 })}</Typography>
                {onClose && (
                    <IconButton onClick={() => onClose({}, 'backdropClick')}>
                        <CloseIcon />
                    </IconButton>
                )}
            </div>
            {children}
        </MuiDialog>
    );
};
