// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContainerLoader_container__KlQMi{left:0;top:0;width:100%;height:100%;display:flex;align-items:center;justify-content:center;flex-direction:column;z-index:10;flex:1 1}.ContainerLoader_container__KlQMi.ContainerLoader_rounded__r2oga{border-radius:30px}.ContainerLoader_retryContainer__\\+-WgL{margin-top:16px}", "",{"version":3,"sources":["webpack://./src/components/container-loader/ContainerLoader.module.scss"],"names":[],"mappings":"AAAA,kCAAW,MAAM,CAAC,KAAK,CAAC,UAAU,CAAC,WAAW,CAAC,YAAY,CAAC,kBAAkB,CAAC,sBAAsB,CAAC,qBAAqB,CAAC,UAAU,CAAC,QAAM,CAAC,iEAAmB,kBAAkB,CAAC,wCAAgB,eAAe","sourcesContent":[".container{left:0;top:0;width:100%;height:100%;display:flex;align-items:center;justify-content:center;flex-direction:column;z-index:10;flex:1}.container.rounded{border-radius:30px}.retryContainer{margin-top:16px}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ContainerLoader_container__KlQMi",
	"rounded": "ContainerLoader_rounded__r2oga",
	"retryContainer": "ContainerLoader_retryContainer__+-WgL"
};
export default ___CSS_LOADER_EXPORT___;
