import { Input } from 'components/input/Input';
import { CurrencyRenderer } from 'exchange-manager/CurrencyRenderer';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const DEFAULT_AMOUNT = 5e3;

export const UIKitCurrencyRenderer: React.FC = () => {
    const [usd, setUsd] = useState(0);

    const amount = usd || DEFAULT_AMOUNT;
    return (
        <div className="bg-white/50">
            <Link to="/">Home page</Link>
            <div className="my-4 flex flex-col gap-2 bg-white p-4">
                <Input
                    value={usd || ''}
                    onChange={e => setUsd(+e.target.value)}
                    placeholder={`${DEFAULT_AMOUNT}`}
                />
                <CurrencyRenderer usdAmount={amount} asCurrency="USD" />
                <CurrencyRenderer usdAmount={amount} asCurrency="EUR" />
                <CurrencyRenderer usdAmount={amount} asCurrency="GBP" />
                <CurrencyRenderer usdAmount={amount} asCurrency="BTC" />
                <CurrencyRenderer usdAmount={amount} asCurrency="ETH" />
                <CurrencyRenderer usdAmount={amount} asCurrency="SOL" />
            </div>
        </div>
    );
};
