// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_mainWrap__wdSm0{position:relative;height:188px;margin-left:0.5rem;margin-right:0.5rem}.style_main__rrXq7{border-radius:0.25rem;max-width:100%;max-height:100%;object-fit:contain;position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);transition:opacity .3s}.style_main__rrXq7.style_fadeOut__X8B2e{opacity:0}.style_slider__b70i1{display:flex}", "",{"version":3,"sources":["webpack://./src/components/carousel/style.module.scss"],"names":[],"mappings":"AAAA,uBAAU,iBAAiB,CAAC,YAAY,CAAC,kBAAU,CAAV,mBAAW,CAAO,mBAAA,qBAAc,CAAC,cAAc,CAAC,eAAe,CAAC,kBAAkB,CAAC,iBAAiB,CAAC,OAAO,CAAC,QAAQ,CAAC,uCAA+B,CAA/B,+BAA+B,CAAC,sBAAtH,CAA6I,wCAAc,SAAS,CAAC,qBAAQ,YAAY","sourcesContent":[".mainWrap{position:relative;height:188px;@apply mx-2}.main{@apply rounded;max-width:100%;max-height:100%;object-fit:contain;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);transition:opacity .3s}.main.fadeOut{opacity:0}.slider{display:flex}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainWrap": "style_mainWrap__wdSm0",
	"main": "style_main__rrXq7",
	"fadeOut": "style_fadeOut__X8B2e",
	"slider": "style_slider__b70i1"
};
export default ___CSS_LOADER_EXPORT___;
