export const ERROR_KEY = {
    REQUIRED_FIELD: 'REQUIRED_FIELD',
    LAST_NAME_REQUIRED: 'LAST_NAME_REQUIRED',
    INVALID_EMAIL: 'INVALID_EMAIL',
    AT_LEAST_ONE_HUMAN: 'AT_LEAST_ONE_HUMAN',
    AIRPORTS_ARE_THE_SAME: 'AIRPORTS_ARE_THE_SAME',
    DATES_ARE_THE_SAME: 'DATES_ARE_THE_SAME',
    RETURN_DATES_BEFORE_START: 'RETURN_DATES_BEFORE_START',
    INVALID_PHONE_NUMBER: 'INVALID_PHONE_NUMBER',
    ILLOGICAL_DATE_RANGE: 'ILLOGICAL_DATE_RANGE',
};
