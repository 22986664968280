import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { InfoIcon } from '../assets/icons';

export const BookingSearchResultWrapper: React.FC<{
    showSearchResults: boolean;
    total: number;
    children: React.ReactNode;
    loading?: boolean;
    emptyLegsMayBeAvailable?: boolean;
}> = ({ showSearchResults, total, children, loading, emptyLegsMayBeAvailable }) => {
    const { t } = useTranslation();

    if (loading) {
        return null;
    }

    return (
        <div className="mt-10 flex flex-col gap-4">
            <div className="flex items-end justify-between">
                <div className="flex flex-col gap-3">
                    <Typography className="text-[1.75rem] font-semibold capitalize leading-[2.375rem] text-white">
                        {showSearchResults ? t('yourEstimatesAreBelow') : t('enterContactDetails')}
                    </Typography>
                    {emptyLegsMayBeAvailable && (
                        <Typography className="flex gap-1 text-neutral-400">
                            <InfoIcon /> {t('emptyLegsMayBeAvailable')}
                        </Typography>
                    )}
                </div>
                {showSearchResults && (
                    <Typography className="text-white">
                        {t('matchesFound', { count: total })}
                    </Typography>
                )}
            </div>
            {children}
        </div>
    );
};
