import { InferType, object, string, number, boolean, array } from 'yup';
import {
    EAirportType,
    EAirportUsage,
    ECostBasis,
    ECostType,
    ECostUnit,
    EPricingRule,
    ERuleScope,
    ERuleTrigger,
} from '../swagger-types';
import { oneOfEnum } from '../utils/yup.utils';
import { convertCentToUnit } from '../utils/currency.utils';

export const AirportOutDtoSchema = object({
    id: string().required(),
    airportName: string().required(),
    latitude: number().required(),
    longitude: number().required(),
    icaoCode: string().required(),
    iataCode: string().optional().default(undefined),
    countryName: string().optional().default(undefined),
    countryIso2: string().optional().default(undefined),
    cityName: string().optional().default(undefined),
    jetA: boolean().optional().default(undefined),
    avgas100: boolean().optional().default(undefined),
    avgas80: boolean().optional().default(undefined),
    airportType: oneOfEnum(EAirportType).optional().default(undefined),
    airportUsage: oneOfEnum(EAirportUsage).optional().default(undefined),
});

export type AirportOutDtoSchema = InferType<typeof AirportOutDtoSchema>;

export const PetsOutDtoSchema = object({
    details: string().required(),
});

export type PetsOutDtoSchema = InferType<typeof PetsOutDtoSchema>;

export const FeatureOutDtoSchema = object({
    id: string().required(),
    name: string().required(),
});

export type FeatureOutDtoSchema = InferType<typeof FeatureOutDtoSchema>;

export const DayChargesOutDtoSchema = object({
    date: string().required(),
    amount: number().required(),
    isMinimumRuleApplied: boolean().required(),
});

export type DayChargesOutDtoSchema = InferType<typeof DayChargesOutDtoSchema>;

export const TriggeredPricingRuleOutDtoSchema = object({
    value: number().required(),
    triggerValue: number().required(),
    amount: number().required(),
    rule: oneOfEnum(EPricingRule).required(),
    trigger: oneOfEnum(ERuleTrigger).required(),
    scope: oneOfEnum(ERuleScope).required(),
});

export type TriggeredPricingRuleOutDtoSchema = InferType<typeof TriggeredPricingRuleOutDtoSchema>;

export const AssociatedCostsOutDtoSchema = object({
    value: number().required(),
    costType: oneOfEnum(ECostType).required(),
    costBasis: oneOfEnum(ECostBasis).required(),
    costUnit: oneOfEnum(ECostUnit).required(),
});

export type AssociatedCostsOutDtoSchema = InferType<typeof AssociatedCostsOutDtoSchema>;

export const LegAssociatedCostsOutDtoSchema = AssociatedCostsOutDtoSchema;

export type LegAssociatedCostsOutDtoSchema = InferType<typeof LegAssociatedCostsOutDtoSchema>;

export const FlightAssociatedCostsOutDtoSchema = AssociatedCostsOutDtoSchema;

export type FlightAssociatedCostsOutDtoSchema = InferType<typeof FlightAssociatedCostsOutDtoSchema>;

export const TotalAssociatedCostsOutDtoSchema = AssociatedCostsOutDtoSchema;

export type TotalAssociatedCostsOutDtoSchema = InferType<typeof TotalAssociatedCostsOutDtoSchema>;

export const AircraftOutDtoSchema = object({
    id: string().required(),
    type: string().required(),
    createdDate: string().required(),
    isPublished: boolean().required(),
    isAlwaysDisplay: boolean().required(),
    webpageUrl: string().optional().default(undefined),
    description: string().optional().default(undefined),
    luggage: string().optional().default(undefined),
    updatedDate: string().optional().default(undefined),
    retailRateCent: number().optional().default(undefined),
    lowPax: number().optional().default(undefined),
    highPax: number().optional().default(undefined),
    cruiseSpeed: number().optional().default(undefined),
    range: number().optional().default(undefined),
    endurance: number().optional().default(undefined),
    runwayLength: number().optional().default(undefined),
    lowPriceAdjustment: number().optional().default(undefined),
    highPriceAdjustment: number().optional().default(undefined),
    pictureIds: array().of(string().required()).optional().default(undefined),
    features: array().of(FeatureOutDtoSchema.required()).optional().default(undefined),
});

export type AircraftOutDtoSchema = InferType<typeof AircraftOutDtoSchema>;

export const LegsOutDtoSchema = object({
    timeInTheAir: string().required(),
    duration: string().required(),
    pax: number().required(),
    baseBudget: number().required(),
    fuelStopsNeeded: number().required(),
    distance: number().required(),
    quoteAdjusted: number().required(),
    isFerry: boolean().required(),
    startAirport: AirportOutDtoSchema.required(),
    endAirport: AirportOutDtoSchema.required(),
    legAssociatedCosts: array().of(LegAssociatedCostsOutDtoSchema.required()).required(),
    date: string().required(),
    pets: array().of(PetsOutDtoSchema).optional().default(undefined),
});

export type LegsOutDtoSchema = InferType<typeof LegsOutDtoSchema>;

export const SearchHitsOutDtoSchema = object({
    totalPaxTime: string().required(),
    totalTime: string().required(),

    minimumFeePerDayCharges: number().required(),
    preTotalBudget: number().required(),
    totalBudget: number().required(),
    totalLowBudgetRange: number().transform(convertCentToUnit).required(),
    totalHighBudgetRange: number().transform(convertCentToUnit).required(),

    aircraft: AircraftOutDtoSchema.required(),

    legs: array().of(LegsOutDtoSchema.required()).required(),
    flightAssociatedCosts: array().of(FlightAssociatedCostsOutDtoSchema.required()).required(),
    totalAssociatedCosts: array().of(TotalAssociatedCostsOutDtoSchema.required()).required(),
    dayCharges: array().of(DayChargesOutDtoSchema.required()).required(),
    triggeredPricingRule: array()
        .of(TriggeredPricingRuleOutDtoSchema.required())
        .optional()
        .default(undefined),
});

export type SearchHitsOutDtoSchema = InferType<typeof SearchHitsOutDtoSchema>;
