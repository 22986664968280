import React, { useCallback } from 'react';
import { DatePickerInput } from '../components/date-picker-input/DatePickerInput';
import { PassengersInput } from '../components/passengers-input/PassengersInput';
import { useTranslation } from 'react-i18next';
import { MultiCityDto, PassengersInDto } from './useQuoteForm';
import { ButtonUnstyled } from '@mui/base';
import { IconButton, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PlusIcon from '@mui/icons-material/Add';
import { clsxm } from '../utils/clsxm';
import { AirportAutocomplete } from './AirportAutocomplete';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { LegFormSchema, MultiCityFormSchema } from '../api/main.form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { SearchFormInDto } from '../swagger-types';
import { RequiredNotNull } from '../api/main.types';
import { BookingFormWrapper } from './BookingFormWrapper';
import { InfoIcon } from '../assets/icons';
import { isEmpty } from 'lodash';
import { gtag } from 'gtag/gtag';

export const MultiCityForm: React.FC<{
    configLabel: string;
    data: MultiCityDto;
    onDataChange: (data: MultiCityDto) => void;
    passengers: PassengersInDto;
    onPassengersChange: (passengers: PassengersInDto) => void;
    onSubmit: (Dto: SearchFormInDto) => void;
    buttonText: string;
    disabled?: boolean;
    loading?: boolean;
}> = ({
    configLabel,
    data,
    onDataChange,
    passengers,
    onPassengersChange,
    onSubmit,
    buttonText,
    disabled,
    loading,
}) => {
    const { t } = useTranslation();
    const {
        handleSubmit,
        control,
        watch,
        trigger,
        formState: { errors },
    } = useForm<MultiCityFormSchema>({
        resolver: yupResolver(MultiCityFormSchema),
        defaultValues: {
            legs: data,
            passengers,
        },
    });

    const legs = watch().legs;

    const { fields, append, remove, update } = useFieldArray({
        control,
        name: 'legs',
    });

    const handleAddRow = useCallback(() => {
        const startAirport = legs[legs.length - 1]?.endAirport || null;
        const newLeg = {
            date: null,
            startAirport,
            endAirport: null,
        };
        append(newLeg);
        onDataChange([...data, newLeg]);
    }, [onDataChange, fields, append, watch, data]);

    return (
        <BookingFormWrapper
            buttonText={buttonText}
            disabled={disabled}
            loading={loading}
            onSubmit={handleSubmit(formData => {
                const {
                    legs,
                    passengers: { passengers, pets },
                } = formData;

                isEmpty(errors) &&
                    onSubmit({
                        // TODO: remove 'as'
                        legs: (legs as RequiredNotNull<LegFormSchema>[]).map(
                            ({ date, startAirport, endAirport }) => ({
                                date: date.toISOString(),
                                startAirportId: startAirport.id,
                                endAirportId: endAirport.id,
                            }),
                        ),
                        passengers,
                        pets,
                    });
            })}>
            <div className="grid gap-4">
                {fields.map((field, index) => (
                    <div key={field.id} className="grid grid-cols-[1fr,36px] gap-2 md:items-center">
                        <div className="grid gap-2 md:grid-cols-3">
                            <Controller
                                control={control}
                                name={`legs.${index}.startAirport`}
                                render={({ field: { onChange, value }, fieldState }) => (
                                    <AirportAutocomplete
                                        value={value}
                                        fullWidth
                                        disabled={disabled}
                                        InputProps={{
                                            label: t('from'),
                                            placeholder: t('from'),
                                            errorMessage: t(fieldState.error?.message || ''),
                                        }}
                                        onChange={(_, value) => {
                                            onDataChange(
                                                data.map((item, i) => {
                                                    if (i === index) {
                                                        return {
                                                            ...item,
                                                            startAirport: value,
                                                        };
                                                    }
                                                    return item;
                                                }),
                                            );
                                            onChange(value);
                                            gtag.selectMultiCityStartAirport(
                                                {
                                                    icao: value?.icaoCode || '-',
                                                    legIndex: index,
                                                },
                                                { configLabel },
                                            );
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name={`legs.${index}.endAirport`}
                                render={({ field: { onChange, value }, fieldState }) => (
                                    <AirportAutocomplete
                                        value={value}
                                        fullWidth
                                        disabled={disabled}
                                        InputProps={{
                                            label: t('to'),
                                            placeholder: t('to'),
                                            errorMessage: t(fieldState.error?.message || ''),
                                        }}
                                        onChange={(_, value) => {
                                            onDataChange(
                                                data.map((item, i) => {
                                                    if (i === index) {
                                                        return {
                                                            ...item,
                                                            endAirport: value,
                                                        };
                                                    } else if (i === index + 1) {
                                                        return {
                                                            ...item,
                                                            startAirport: value,
                                                        };
                                                    }
                                                    return item;
                                                }),
                                            );
                                            onChange(value);
                                            gtag.selectMultiCityEndAirport(
                                                {
                                                    icao: value?.icaoCode || '-',
                                                    legIndex: index,
                                                },
                                                { configLabel },
                                            );

                                            const nextLeg = legs[index + 1];

                                            if (nextLeg) {
                                                update(index + 1, {
                                                    ...nextLeg,
                                                    startAirport: value,
                                                });
                                                gtag.selectMultiCityStartAirport(
                                                    {
                                                        icao: value?.icaoCode || '-',
                                                        legIndex: index + 1,
                                                    },
                                                    { configLabel },
                                                );
                                            }
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name={`legs.${index}.date`}
                                render={({
                                    field: { onChange, value, ref },
                                    formState: { isSubmitted },
                                    fieldState,
                                }) => {
                                    return (
                                        <DatePickerInput
                                            ref={ref}
                                            disabled={disabled}
                                            inputProps={{
                                                label: t('departure'),
                                                errorMessage: t(fieldState.error?.message || ''),
                                            }}
                                            selected={value}
                                            showTimeSelect
                                            placeholderText={t('departure')}
                                            timeFormat="HH:mm"
                                            onChange={date => {
                                                onDataChange(
                                                    data.map((item, i) => {
                                                        if (i === index) {
                                                            return {
                                                                ...item,
                                                                date: date || null,
                                                            };
                                                        }
                                                        return item;
                                                    }),
                                                );
                                                onChange(date || null);
                                                gtag.changeMultiCityDate(
                                                    {
                                                        date: date.toJSON(),
                                                        legIndex: index,
                                                    },
                                                    { configLabel },
                                                );
                                                if (isSubmitted) {
                                                    trigger(`legs.${index - 1}.date`);
                                                    trigger(`legs.${index + 1}.date`);
                                                }
                                            }}
                                            minDate={new Date()}
                                        />
                                    );
                                }}
                            />
                        </div>
                        <IconButton
                            className="!mt-5 h-9 w-9 md:!mt-0"
                            onClick={() => {
                                remove(index);
                                onDataChange(data.filter((_, i) => i !== index));
                            }}
                            disabled={fields.length <= 2 || disabled}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                ))}
                <div className="grid items-end gap-2 md:grid-cols-[2fr,1fr,36px]">
                    <Controller
                        control={control}
                        name="passengers"
                        render={({ field: { onChange, value, ref }, fieldState }) => (
                            <PassengersInput
                                ref={ref}
                                value={value}
                                disabled={disabled}
                                onChange={passengers => {
                                    onPassengersChange(passengers);
                                    onChange(passengers);
                                    gtag.changePaxPetsInfo(
                                        gtag.mapper.normalizePassengers(passengers),
                                        { configLabel },
                                    );
                                }}
                                inputProps={{
                                    label: (
                                        <span className="flex items-center gap-2">
                                            {t('maxPassengers')}
                                            <Tooltip title={t('maximumNumberOfPassengers')}>
                                                <InfoIcon className="w-[15px]" />
                                            </Tooltip>
                                        </span>
                                    ),
                                    placeholder: t('maxPassengers'),
                                    errorMessage: t(fieldState.error?.message || ''),
                                }}
                            />
                        )}
                    />
                    <ButtonUnstyled
                        className={clsxm(
                            'flex items-center justify-center gap-2.5 rounded border border-gray-300 p-3 font-medium text-gray-600 transition-colors',
                            'hover:bg-gray-200',
                            'disabled:pointer-events-none disabled:text-gray-400',
                            'active:bg-gray-200',
                        )}
                        disabled={disabled}
                        onClick={handleAddRow}>
                        <PlusIcon />
                        {t('addLeg')}
                    </ButtonUnstyled>
                </div>
            </div>
        </BookingFormWrapper>
    );
};
