import React, { useState, useCallback } from 'react';
import { PassengersFormItem } from './PassengersFormItem';
import { useTranslation } from 'react-i18next';
import { PassengersInDto } from '../../QuoteForm/useQuoteForm';
import { PetsDialog } from '../pets-dialog/PetsDialog';
import { PetInDto } from '../../swagger-types';

export const PassengersForm: React.FC<{
    value: PassengersInDto;
    onChange: (value: PassengersInDto) => void;
}> = ({ value, onChange }) => {
    const [openPetsDialog, setOpenPetsDialog] = useState(false);
    const { t } = useTranslation();

    const handleChangePassengers = (passengers: number) => {
        onChange({ ...value, passengers });
    };

    const handleChangePets = (pets: PetInDto[]) => {
        onChange({ ...value, pets });
    };

    const handleOpenPetsDialog = useCallback(() => {
        setOpenPetsDialog(true);
    }, []);

    const handleClosePetsDialog = useCallback(() => {
        setOpenPetsDialog(false);
    }, []);

    return (
        <div className="flex w-72  flex-col gap-3 px-2 py-3">
            <PassengersFormItem
                title={t('passengers', { count: 0 })}
                value={value.passengers}
                onChange={handleChangePassengers}
            />
            <PassengersFormItem
                title={t('pet', { count: 0 })}
                value={value.pets.length}
                onChange={handleOpenPetsDialog}
            />
            <PetsDialog
                pets={value.pets}
                open={openPetsDialog}
                onClose={handleClosePetsDialog}
                onChangePets={handleChangePets}
            />
        </div>
    );
};
