import React, { useState } from 'react';
import { Autocomplete, MyAutocompleteProps } from '../components/autocomplete/Autocomplete';
import { mainApi } from '../api/main.api';
import { MenuItem } from '@mui/material';
import { renderAirportName } from '../utils/airport.utils';
import { AirportOutDto } from '../swagger-types';

const MIN_CHARACTERS_FOR_SEARCH = 3;

export type AirportAutocompleteProps = Omit<
    MyAutocompleteProps<AirportOutDto, false, false, false>,
    'getOptions'
>;

export const AirportAutocomplete: React.FC<AirportAutocompleteProps> = ({
    InputProps,
    ...props
}) => {
    const [hasMore, setHasMore] = useState(false);

    return (
        <Autocomplete
            InputProps={{ bold: true, ...InputProps }}
            getOptions={async (term, page = 0, count = 0) => {
                if (term && term.length >= MIN_CHARACTERS_FOR_SEARCH) {
                    const { result, total } = await mainApi.getAirports({
                        term,
                        page,
                        size: 20,
                    });
                    setHasMore(count + result.length < total);
                    return result;
                }
                return [];
            }}
            isOptionEqualToValue={(option, value) => Boolean(value && option.id === value.id)}
            getOptionLabel={option => option.icaoCode}
            renderOption={(props, option, { selected }) => (
                <MenuItem selected={selected} {...props} style={{ whiteSpace: 'normal' }}>
                    {renderAirportName(option)}
                </MenuItem>
            )}
            filterOptions={identity}
            browserAutocompleteOff
            fetchOnOpen
            asyncSearch
            clearOptionsOnClose
            hasMore={hasMore}
            scrollOffset={200}
            minCharactersForSearch={MIN_CHARACTERS_FOR_SEARCH}
            {...props}
        />
    );
};

const identity = <T,>(a: T) => a;
