import React, { useCallback } from 'react';
import { Dialog } from '../dialog/Dialog';
import { IconButton, InputAdornment, Typography } from '@mui/material';
import { Input } from '../input/Input';
import { DeleteIcon, PetIcon } from '../../assets/icons';
import { ButtonUnstyled } from '@mui/base';
import { clsxm } from '../../utils/clsxm';
import PlusIcon from '@mui/icons-material/Add';
import { StyledButton } from '../StyledButton';
import { useFieldArray, useForm } from 'react-hook-form';
import { PetsFormSchema } from '../../api/main.form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useTranslation } from 'react-i18next';
import { PetInDto } from '../../swagger-types';

export const PetsDialog: React.FC<{
    pets: PetInDto[];
    open: boolean;
    onClose: () => void;
    onChangePets: (pets: PetInDto[]) => void;
}> = ({ open, onClose, onChangePets, pets }) => {
    const { t } = useTranslation();
    const { control, register, formState, handleSubmit, reset, clearErrors, trigger } =
        useForm<PetsFormSchema>({
            resolver: yupResolver(PetsFormSchema),
            defaultValues: { pets: pets.length ? pets : [{ details: '' }] },
        });

    const { fields, append, remove } = useFieldArray<PetsFormSchema>({
        control,
        name: 'pets',
    });

    const onPetsSubmit = (data: PetsFormSchema) => {
        onChangePets(data.pets.filter(pet => pet.details));
        reset(data);
        onClose();
    };

    const handleClose = useCallback(() => {
        reset();
        onClose();
    }, [onClose, reset]);

    return (
        <Dialog open={open} onClose={handleClose}>
            <Typography className="!mb-4 text-2xl font-semibold text-[#3C3C3C]">
                {t('addPets')}
            </Typography>
            <Typography className="!mb-4 text-base text-[#3C3C3C]">{t('weLovePets')}</Typography>
            <form
                onSubmit={e => {
                    // prevent submit of main form
                    e.stopPropagation();
                    handleSubmit(onPetsSubmit)(e);
                }}>
                {fields.map((field, index) => (
                    <Input
                        key={index}
                        {...register(`pets.${index}.details`, {
                            onChange: () => trigger(`pets.${index}`),
                        })}
                        errorMessage={t(formState.errors.pets?.[index]?.message || '')}
                        label={t('petsLabel')}
                        placeholder={t('enterPetsInfo')}
                        className="mb-4"
                        startAdornment={
                            <InputAdornment position="start">
                                <PetIcon />
                            </InputAdornment>
                        }
                        endAdornment={
                            fields.length > 1 ? (
                                <InputAdornment position="end">
                                    <IconButton
                                        disableRipple
                                        onClick={() => {
                                            fields.length === 2 && clearErrors();
                                            remove(index);
                                        }}>
                                        <DeleteIcon className="transition-colors hover:fill-neutral-600" />
                                    </IconButton>
                                </InputAdornment>
                            ) : undefined
                        }
                    />
                ))}
                <ButtonUnstyled
                    className={clsxm(
                        'flex w-fit items-center justify-center gap-2.5 rounded border border-gray-300 px-10 py-3 font-medium text-gray-600 transition-colors',
                        'hover:bg-gray-200',
                        'active:bg-gray-200',
                    )}
                    onClick={() => append({ details: '' })}>
                    <PlusIcon />
                    {t('addPet')}
                </ButtonUnstyled>
                <div className="mt-10 flex gap-3.5">
                    <StyledButton className="px-10" type="submit">
                        {t('save')}
                    </StyledButton>
                    <StyledButton
                        className="border border-stone-300 bg-stone-200 px-10 text-neutral-600 hover:bg-stone-300"
                        onClick={handleClose}>
                        {t('cancel')}
                    </StyledButton>
                </div>
            </form>
        </Dialog>
    );
};
