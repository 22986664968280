import React, { useEffect } from 'react';
import { useGoogleMap } from '@react-google-maps/api';
import { getGoogle } from 'google/google-maps.utils';

export const MapSideEffects: React.FC<{ markers: any[] }> = ({ markers }) => {
    const map = useGoogleMap();
    const maybeGoogle = getGoogle();
    const latlngbounds = maybeGoogle && new maybeGoogle.maps.LatLngBounds();

    useEffect(() => {
        if (!latlngbounds) {
            return;
        }
        if (map && markers.length) {
            for (let i = 0; i < markers.length; i++) {
                latlngbounds.extend(markers[i]);
            }
            map.fitBounds(latlngbounds);
        }
    }, [map, markers, latlngbounds]);

    return null;
};
