import { clsxm } from 'utils/clsxm';
import { ButtonUnstyled, ButtonUnstyledProps } from '@mui/base';
import { CircularProgress } from '@mui/material';
import React from 'react';

export const StyledButton: React.FC<ButtonUnstyledProps & { loading?: boolean }> = ({
    className,
    children,
    loading,
    ...props
}) => {
    return (
        <ButtonUnstyled
            className={clsxm(
                'flex items-center justify-center gap-4 rounded-[4px] bg-gold-main p-2 font-semibold text-white transition-colors',
                'hover:bg-gold-light',
                'active:bg-gold-dark',
                'disabled:bg-gray-400 disabled:ring-gray-400',
                className,
            )}
            {...props}>
            {children}
            {loading && <CircularProgress size={18} color="inherit" />}
        </ButtonUnstyled>
    );
};
