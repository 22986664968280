import React from 'react';
import { IconButton, Typography } from '@mui/material';
import { MinusIcon, PlusIcon } from '../../assets/icons';

export const PassengersFormItem: React.FC<{
    title: string;
    onChange: (value: number) => void;
    value: number;
}> = ({ title, value, onChange }) => {
    return (
        <div className="flex items-center justify-between px-2">
            <Typography className="text-sm font-medium text-gray-600">{title}</Typography>
            <div className="flex items-center gap-1">
                <IconButton
                    className="group h-[28px] w-[28px]"
                    onClick={() => onChange(value - 1)}
                    disabled={value === 0}>
                    <MinusIcon className="group-disabled:fill-gray-300" />
                </IconButton>
                <div className="w-16 rounded border border-gray-300 p-1 text-center font-semibold">
                    {value}
                </div>
                <IconButton className="group h-[28px] w-[28px]" onClick={() => onChange(value + 1)}>
                    <PlusIcon className="group-disabled:fill-gray-300" />
                </IconButton>
            </div>
        </div>
    );
};
