import { createTheme } from '@mui/material';

const theme = createTheme({
    palette: {
        primary: {
            main: '#E7AB5F',
            contrastText: '#FFFFFF',
        },
    },
    components: {
        MuiTypography: {
            defaultProps: {
                variant: 'inherit',
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    background: '#FFFFFF',
                    borderRadius: 4,
                    color: '#A3A3A3',
                    border: '1px solid #D1D5DB',
                    fontSize: 14,
                    fontWeight: 500,
                    textTransform: 'none',
                    padding: '9px 22px',
                    minHeight: 'auto',
                    '&.Mui-selected': {
                        color: '#FFFFFF',
                        background: '#525252',
                        border: '1px solid #525252',
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    minHeight: 'auto',
                },
                flexContainer: {
                    gap: 8,
                },
                indicator: {
                    display: 'none',
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                input: {
                    paddingTop: 12,
                    paddingBottom: 12,
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    paddingTop: 10,
                    paddingBottom: 10,
                    fontSize: 14,
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    paddingLeft: 20,
                    paddingRight: 20,
                    fontSize: 14,
                    whiteSpace: 'normal',
                    '&.Mui-selected': {
                        background: '#E5E7EB',
                    },
                    '&.Mui-selected:hover': {
                        background: '#d1d5db',
                    },
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '.MuiInput-root': {
                        '.MuiInput-input': {
                            padding: '12px 0',
                        },
                    },
                },
            },
        },
    },
});

export const muiTheme = createTheme(theme, {
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    [theme.breakpoints.down('lg')]: {
                        padding: '9px 16px',
                    },
                },
            },
        },
    },
});
