import { useCallback, useMemo, useState } from 'react';
import { AirportOutDto, EDefaultTab, PetInDto } from '../swagger-types';

export interface PassengersInDto {
    passengers: number;
    pets: PetInDto[];
}

export interface OneWayInDto {
    date: Date | null;
    startAirport: AirportOutDto | null;
    endAirport: AirportOutDto | null;
}

export interface RoundTripInDto {
    date: Date | null;
    returnDate: Date | null;
    startAirport: AirportOutDto | null;
    endAirport: AirportOutDto | null;
}

export type MultiCityDto = OneWayInDto[];

export const useQuoteForm = (tab: EDefaultTab) => {
    const [passengers, setPassengers] = useState<PassengersInDto>({
        passengers: 1,
        pets: [],
    });

    const [oneWayData, setOneWayData] = useState<OneWayInDto>({
        date: null,
        startAirport: null,
        endAirport: null,
    });
    const [roundTripData, setRoundTripData] = useState<RoundTripInDto>({
        date: null,
        returnDate: null,
        startAirport: null,
        endAirport: null,
    });
    const [multiCityData, setMultiCityData] = useState<MultiCityDto>([
        {
            date: null,
            startAirport: null,
            endAirport: null,
        },
        {
            date: null,
            startAirport: null,
            endAirport: null,
        },
    ]);

    const onOneWayDataChange = useCallback((Dto: OneWayInDto) => {
        setOneWayData(Dto);
        setRoundTripData(prevData => ({
            ...prevData,
            ...Dto,
        }));
        setMultiCityData(prevData =>
            prevData.map((leg, index) => {
                if (index === 0) {
                    return Dto;
                }
                return leg;
            }),
        );
    }, []);

    const onRoundTripDataChange = useCallback((Dto: RoundTripInDto) => {
        setRoundTripData(Dto);
        setOneWayData(Dto);
        setMultiCityData(prevData =>
            prevData.map((leg, index) => {
                if (index === 0) {
                    return Dto;
                }
                return leg;
            }),
        );
    }, []);

    const onMultiCityDataChange = useCallback((Dto: MultiCityDto) => {
        const firstLeg = Dto[0];
        setMultiCityData(Dto);

        if (firstLeg) {
            setOneWayData(firstLeg);
            setRoundTripData(prevData => ({
                ...prevData,
                ...firstLeg,
            }));
        }
    }, []);

    const onPassengersChange = useCallback((Dto: PassengersInDto) => {
        setPassengers(Dto);
    }, []);

    const airports = useMemo(() => {
        let airports: Array<AirportOutDto | null> = [];
        if (tab === EDefaultTab.ONE_WAY) {
            airports = [oneWayData.startAirport, oneWayData.endAirport];
        } else if (tab === EDefaultTab.ROUND_TRIP) {
            airports = [roundTripData.startAirport, roundTripData.endAirport];
        } else if (tab === EDefaultTab.MULTI_CITY) {
            multiCityData.forEach(({ startAirport, endAirport }) => {
                airports.push(startAirport);
                airports.push(endAirport);
            });
        }
        return airports.filter(airport => airport !== null) as AirportOutDto[];
    }, [
        tab,
        oneWayData.startAirport,
        oneWayData.endAirport,
        roundTripData.startAirport,
        roundTripData.endAirport,
        multiCityData,
    ]);

    return {
        airports,
        passengers,
        oneWayData,
        roundTripData,
        multiCityData,
        onPassengersChange,
        onOneWayDataChange,
        onRoundTripDataChange,
        onMultiCityDataChange,
    };
};
