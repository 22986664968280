import { FormControl } from '@mui/material';
import { styled } from '@mui/system';

// TODO add to ui-kit, handle non-dark theme. Currently good only for dark
export const StyledFormControl = styled(FormControl)`
    .MuiInput-underline:before {
        border-bottom: 1px solid #d1d5db; /* Default underline color */
    }
    .MuiInput-underline:hover:before {
        border-bottom: 2px solid #d1d5db; /* Hover underline color */
    }
    .MuiInput-underline:after {
        border-bottom: 2px solid #d1d5db; /* Focused underline color */
    }
    .MuiSvgIcon-root {
        color: #d1d5db; /* Dropdown indicator color */
    }
`;
