import React, { useState } from 'react';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, Typography } from '@mui/material';
import { StyledButton } from '../components/StyledButton';
import { EmailIcon, MessageIcon, UserIcon } from '../assets/icons';
import { Close as CloseIcon } from '@mui/icons-material';
import { Input } from '../components/input/Input';
import { useTranslation } from 'react-i18next';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { InquireFormSchema } from '../api/main.form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PhoneInput } from '../components/phone-input/PhoneInput';
import {
    EDefaultTab,
    SearchFormInDto,
    SearchHitsOutDto,
    WidgetConfigInDto,
} from '../swagger-types';
import { InquirySentBlock } from './InquirySentBlock';
import { useBookingInquiry } from '../api/main.api.hook';
import { CURRENCY_KEY } from '../exchange-manager/exchange-manager';
import { useCurrencyRender } from '../exchange-manager/CurrencyRenderer';
import { gtag } from 'gtag/gtag';
import { useGetCurrentAdSourceParams } from 'hooks/localStorage.hooks';
import { clsxm } from 'utils/clsxm';
import { TRACKING_CLASSNAME_FOR_SEND_INQUIRY_BUTTON } from 'gtag/analytics.seo';
import { useSharedLeadState } from 'QuoteForm/lead-capture/useSharedLeadState';
import { useGoogleId } from 'gtag/useGoogleId';
import { useDeviceDetails } from 'gtag/useDeviceDetails';

export const InquireForm: React.FC<{
    tab: EDefaultTab;
    open: boolean;
    onConfirm: () => void;
    onClose: () => void;
    inquired?: boolean;
    configLabel: string;
    widgetConfig: WidgetConfigInDto;
    searchForm: SearchFormInDto;
    currency: CURRENCY_KEY;
    flight?: SearchHitsOutDto;
}> = ({
    flight,
    tab,
    open,
    onClose,
    onConfirm,
    inquired,
    configLabel,
    widgetConfig,
    searchForm,
    currency,
}) => {
    const {
        t,
        i18n: { resolvedLanguage },
    } = useTranslation();
    const adSourceParams = useGetCurrentAdSourceParams();

    const [agreeWithPrivacyPolicy, setAgreeWithPrivacyPolicy] = useState(false);
    const [sharedLead, setSharedLead] = useSharedLeadState();

    const {
        register,
        formState: { errors },
        handleSubmit,
        control,
        getValues,
    } = useForm<InquireFormSchema>({
        resolver: yupResolver(InquireFormSchema),
        defaultValues: sharedLead,
    });

    const { roundedAmount: totalLowBudgetRange, formattedString: formattedTotalLowBudgetRange } =
        useCurrencyRender(flight?.totalLowBudgetRange || 0, currency);
    const { roundedAmount: totalHighBudgetRange, formattedString: formattedTotalHighBudgetRange } =
        useCurrencyRender(flight?.totalHighBudgetRange || 0, currency);

    const { mutate: handleInquiry, isLoading } = useBookingInquiry();
    const { googleClickId, googleClientId } = useGoogleId();
    const deviceDetails = useDeviceDetails();

    const onSubmit: SubmitHandler<InquireFormSchema> = contactForm => {
        handleInquiry(
            {
                // --- start google fields
                googleClickId,
                googleClientId,
                // --- end google fields
                ...deviceDetails,
                configLabel,
                searchForm,
                contactForm: {
                    ...contactForm,
                    // TODO remove hardcode when form updated
                    hasFlownBefore: false,
                },
                widgetConfig: { ...widgetConfig, label: widgetConfig.label || configLabel },
                selectedAircraft: flight
                    ? {
                          priceRange: {
                              lowPrice: formattedTotalLowBudgetRange,
                              highPrice: formattedTotalHighBudgetRange,
                              currency,
                          },
                          virtualFleetId: flight.aircraft.id,
                          paxLegs: flight.legs
                              .filter(({ isFerry }) => !isFerry)
                              .map(({ duration }, indexLeg) => ({
                                  flightTime: duration,
                                  indexLeg,
                              })),
                      }
                    : undefined,
                meta: {
                    resolvedLanguage,
                    searchType: tab,
                    flight,
                    totalLowBudgetRange,
                    totalHighBudgetRange,
                },
                adSource: {
                    utmCampaign: adSourceParams?.utm_campaign || undefined,
                    utmContent: adSourceParams?.utm_content || undefined,
                    utmMedium: adSourceParams?.utm_medium || undefined,
                    utmSource: adSourceParams?.utm_source || undefined,
                    utmTerm: adSourceParams?.utm_term || undefined,
                },
            },
            {
                onSuccess: () => {
                    onConfirm();
                },
            },
        );
    };

    const inputRegister: typeof register = (key, ...args) => {
        const reg = register(key, ...args);
        return {
            ...reg,
            onBlur: e => {
                if (key === 'name' && e?.target) {
                    gtag.enterName({ value: e.target.value }, { configLabel });
                }
                if (key === 'email' && e?.target) {
                    gtag.enterEmail({ value: e.target.value }, { configLabel });
                }
                if (!e?.target) {
                    return Promise.resolve(false);
                }
                setSharedLead(getValues());
                return reg.onBlur(e);
            },
            errorMessage: t(errors[key]?.message?.toString() || ''),
            disabled: isLoading,
        };
    };

    if (!open) {
        return null;
    }

    if (inquired) {
        return <InquirySentBlock />;
    }

    return (
        <div className="mt-3 rounded-[1.25rem] bg-white p-6 pb-10 shadow-custom">
            <div className="mb-6 flex justify-between">
                <Typography className="text-sm font-semibold uppercase text-gray-800">
                    {t('provideYourContactDetails')}
                </Typography>
                <IconButton onClick={() => onClose()}>
                    <CloseIcon />
                </IconButton>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-10 grid gap-6 md:grid-cols-3">
                    <Input
                        {...inputRegister('name')}
                        label={t('name')}
                        placeholder={t('enterName')}
                        startAdornment={
                            <InputAdornment position="start">
                                <UserIcon />
                            </InputAdornment>
                        }
                    />
                    <Input
                        {...inputRegister('email')}
                        label={t('emailAddress')}
                        placeholder={t('enterEmail')}
                        startAdornment={
                            <InputAdornment position="start">
                                <EmailIcon />
                            </InputAdornment>
                        }
                    />
                    <Controller
                        name="phone"
                        control={control}
                        render={({ field, fieldState }) => {
                            return (
                                <PhoneInput
                                    {...field}
                                    onBlur={e => {
                                        if (e?.target) {
                                            gtag.enterPhone(
                                                {
                                                    value: (e.target as HTMLInputElement).value,
                                                },
                                                { configLabel },
                                            );
                                            setSharedLead(getValues());
                                        }
                                        return field.onBlur();
                                    }}
                                    international
                                    defaultCountry="US"
                                    label={t('phoneNumber')}
                                    placeholder={'+1 000 000 0000'}
                                    disabled={isLoading}
                                    errorMessage={t(fieldState.error?.message || '')}
                                />
                            );
                        }}
                    />
                </div>
                <Controller
                    name="comment"
                    control={control}
                    render={({ field, fieldState }) => (
                        <Input
                            {...field}
                            label={t('requestsOrRequirements')}
                            placeholder={t('typeHere')}
                            helperText={
                                field.value
                                    ? t('charsLeft', { chars: 255 - field.value.length })
                                    : ''
                            }
                            className="mb-8"
                            multiline
                            maxRows={8}
                            onChange={e => {
                                const value = e.target.value;
                                // 255 is SalesForce limit for Text type
                                field.onChange(
                                    value
                                        .replaceAll(/\n+/g, ' ')
                                        .replaceAll(/\s+/g, ' ')
                                        .slice(0, 255),
                                );
                            }}
                            onBlur={e => {
                                gtag.enterComment({ value: e.target.value }, { configLabel });
                                setSharedLead(getValues());
                            }}
                            startAdornment={
                                <InputAdornment position="start">
                                    <MessageIcon />
                                </InputAdornment>
                            }
                            errorMessage={t(fieldState.error?.message || '')}
                        />
                    )}
                />
                <div className="grid md:grid-cols-[60%,40%]">
                    <FormControlLabel
                        control={<Checkbox checked={agreeWithPrivacyPolicy} disabled={isLoading} />}
                        onChange={(_, checked) => setAgreeWithPrivacyPolicy(checked)}
                        label={
                            <Typography className="text-sm text-gray-600">
                                {t('iReadThe')}{' '}
                                <a
                                    href="https://bitluxtravel.com/privacy-policy/"
                                    target="_blank"
                                    className="underline"
                                    rel="noreferrer">
                                    {t('privacyPolicy')}
                                </a>{' '}
                                {t('andToBeContacted')}
                            </Typography>
                        }
                    />
                    <StyledButton
                        className={clsxm(
                            'mt-6 flex w-full items-center justify-center gap-4 md:mt-0',
                            TRACKING_CLASSNAME_FOR_SEND_INQUIRY_BUTTON,
                        )}
                        disabled={!agreeWithPrivacyPolicy || isLoading}
                        loading={isLoading}
                        type="submit">
                        {t('sendInquiry')}
                    </StyledButton>
                </div>
            </form>
        </div>
    );
};
