import { GLOBAL_WIDGET_CONFIG_LABEL_KEY, WIDGET_CONFIG_LABEL_DEFAULT } from 'tmp/widget.constant';

export const getGlobalConfigLabel = () => {
    const maybeLabel = (window as any)[GLOBAL_WIDGET_CONFIG_LABEL_KEY];
    if (typeof maybeLabel === 'string' || typeof maybeLabel === 'number') {
        return String(maybeLabel);
    }
    return WIDGET_CONFIG_LABEL_DEFAULT;
};

export const normalizeWidgetConfigLabel = (rawLabel: string) =>
    rawLabel.replace(/[^a-zA-Z0-9_]/g, '_').toLowerCase();
