import { yupResolver } from '@hookform/resolvers/yup';
import {
    capitalize,
    InputAdornment,
    InputLabel,
    MenuItem,
    RadioGroup,
    Select,
} from '@mui/material';
import { useRequestMembershipCard } from 'api/main.api.hook';
import { EmailIcon, UserIcon } from 'assets/icons';
import { Input } from 'components/input/Input';
import { PhoneInput } from 'components/phone-input/PhoneInput';
import { Radio } from 'components/radio/Radio';
import { StyledButton } from 'components/StyledButton';
import { gtag } from 'gtag/gtag';
import { useDeviceDetails } from 'gtag/useDeviceDetails';
import { useGoogleId } from 'gtag/useGoogleId';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MembershipFormSchema } from './membership.schema';
import { useState } from 'react';
import { StyledFormControl } from 'components/StyledFormControl';
import { useGetCurrentAdSourceParams } from 'hooks/localStorage.hooks';
import { EActivateMembership, EMembershipCardType } from 'swagger-types';

export const MembershipCardForm: React.FC<{
    configLabel: string;
    onComplete: () => void;
}> = ({ configLabel, onComplete }) => {
    const { t } = useTranslation();
    const [stateCard, setStateCard] = useState<MembershipFormSchema | undefined>();

    const {
        register,
        formState: { errors },
        handleSubmit,
        control,
        getValues,
    } = useForm<MembershipFormSchema>({
        resolver: yupResolver(MembershipFormSchema),
        defaultValues: stateCard,
    });

    const inputRegister: typeof register = (key, ...args) => {
        const reg = register(key, ...args);
        return {
            ...reg,
            onBlur: e => {
                if (key === 'firstName' && e?.target) {
                    gtag.enterFirstName({ value: e.target.value }, { configLabel });
                }
                if (key === 'lastName' && e?.target) {
                    gtag.enterLastName({ value: e.target.value }, { configLabel });
                }
                if (key === 'email' && e?.target) {
                    gtag.enterEmail({ value: e.target.value }, { configLabel });
                }
                if (key === 'company' && e?.target) {
                    gtag.enterComment({ value: e.target.value }, { configLabel });
                }
                if (!e?.target) {
                    return Promise.resolve(false);
                }
                setStateCard(getValues());
                return reg.onBlur(e);
            },
            errorMessage: t(errors[key]?.message?.toString() || ''),
            disabled: isLoading,
        };
    };

    const { mutate: requestMembershipCardMutation, isLoading } = useRequestMembershipCard();
    const { googleClickId, googleClientId } = useGoogleId();
    const deviceDetails = useDeviceDetails();
    const adSourceParams = useGetCurrentAdSourceParams();

    const onSubmit: SubmitHandler<MembershipFormSchema> = data => {
        requestMembershipCardMutation(
            {
                dto: {
                    contactForm: { ...deviceDetails, ...data },
                    googleClickId,
                    googleClientId,
                    adSource: {
                        utmCampaign: adSourceParams?.utm_campaign || undefined,
                        utmContent: adSourceParams?.utm_content || undefined,
                        utmMedium: adSourceParams?.utm_medium || undefined,
                        utmSource: adSourceParams?.utm_source || undefined,
                        utmTerm: adSourceParams?.utm_term || undefined,
                    },
                },
                configLabel,
            },
            {
                onSuccess: () => {
                    onComplete();
                },
            },
        );
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <div className="flex flex-col gap-6">
                <div className="grid gap-6 md:grid-cols-2">
                    <Input
                        {...inputRegister('firstName')}
                        label={t('membershipCard.firstName')}
                        placeholder={t('membershipCard.firstName')}
                        darkMode
                        startAdornment={
                            <InputAdornment position="start">
                                <UserIcon />
                            </InputAdornment>
                        }
                    />
                    <Input
                        {...inputRegister('lastName')}
                        label={t('membershipCard.lastName')}
                        placeholder={t('membershipCard.lastName')}
                        darkMode
                        startAdornment={
                            <InputAdornment position="start">
                                <UserIcon />
                            </InputAdornment>
                        }
                    />
                    <Input
                        {...inputRegister('company')}
                        label={t('membershipCard.companyName')}
                        placeholder={t('membershipCard.companyName')}
                        darkMode
                        startAdornment={
                            <InputAdornment position="start">
                                <UserIcon />
                            </InputAdornment>
                        }
                    />
                    <Controller
                        name="phone"
                        control={control}
                        render={({ field, fieldState }) => {
                            return (
                                <PhoneInput
                                    {...field}
                                    onBlur={e => {
                                        if (e?.target) {
                                            gtag.enterPhone(
                                                {
                                                    value: (e.target as HTMLInputElement).value,
                                                },
                                                { configLabel },
                                            );
                                            setStateCard(getValues());
                                        }
                                        return field.onBlur();
                                    }}
                                    international
                                    defaultCountry="US"
                                    label={t('phoneNumber')}
                                    placeholder={'+1 000 000 0000'}
                                    disabled={isLoading}
                                    darkMode
                                    errorMessage={t(fieldState.error?.message || '')}
                                />
                            );
                        }}
                    />
                </div>
                <div className="grid items-end gap-6 md:grid-cols-2">
                    <div>
                        <div className="text-sm text-gray-500">
                            {t('membershipCard.emailLabel')}
                        </div>
                        <Input
                            {...inputRegister('email')}
                            placeholder={t('enterEmail')}
                            darkMode
                            startAdornment={
                                <InputAdornment position="start">
                                    <EmailIcon />
                                </InputAdornment>
                            }
                        />
                    </div>
                    <div className="flex flex-wrap items-center justify-center gap-4">
                        <div>{t('membershipCard.doYouHaveCard')}</div>
                        <Controller
                            name="hasAJetCard"
                            control={control}
                            render={({ field: { value, onChange, ...field }, fieldState }) => {
                                const hasError = Boolean(fieldState.error?.message || '');
                                return (
                                    <RadioGroup
                                        {...field}
                                        onChange={e => {
                                            onChange(e.target.value === 'true');
                                            setStateCard(getValues());
                                        }}
                                        value={value || ''}
                                        className="flex-col text-white">
                                        <div className="flex flex-row justify-around">
                                            <Radio
                                                darkMode
                                                value={true}
                                                label={
                                                    <span className="text-normal">{t('yes')}</span>
                                                }
                                                checked={value === true}
                                            />
                                            <Radio
                                                darkMode
                                                value={false}
                                                label={
                                                    <span className="text-normal">{t('no')}</span>
                                                }
                                                checked={value === false}
                                            />
                                        </div>
                                        {hasError && (
                                            <div className="border-t border-red-500 pt-0.5 text-xs text-red-500">
                                                {t(fieldState.error?.message || '')}
                                            </div>
                                        )}
                                    </RadioGroup>
                                );
                            }}
                        />
                    </div>
                </div>
                <div className="grid items-end gap-6 md:grid-cols-2">
                    <Controller
                        name="cardType"
                        control={control}
                        render={({ field: { value, onChange, ...field }, fieldState }) => {
                            const hasError = Boolean(fieldState.error?.message || '');
                            return (
                                <StyledFormControl fullWidth variant="standard">
                                    <InputLabel className="text-gray-500">
                                        {t('membershipCard.jetCardProgram')}
                                    </InputLabel>
                                    {/* TODO create ui-kit Select */}
                                    <Select
                                        {...field}
                                        className="text-white"
                                        value={value || ''}
                                        onChange={e => {
                                            onChange(e);
                                            gtag.selectCardProgram(
                                                { value: e.target.value },
                                                { configLabel },
                                            );
                                        }}
                                        inputProps={{
                                            className: /*tw*/ 'border-white',
                                        }}
                                        renderValue={option =>
                                            JET_CARD_PROGRAMS.find(e => e.value === option)?.title
                                        }>
                                        {JET_CARD_PROGRAMS.map(card => (
                                            <MenuItem
                                                key={card.value}
                                                value={card.value}
                                                disableGutters>
                                                {card.title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {hasError && (
                                        <div className="border-t border-red-500 pt-0.5 text-xs text-red-500">
                                            {t(fieldState.error?.message || '')}
                                        </div>
                                    )}
                                </StyledFormControl>
                            );
                        }}
                    />
                    <Controller
                        name={'activateMembership'}
                        control={control}
                        render={({ field: { value, onChange, ...field }, fieldState }) => {
                            const hasError = Boolean(fieldState.error?.message || '');
                            return (
                                <StyledFormControl fullWidth variant="standard">
                                    <div className="overflow-visible whitespace-normal text-gray-500">
                                        {t('membershipCard.whenToActivateMembership')}
                                    </div>
                                    {/* TODO create ui-kit Select */}
                                    <Select
                                        {...field}
                                        className="text-white"
                                        value={value || ''}
                                        onChange={e => {
                                            onChange(e);
                                            gtag.selectCardProgram(
                                                { value: e.target.value },
                                                { configLabel },
                                            );
                                        }}
                                        inputProps={{
                                            className: /*tw*/ 'border-white',
                                        }}
                                        displayEmpty
                                        renderValue={value =>
                                            WHEN_TO_ACTIVATE_CARD.find(e => e.value === value)
                                                ?.title || (
                                                <div className="text-slate-600">
                                                    {t('common.selectOption')}
                                                </div>
                                            )
                                        }>
                                        {WHEN_TO_ACTIVATE_CARD.map(when => (
                                            <MenuItem
                                                key={when.value}
                                                value={when.value}
                                                disableGutters>
                                                {when.title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {hasError && (
                                        <div className="border-t border-red-500 pt-0.5 text-xs text-red-500">
                                            {t(fieldState.error?.message || '')}
                                        </div>
                                    )}
                                </StyledFormControl>
                            );
                        }}
                    />
                </div>
                <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
                    <div className="text-xs text-gray-400">
                        {t('leadCapture.byClickingAgreeToBeContacted')}
                    </div>
                    <StyledButton loading={isLoading} type="submit" className="px-12">
                        {t('membershipCard.buttonRequestDetails')}
                    </StyledButton>
                </div>
            </div>
        </form>
    );
};

const JET_CARD_PROGRAMS: {
    value: EMembershipCardType;
    title: string;
}[] = [
    EMembershipCardType.ASCENT,
    EMembershipCardType.CARDINAL,
    EMembershipCardType.SOVEREIGNTY,
].map(e => ({
    value: e,
    title: `${capitalize(e.toLowerCase())} Card`,
}));

const WHEN_TO_ACTIVATE_CARD: {
    value: EActivateMembership;
    title: string;
}[] = [
    { value: EActivateMembership.ASAP, title: 'ASAP' },
    { value: EActivateMembership.WITHIN_ONE_MONTH, title: 'Within 1 month' },
    { value: EActivateMembership.ONE_TWO_MONTHS, title: '1-2 Months' },
    { value: EActivateMembership.THREE_SIX_MONTHS, title: '3-6 Months' },
    { value: EActivateMembership.JUST_LOOKING, title: 'Just Looking' },
];
