import clsx from 'clsx';
import { isBoolean, isString } from 'lodash';
import { nanoid } from 'nanoid';
import { useEffect, useCallback, useState, useMemo, useRef } from 'react';
import { useThrottledCallback } from 'use-debounce';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import {
    Autocomplete as MuiAutocomplete,
    AutocompleteProps as MuiAutocompleteProps,
    IconButton,
} from '@mui/material';
import ChevronDownIcon from '@mui/icons-material/ExpandMore';

import { ListBox } from './ListBox';

import styles from './Autocomplete.module.scss';
import { clsxm } from '../../utils/clsxm';
import { Input, InputProps } from '../input/Input';
import { useTranslation } from 'react-i18next';

enum AutocompleteState {
    NO_OPTIONS_AVAILABLE,
    OPTIONS_FETCH_REQUIRED,
    OPTIONS_FETCHED,
    OPTIONS_LOADING,
    OPTIONS_FETCH_ERROR,
}

enum AutocompleteFetchFrom {
    SEARCH,
    SCROLL,
}

export interface MyAutocompleteProps<
    K,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
> extends Omit<
        MuiAutocompleteProps<K, Multiple, DisableClearable, FreeSolo>,
        'renderInput' | 'options' | 'onInputChange'
    > {
    getOptions: (search?: string, page?: number, loadedCount?: number) => Promise<K[]>;
    browserAutocompleteOff?: boolean;
    fetchOnOpen?: boolean;
    noEmptyFetch?: boolean;
    onInputChange?: (v: string) => void;
    onOptionsClose?: () => void;
    InputProps?: InputProps;
    asyncSearch?: boolean;
    asyncDelay?: number;
    paperClassName?: string;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    hideEndIcon?: boolean;
    hasMore?: boolean;
    highlightTextMatchAccessor?: boolean;
    scrollOffset?: number;
    clearOptionsOnClose?: boolean;
    withTextMatchAccessor?: string;
    minCharactersForSearch?: number;
}

export function Autocomplete<
    K = any,
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined,
>({
    getOptions,
    onOptionsClose,
    onInputChange,
    scrollOffset = 0,
    asyncDelay = 400,
    clearOptionsOnClose,
    noEmptyFetch,
    fetchOnOpen,
    browserAutocompleteOff,
    InputProps,
    asyncSearch,
    paperClassName,
    startIcon,
    endIcon,
    hideEndIcon = true,
    withTextMatchAccessor,
    highlightTextMatchAccessor,
    hasMore,
    minCharactersForSearch = 0,
    ...props
}: MyAutocompleteProps<K, Multiple, DisableClearable, FreeSolo>) {
    const { t } = useTranslation();
    const [autocompleteStateMachine, setAutocompleteState] = useState<AutocompleteState>(
        fetchOnOpen
            ? AutocompleteState.NO_OPTIONS_AVAILABLE
            : AutocompleteState.OPTIONS_FETCH_REQUIRED,
    );
    const [autocompleteFetchFromMachine, setAutocompleteFetchFrom] =
        useState<AutocompleteFetchFrom>(AutocompleteFetchFrom.SEARCH);

    const [page, setPage] = useState(0);
    const [options, setOptions] = useState<K[]>([]);
    const [optionsOpen, setOptionsOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const searchRequestId = useRef('');

    const loading = useMemo(
        () =>
            autocompleteStateMachine === AutocompleteState.OPTIONS_LOADING ||
            (autocompleteStateMachine === AutocompleteState.OPTIONS_FETCH_REQUIRED &&
                !options.length),
        [autocompleteStateMachine, options.length],
    );

    const throttledSetSearch = useThrottledCallback((value: string) => {
        setSearchValue(value);
        setPage(0);
        setAutocompleteFetchFrom(AutocompleteFetchFrom.SEARCH);
        setAutocompleteState(AutocompleteState.OPTIONS_FETCH_REQUIRED);
        searchRequestId.current = nanoid(10);
    }, asyncDelay);

    useEffect(() => {
        if (autocompleteStateMachine === AutocompleteState.OPTIONS_FETCH_REQUIRED) {
            (async () => {
                setAutocompleteState(AutocompleteState.OPTIONS_LOADING);
                try {
                    const savedRequestLocalId = searchRequestId.current;
                    //TODO: options.length incorrently works for requests
                    const optionsData = await getOptions(searchValue, page, options.length);

                    if (autocompleteFetchFromMachine === AutocompleteFetchFrom.SEARCH) {
                        if (searchRequestId.current === savedRequestLocalId) {
                            setOptions(optionsData);
                            setAutocompleteState(AutocompleteState.OPTIONS_FETCHED);
                        }
                    } else {
                        setOptions(prev => {
                            return [...prev, ...optionsData];
                        });
                        setAutocompleteState(AutocompleteState.OPTIONS_FETCHED);
                    }
                } catch (error) {
                    setAutocompleteState(AutocompleteState.OPTIONS_FETCH_ERROR);
                }
            })();
        }
    }, [
        autocompleteFetchFromMachine,
        autocompleteStateMachine,
        page,
        options.length,
        searchValue,
        getOptions,
    ]);

    const handleOptionsToggle = useCallback(
        (open?: boolean) => () => {
            if (!open && clearOptionsOnClose) {
                setPage(0);
                setAutocompleteState(
                    fetchOnOpen
                        ? AutocompleteState.NO_OPTIONS_AVAILABLE
                        : AutocompleteState.OPTIONS_FETCH_REQUIRED,
                );
                setAutocompleteFetchFrom(AutocompleteFetchFrom.SEARCH);
                setOptions([]);
            }
            const newOpenValue = isBoolean(open) ? open : !optionsOpen;
            setOptionsOpen(newOpenValue);
            const emptySearch = noEmptyFetch ? !!searchValue : true;
            if (
                newOpenValue &&
                autocompleteStateMachine === AutocompleteState.NO_OPTIONS_AVAILABLE &&
                emptySearch
            ) {
                setAutocompleteState(AutocompleteState.OPTIONS_FETCH_REQUIRED);
            }
            if (onOptionsClose && !newOpenValue) {
                onOptionsClose();
            }
        },
        [
            autocompleteStateMachine,
            clearOptionsOnClose,
            fetchOnOpen,
            noEmptyFetch,
            onOptionsClose,
            optionsOpen,
            searchValue,
        ],
    );

    const loadMore = useCallback(() => {
        if (loading) {
            return null;
        }

        setAutocompleteFetchFrom(AutocompleteFetchFrom.SCROLL);
        setPage(prevPageValue => prevPageValue + 1);
        setAutocompleteState(AutocompleteState.OPTIONS_FETCH_REQUIRED);
    }, [loading]);

    return (
        <MuiAutocomplete
            classes={{
                paper: clsx(styles.Paper, paperClassName),
                ...props.classes,
            }}
            loadingText={t('loading')}
            noOptionsText={t('noOptions')}
            ListboxProps={
                hasMore
                    ? {
                          onScroll: (event: React.SyntheticEvent) => {
                              const listboxNode = event.currentTarget;
                              if (
                                  listboxNode.scrollTop + listboxNode.clientHeight + scrollOffset >=
                                  listboxNode.scrollHeight
                              ) {
                                  loadMore();
                              }
                          },
                      }
                    : undefined
            }
            ListboxComponent={ListBox}
            open={optionsOpen && searchValue.length >= minCharactersForSearch}
            onOpen={handleOptionsToggle(true)}
            onClose={handleOptionsToggle(false)}
            loading={loading}
            options={options}
            onInputChange={(_, value, reason) => {
                onInputChange && onInputChange(value);
                if (reason === 'reset' && !value) {
                    setSearchValue('');
                    return;
                }
                if (asyncSearch) {
                    throttledSetSearch(value);
                }
            }}
            renderInput={params => {
                return (
                    <Input
                        ref={params.InputProps.ref}
                        {...InputProps}
                        className={clsx(styles.InputWrapper, InputProps?.className)}
                        startAdornment={startIcon || params.InputProps.startAdornment}
                        fullWidth
                        endAdornment={
                            !hideEndIcon &&
                            (endIcon ? (
                                endIcon
                            ) : (
                                <IconButton
                                    color="primary"
                                    onClick={handleOptionsToggle()}
                                    disableRipple
                                    className={styles.ArrowIconWrapper}>
                                    <ChevronDownIcon
                                        className={clsxm(
                                            styles.ArrowIcon,
                                            optionsOpen && styles.ArrowIconOpen,
                                        )}
                                    />
                                </IconButton>
                            ))
                        }
                        classes={{
                            root: styles.InputRoot,
                            ...InputProps?.classes,
                        }}
                        inputProps={{
                            ...params.inputProps,
                            ...InputProps?.inputProps,
                            autoComplete: browserAutocompleteOff ? 'off' : 'new-password',
                        }}
                        disabled={props.disabled}
                    />
                );
            }}
            renderOption={
                withTextMatchAccessor
                    ? (props, option, { inputValue }) => {
                          const optionGet = option as unknown as Record<string, string>;
                          const optionLabel = isString(optionGet)
                              ? optionGet
                              : optionGet[withTextMatchAccessor];
                          const matches = match(optionLabel as string, inputValue);
                          const parts = parse(optionLabel as string, matches);

                          return (
                              <li {...props}>
                                  <div>
                                      {parts.map((part, index) => (
                                          <span
                                              key={index}
                                              className={
                                                  highlightTextMatchAccessor && part.highlight
                                                      ? 'highlight-primary'
                                                      : undefined
                                              }
                                              style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                              {part.text}
                                          </span>
                                      ))}
                                  </div>
                              </li>
                          );
                      }
                    : props.renderOption
            }
            {...props}
        />
    );
}
