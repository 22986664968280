export const SolanaIconSvg: React.FC<{
    size?: number;
    className?: string;
}> = ({ size = 24, className }) => {
    return (
        <svg width={size} height={size} viewBox="-5 0 35 22" fill="none" className={className}>
            <path
                d="M 23.8763 16.9512 L 19.9145 21.2072 C 19.8284 21.2996 19.7242 21.3733 19.6084 21.4237 C 19.4926 21.4741 19.3677 21.5001 19.2414 21.5 H 0.46 C 0.3703 21.5 0.2827 21.4737 0.2077 21.4245 C 0.1328 21.3753 0.0738 21.3053 0.0381 21.2229 C 0.0024 21.1406 -0.0085 21.0496 0.0067 20.9611 C 0.0219 20.8727 0.0625 20.7906 0.1237 20.725 L 4.0886 16.469 C 4.1745 16.3768 4.2783 16.3032 4.3938 16.2528 C 4.5092 16.2025 4.6337 16.1764 4.7596 16.1762 H 23.5401 C 23.6297 16.1762 23.7173 16.2024 23.7922 16.2517 C 23.8672 16.3009 23.9261 16.371 23.9619 16.4533 C 23.9976 16.5356 24.0085 16.6266 23.9933 16.7151 C 23.9781 16.8035 23.9374 16.8856 23.8763 16.9512 Z M 19.9145 8.3809 C 19.8284 8.2884 19.7242 8.2148 19.6084 8.1644 C 19.4926 8.114 19.3677 8.0881 19.2414 8.0881 H 0.46 C 0.3703 8.0881 0.2827 8.1143 0.2077 8.1636 C 0.1328 8.2127 0.0738 8.2828 0.0381 8.3651 C 0.0024 8.4475 -0.0085 8.5385 0.0067 8.627 C 0.0219 8.7154 0.0625 8.7975 0.1237 8.8631 L 4.0886 13.1191 C 4.1745 13.2113 4.2783 13.2849 4.3938 13.3353 C 4.5092 13.3856 4.6337 13.4117 4.7596 13.4119 H 23.5401 C 23.6297 13.4119 23.7173 13.3857 23.7922 13.3364 C 23.8672 13.2873 23.9261 13.2172 23.9619 13.1349 C 23.9976 13.0525 24.0085 12.9615 23.9933 12.873 C 23.9781 12.7846 23.9374 12.7025 23.8763 12.6369 L 19.9145 8.3809 Z M 0.46 5.3238 H 19.2414 C 19.3677 5.3239 19.4926 5.2979 19.6084 5.2476 C 19.7242 5.1971 19.8284 5.1235 19.9145 5.031 L 23.8763 0.775 C 23.9374 0.7094 23.9781 0.6273 23.9933 0.5388 C 24.0085 0.4504 23.9976 0.3594 23.9619 0.2771 C 23.9261 0.1948 23.8672 0.1247 23.7922 0.0755 C 23.7173 0.0262 23.6297 0 23.5401 0 L 4.7596 0 C 4.6337 0.0002 4.5092 0.0263 4.3938 0.0767 C 4.2783 0.127 4.1745 0.2006 4.0886 0.2928 L 0.1247 4.5488 C 0.0636 4.6143 0.023 4.6963 0.0077 4.7847 C -0.0075 4.8731 0.0033 4.964 0.0389 5.0462 C 0.0745 5.1285 0.1333 5.1986 0.2081 5.248 C 0.2829 5.2973 0.3704 5.3236 0.46 5.3238 Z"
                fill="black"></path>
        </svg>
    );
};
