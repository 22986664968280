import { LanguageSwitcher } from 'components/LanguageSwitcher';
import { StyledButton } from 'components/StyledButton';
import { gtag } from 'gtag/gtag';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { clsxm } from 'utils/clsxm';
import { LeadForm } from './LeadForm';

enum EStep {
    INITIAL = 'INITIAL',
    LEAD = 'LEAD',
}

export const LeadCaptureFlow: React.FC<{
    configLabel: string;
    onComplete: () => void;
}> = ({ configLabel, onComplete }) => {
    const { t } = useTranslation();
    const [step, setStep] = useState(EStep.INITIAL);

    const renderStep = () => {
        if (step === EStep.LEAD) {
            return <LeadForm configLabel={configLabel} onComplete={onComplete} />;
        }
        return (
            <div className="flex w-full flex-col items-center gap-8 py-16 text-white @container">
                <div className="text-center text-3xl tracking-wider @3xl:tracking-widest">
                    {t('leadCapture.lookingToGetInTouch')}
                </div>
                <div className="flex w-full flex-wrap justify-center gap-12">
                    <StyledButton
                        onClick={() => {
                            setStep(EStep.LEAD);
                            gtag.requestQuote({}, { configLabel, withTotalEvent: true });
                        }}>
                        {t('leadCapture.getInstantQuote')}
                    </StyledButton>
                    <a
                        href="tel:+1-561-421-8620"
                        onClick={() => {
                            gtag.initiateCall(
                                {
                                    value: '+1-561-421-8620',
                                },
                                { configLabel, withTotalEvent: true },
                            );
                        }}>
                        <StyledButton>
                            <svg width="15" height="15" viewBox="0 0 15 15">
                                <path
                                    className="fill-white"
                                    d="M12.3 15h-.2c-2.1-.2-4.1-1-5.9-2.1-1.6-1-3.1-2.5-4.1-4.1C1 7 .2 5 0 2.9-.1 1.8.7.8 1.8.7H4c1 0 1.9.7 2 1.7.1.6.2 1.1.4 1.7.3.7.1 1.6-.5 2.1l-.4.4c.7 1.1 1.7 2.1 2.9 2.9l.4-.5c.6-.6 1.4-.7 2.1-.5.6.3 1.1.4 1.7.5 1 .1 1.8 1 1.7 2v2c0 .5-.2 1-.6 1.4-.3.4-.8.6-1.4.6zM4 2.1H2c-.2 0-.3.1-.4.2-.1.1-.1.3-.1.4.2 1.9.8 3.7 1.8 5.3.9 1.5 2.2 2.7 3.7 3.7 1.6 1 3.4 1.7 5.3 1.9.2 0 .3-.1.4-.2.1-.1.2-.2.2-.4v-2c0-.3-.2-.5-.5-.6-.7-.1-1.3-.3-2-.5-.2-.1-.4 0-.6.1l-.8.9c-.2.2-.6.3-.9.1C6.4 10 5 8.6 4 6.9c-.2-.3-.1-.7.1-.9l.8-.8c.2-.2.2-.4.1-.6-.2-.6-.4-1.3-.5-2 0-.3-.2-.5-.5-.5zm7.7 4.5c-.4 0-.7-.2-.7-.6-.2-1-1-1.8-2-2-.4 0-.7-.4-.6-.8.1-.4.5-.7.9-.6 1.6.3 2.8 1.5 3.1 3.1.1.4-.2.8-.6.9h-.1zm2.6 0c-.4 0-.7-.3-.7-.6-.3-2.4-2.2-4.3-4.6-4.5-.4-.1-.7-.5-.6-.9 0-.4.4-.6.8-.6 3.1.3 5.4 2.7 5.8 5.8 0 .4-.3.7-.7.8z"></path>
                            </svg>
                            {t('leadCapture.callNow')}
                        </StyledButton>
                    </a>
                </div>
            </div>
        );
    };

    const isFirstStep = step === EStep.INITIAL;
    return (
        <div
            className={clsxm(
                '@container',
                'relative flex flex-col overflow-hidden rounded-[1.25rem] px-2 py-4 shadow-custom md:px-6',
                isFirstStep ? 'bg-main-dark' : 'bg-white',
            )}>
            {isFirstStep && (
                <div
                    className={clsxm(
                        'absolute left-0 top-0 h-full w-full',
                        'pointer-events-none hidden',
                        '@3xl:block',
                    )}>
                    <div className="absolute left-24 top-0 h-full w-8 skew-x-[-28deg] bg-[#CBAE70]"></div>
                    <div className="absolute left-36 top-0 h-full w-8 skew-x-[-28deg] bg-[#CBAE70]"></div>
                </div>
            )}
            <div className="flex justify-end">
                <LanguageSwitcher configLabel={configLabel} darkMode={isFirstStep} />
            </div>
            <div className="flex w-full flex-col gap-4 md:flex-row lg:gap-14">{renderStep()}</div>
        </div>
    );
};
