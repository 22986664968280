export type APIQueryParams = {
    term?: string;
    page?: number;
    size?: number | string;
    sort?: string;
};

export enum EQueryConfigNames {
    WIDGET_CONFIG = 'WIDGET_CONFIG',
}

export type RequiredNotNull<T> = {
    [P in keyof T]: NonNullable<T[P]>;
};
