import { APIQueryParams } from './main.types';
import {
    LegsPublicInDto,
    WidgetConfigOutDto,
    AirportOutDto,
    TripInDto,
    ContactFormInDto,
    MembershipCardInfoInDto,
} from '../swagger-types';
import { validateAndAssert } from '../services/api-service/api-schema-validations';
import { array, object } from 'yup';
import { SearchHitsOutDtoSchema } from './main.schema';
import { apiService } from 'services/api-service';
import { WIDGET_CONFIG_LABEL_DEFAULT } from 'tmp/widget.constant';

class MainApi {
    async getAirports(params?: APIQueryParams) {
        const { data } = await apiService.get<{ result: AirportOutDto[]; total: number }>(
            'public/airports',
            {
                params,
            },
        );
        return data;
    }

    async getAirportById(id: string) {
        const { data } = await apiService.get<AirportOutDto>(`public/airports/${id}`);
        return data;
    }

    async getAirportByTerm(term?: string) {
        const { data } = await apiService.get<{ result: AirportOutDto[]; total: number }>(
            'public/airports',
            {
                params: {
                    term,
                    size: 1,
                    page: 0,
                },
            },
        );
        if (data.result[0]) {
            return data.result[0];
        }
        return null;
    }

    async getWidgetConfig(configLabel = WIDGET_CONFIG_LABEL_DEFAULT) {
        return apiService.get<WidgetConfigOutDto>(`public/widget-config/by-label/${configLabel}`);
    }

    async getAllWidgetConfigs(params: APIQueryParams = { page: 0, size: 20 }) {
        const { data } = await apiService.get<{ result: WidgetConfigOutDto[] }>(
            `public/widget-config/paginated`,
            {
                params,
            },
        );
        return data;
    }

    bookingSearch(Dto: LegsPublicInDto) {
        return validateAndAssert(
            apiService.put('public/booking/search', Dto),
            object({
                result: array().of(SearchHitsOutDtoSchema).required(),
            }),
        );
    }
    bookingInquiry(Dto: TripInDto) {
        return apiService.post('public/booking/inquiry', {
            ...Dto,
            contactForm: {
                ...Dto.contactForm,
                // TODO remove hardcode when form ready
                hasFlownBefore: false,
            },
        });
    }

    async captureLead(dto: ContactFormInDto) {
        await apiService.post('public/booking/lead-creation', dto);
    }

    async requestMembershipCard(dto: MembershipCardInfoInDto) {
        await apiService.post('public/booking/membership-card', dto);
    }
}

export const mainApi = new MainApi();
