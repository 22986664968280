import { ERROR_KEY } from 'constants/errors';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { EActivateMembership, EMembershipCardType } from 'swagger-types';
import { oneOfEnum } from 'utils/yup.utils';
import { object, string, InferType, boolean } from 'yup';

export const MembershipFormSchema = object({
    firstName: string().required(ERROR_KEY.REQUIRED_FIELD),
    lastName: string().required(ERROR_KEY.LAST_NAME_REQUIRED),
    email: string().email(ERROR_KEY.INVALID_EMAIL).required(ERROR_KEY.REQUIRED_FIELD),
    phone: string()
        .required(ERROR_KEY.REQUIRED_FIELD)
        .test('phone-number', ERROR_KEY.INVALID_PHONE_NUMBER, phone =>
            isValidPhoneNumber(phone || ''),
        ),
    company: string(),
    hasAJetCard: boolean().required(ERROR_KEY.REQUIRED_FIELD),
    cardType: oneOfEnum(EMembershipCardType).required(ERROR_KEY.REQUIRED_FIELD),
    activateMembership: oneOfEnum(EActivateMembership),
});

export type MembershipFormSchema = InferType<typeof MembershipFormSchema>;
