import { gtag } from 'gtag/gtag';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { clsxm } from '../utils/clsxm';

export const LanguageSwitcher: React.FC<{ configLabel: string; darkMode?: boolean }> = ({
    configLabel,
    darkMode,
}) => {
    const {
        i18n: { changeLanguage, resolvedLanguage },
    } = useTranslation();

    const LANGUAGES = ['en', 'es'];

    return (
        <div className="flex items-center">
            {LANGUAGES.map((lng, index) => (
                <React.Fragment key={lng}>
                    <button
                        className={clsxm(
                            'px-2 font-semibold uppercase transition-colors',
                            darkMode ? 'text-neutral-600' : 'text-neutral-800',
                            darkMode
                                ? 'disabled:font-normal disabled:text-neutral-400'
                                : 'disabled:font-normal disabled:text-neutral-600',
                        )}
                        onClick={() => {
                            changeLanguage(lng);
                            gtag.changeLanguage({ language: lng }, { configLabel });
                        }}
                        disabled={resolvedLanguage === lng}>
                        {lng}
                    </button>
                    {/* not last */}
                    {index !== LANGUAGES.length - 1 && (
                        <div className="h-[14px] w-[1px] bg-neutral-400" />
                    )}
                </React.Fragment>
            ))}
        </div>
    );
};
