import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { ERROR_KEY } from 'constants/errors';
import { TRANSLATION_KEYS } from 'constants/translation';

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources: {
            en: {
                translation: {
                    charsLeft: '{{ chars }} left',
                    leadCapture: {
                        lookingToGetInTouch: 'Looking to Get In Touch?',
                        getInstantQuote: 'Get an Instant Quote',
                        callNow: 'Call Now',
                        emailLabelDescription: 'Quotes and Estimates will be emailed to you',
                        haveYouFlownBefore: 'Have you flown private before?',
                        byClickingAgreeToBeContacted:
                            'By clicking next, I agree to be contacted regarding services etc.',
                    },
                    membershipCard: {
                        firstName: 'First Name',
                        lastName: 'Last Name',
                        companyName: 'Company Name',
                        doYouHaveCard: 'Do you currently have a jet card?',
                        jetCardProgram: 'Jet card program',
                        buttonRequestDetails: 'Request Jet Card Details',
                        emailLabel: 'Preferred contact email',
                        requestSent: 'Request Sent!',
                        whenToActivateMembership: 'When are you looking to activate membership?',
                    },
                    common: {
                        selectOption: 'Select Option',
                        // TODO refactor to include more common keys under this group
                    },
                    yes: 'Yes',
                    no: 'No',
                    next: 'Next',
                    save: 'Save',
                    readMore: 'Read More',
                    cancel: 'Cancel',
                    done: 'Done',
                    inquire: 'Inquire',
                    from: 'From',
                    to: 'To',
                    oneWay: 'One Way',
                    roundTrip: 'Round Trip',
                    multiCity: 'Multi City',
                    searchFlight: 'Search Flight',
                    viewEstimatesAndInquire: 'View Estimates And Inquire',
                    departure: 'Departure Date',
                    passengers: 'Passengers',
                    maxPassengers: 'Max Passengers',
                    passenger_one: 'Passenger',
                    passenger_other: 'Passengers',
                    pet_one: 'Pet',
                    pet_other: 'Pets',
                    name: 'Name',
                    enterName: 'Enter Name',
                    emailAddress: 'Email Address',
                    enterEmail: 'Enter Email',
                    phoneNumber: 'Phone Number',
                    showMore: 'Show More',
                    return: 'Return Date',
                    requestsOrRequirements: 'Requests or requirements (optional)',
                    typeHere: 'Type Here',
                    enterPetsInfo: 'Enter weight, type and other info',
                    petsLabel: 'Weight, type, etc',
                    addPets: 'Adding pets',
                    addPet: 'Add Pet',
                    continueToInquire: 'Continue To Inquire',
                    enterContactDetails: 'Enter contact details',
                    yourEstimatesAreBelow: 'Your Estimates Are Below',
                    clickToView: 'Click to View',
                    estimatedPriceBeforeTaxes: 'Estimated price before taxes & fees',
                    weLovePets: `We love pets! Share some details about them so we can have the crew prepared for them!`,
                    matchesFound_one: '{{count}} match found',
                    matchesFound_other: '{{count}} matches found',
                    noResultsFound: 'No Results Found',
                    sendUsMessage: `Send us a message and we can personally look into available aircraft for your trip`,
                    seats: 'Seats',
                    luggage: 'Luggage',
                    flightTime: 'Flight time',
                    priceRange: 'Price Range',
                    provideYourContactDetails: 'Please provide your contact details here.',
                    addLeg: 'Add Leg',
                    iReadThe: 'I have read and accepted the',
                    privacyPolicy: 'Privacy Policy',
                    andToBeContacted: 'and to be contacted via SMS, Phone, or Email',
                    sendInquiry: 'Send Inquiry',
                    inquirySent: 'Inquiry Sent!',
                    weWillGetBack: 'We will get back to you shortly.',
                    noImages: 'No images',
                    time: 'Time',
                    loading: 'Loading…',
                    noOptions: 'No options',
                    maximumNumberOfPassengers: 'Maximum number of passengers on aircraft',
                    fuelStopsNeeded: 'Fuel stops may be needed',
                    emptyLegsMayBeAvailable: 'Empty legs may be available',
                    [ERROR_KEY.REQUIRED_FIELD]: 'Required',
                    [ERROR_KEY.LAST_NAME_REQUIRED]: 'Last name required',
                    [ERROR_KEY.INVALID_EMAIL]: 'Invalid email address',
                    [ERROR_KEY.AT_LEAST_ONE_HUMAN]: 'Should be at least one human',
                    [ERROR_KEY.AIRPORTS_ARE_THE_SAME]: 'From and To airports are the same',
                    [ERROR_KEY.DATES_ARE_THE_SAME]: 'Return date and start date cannot be the same',
                    [ERROR_KEY.RETURN_DATES_BEFORE_START]:
                        'Return date cannot be before start date',
                    [ERROR_KEY.INVALID_PHONE_NUMBER]: 'Phone number is not valid',
                    [ERROR_KEY.ILLOGICAL_DATE_RANGE]:
                        'Illogical date range (end before start, etc.)',
                    [TRANSLATION_KEYS.ENTER_DETAILS_BELOW_TO_SHOW_ESTIMATE]:
                        'Enter details below to show estimate',
                },
            },
            es: {
                translation: {
                    charsLeft: '{{ chars }} restantes',
                    leadCapture: {
                        lookingToGetInTouch: 'Buscando ponerte en contacto?',
                        getInstantQuote: 'Obtenga una cotización instantánea',
                        callNow: 'Llama ahora',
                        emailLabelDescription:
                            'Las cotizaciones y estimados se enviarán por correo electrónico',
                        haveYouFlownBefore: '¿Has volado en privado antes?',
                        byClickingAgreeToBeContacted:
                            'Al hacer clic en Siguiente, acepto que me contacten con respecto a servicios, etc.',
                    },
                    membershipCard: {
                        firstName: 'Nombre',
                        lastName: 'Apellido',
                        companyName: 'Nombre de la empresa',
                        doYouHaveCard: '¿Actualmente tienes una tarjeta jet?',
                        jetCardProgram: 'Tipo de Membresia',
                        buttonRequestDetails: 'Solicitar detalles de membresía',
                        emailLabel: 'Correo electrónico de contacto preferido',
                        requestSent: '¡Solicitud enviada!',
                        whenToActivateMembership: '¿Cuándo desea activar la membresía?',
                    },
                    common: {
                        selectOption: 'Seleccionar opción',
                        // TODO refactor to include more common keys under this group
                    },
                    yes: 'Sí',
                    no: 'No',
                    next: 'Próximo',
                    from: 'De',
                    to: 'A',
                    readMore: 'Lee mas',
                    oneWay: 'Un camino',
                    roundTrip: 'Viaje ida y vuelta',
                    multiCity: 'Multi ciudad',
                    save: 'Ahorrar',
                    cancel: 'Cancelar',
                    done: 'Hecho',
                    inquire: 'Preguntar',
                    searchFlight: 'Buscar Vuelo',
                    viewEstimatesAndInquire: 'Ver presupuestos y consultar',
                    departure: 'Fecha de salida',
                    passengers: 'Pasajeros',
                    maxPassengers: 'Max Pasajeros',
                    passenger_one: 'Pasajero',
                    passenger_other: 'Pasajeros',
                    pet_one: 'Mascota',
                    pet_other: 'Mascotas',
                    name: 'Nombre',
                    enterName: 'Ingrese su nombre',
                    emailAddress: 'Dirección de correo electrónico',
                    enterEmail: 'Ingrese correo electrónico',
                    phoneNumber: 'Número de teléfono',
                    showMore: 'Mostrar más',
                    return: 'Fecha de regreso',
                    requestsOrRequirements: 'Solicitudes o requisitos (opcional)',
                    typeHere: 'Escriba aquí',
                    enterPetsInfo: 'Ingrese peso, tipo y otra información',
                    petsLabel: 'Peso, tipo, etc.',
                    addPets: 'Agregar mascotas',
                    addPet: 'Añadir mascota',
                    continueToInquire: 'Continuar investigando',
                    enterContactDetails: 'Ingrese los datos de contacto',
                    yourEstimatesAreBelow: 'Sus estimaciones están por debajo',
                    clickToView: 'Click para ver',
                    estimatedPriceBeforeTaxes: 'Precio estimado antes de impuestos y tasas',
                    weLovePets: `¡Nos encantan las mascotas! ¡Comparte algunos detalles sobre ellos para que podamos tener al equipo preparado para ellos!`,
                    matchesFound_one: '{{count}} coincidencia encontrada',
                    matchesFound_other: '{{count}} coincidencias encontradas',
                    noResultsFound: 'No se han encontrado resultados',
                    sendUsMessage: `Envíenos un mensaje y podemos buscar personalmente los aviones disponibles. para tu viaje`,
                    seats: 'Asientos',
                    luggage: 'Equipaje',
                    flightTime: 'Tiempo de vuelo',
                    priceRange: 'Rango de precios',
                    provideYourContactDetails: 'Proporcione sus datos de contacto aquí.',
                    addLeg: 'Agregar pierna',
                    iReadThe: 'He leído y aceptado la',
                    privacyPolicy: 'Política de Privacidad',
                    andToBeContacted:
                        'y consiento ser contactado por SMS, teléfono o correo electrónico',
                    sendInquiry: 'Enviar Consulta',
                    inquirySent: '¡Consulta enviada!',
                    weWillGetBack: 'Nos pondremos en contacto con usted en breve.',
                    noImages: 'Sin imágenes',
                    time: 'Tiempo',
                    loading: 'Cargando…',
                    noOptions: 'Sin opciones',
                    maximumNumberOfPassengers: 'Número máximo de pasajeros en el avión',
                    fuelStopsNeeded: 'Pueden ser necesarias paradas de combustible',
                    emptyLegsMayBeAvailable: 'Las piernas vacías pueden estar disponibles',
                    [ERROR_KEY.REQUIRED_FIELD]: 'Requerido',
                    [ERROR_KEY.LAST_NAME_REQUIRED]: 'Apellido Obligatorio',
                    [ERROR_KEY.INVALID_EMAIL]: 'Dirección de correo electrónico no válida',
                    [ERROR_KEY.AT_LEAST_ONE_HUMAN]: 'Debe ser al menos un humano',
                    [ERROR_KEY.AIRPORTS_ARE_THE_SAME]:
                        'Los aeropuertos desde y hacia son los mismos',
                    [ERROR_KEY.DATES_ARE_THE_SAME]:
                        'La fecha de regreso y la fecha de inicio no pueden ser la misma',
                    [ERROR_KEY.RETURN_DATES_BEFORE_START]:
                        'La fecha de devolución no puede ser anterior a la fecha de inicio',
                    [ERROR_KEY.INVALID_PHONE_NUMBER]: 'El número de teléfono no es válido',
                    [ERROR_KEY.ILLOGICAL_DATE_RANGE]:
                        'Intervalo de fechas imposible (finalización anterior a inicio, etc.)',
                    [TRANSLATION_KEYS.ENTER_DETAILS_BELOW_TO_SHOW_ESTIMATE]:
                        'Ingrese los detalles a continuación para mostrar la estimación',
                },
            },
        },
        fallbackLng: 'en',
    });
