import { object, string } from 'yup';

const EnvSchema = object({
    REACT_APP_ENV_NAME: string().required(),
    REACT_APP_API_BASE_URL: string().required(),
});

export const typedEnv = EnvSchema.cast(
    {
        ...process.env,
    },
    { stripUnknown: false },
);

console.log('env', typedEnv);

export const IS_DEV_ENV = typedEnv.REACT_APP_ENV_NAME === 'development';
export const IS_PROD_ENV = typedEnv.REACT_APP_ENV_NAME === 'production';
export const IS_NOT_PROD_ENV = !IS_PROD_ENV;
