import React from 'react';

import {
    Radio as MuiRadio,
    RadioProps as MuiRadioProps,
    FormHelperText,
    FormControlLabel,
} from '@mui/material';

import { RadioChecked, RadioEmpty } from '../../assets';
import { clsxm } from 'utils/clsxm';

export type ColorTypes = 'primary' | 'red' | 'green' | 'blue';

export type RadioProps = {
    label?: string | number | React.ReactElement;
    errorMessage?: string | boolean | undefined;
    containerClassName?: string;
    darkMode?: boolean;
} & Omit<MuiRadioProps, 'color'>;

export const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
    ({ errorMessage, label, className, containerClassName, darkMode, ...props }, ref) => {
        let radioComponent = (
            <MuiRadio
                icon={<RadioEmpty />}
                checkedIcon={<RadioChecked />}
                {...props}
                inputRef={ref}
                className={clsxm('text-accent', className)}
            />
        );
        if (label) {
            radioComponent = (
                <div
                    className={clsxm(
                        'flex items-center text-xs',
                        darkMode
                            ? props.checked
                                ? 'text-gray-100'
                                : 'text-gray-500'
                            : props.checked
                            ? 'text-gray-800'
                            : 'text-gray-500',
                        containerClassName,
                    )}>
                    <FormControlLabel control={radioComponent} label={label} />
                </div>
            );
        }
        return (
            <div>
                {radioComponent}

                {errorMessage && (
                    <FormHelperText className="text-[#fc3030]">{errorMessage}</FormHelperText>
                )}
            </div>
        );
    },
);
