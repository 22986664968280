import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { PARAM_GOOGLE_CLICK_ID, PARAM_GOOGLE_CLIENT_ID } from './google.constant';

export const useGoogleId = () => {
    /**
     * "gclid" is query param added by Google Ads from ad links
     * how to test: search to buy shoes in google and click on sponsored ad link, inspect URL
     */
    const [gclid] = useQueryParam('gclid', withDefault(StringParam, null));
    const [queryClickId] = useQueryParam(PARAM_GOOGLE_CLICK_ID, withDefault(StringParam, null));
    let googleClickId = gclid || queryClickId;
    if (googleClickId) {
        localStorage.setItem(PARAM_GOOGLE_CLICK_ID, googleClickId);
    } else {
        googleClickId = localStorage.getItem(PARAM_GOOGLE_CLICK_ID);
    }
    let googleClientId = get_ga_clientid();
    if (googleClientId) {
        localStorage.setItem(PARAM_GOOGLE_CLIENT_ID, googleClientId);
    } else {
        googleClientId = localStorage.getItem(PARAM_GOOGLE_CLIENT_ID) || undefined;
    }
    return { googleClickId: googleClickId || undefined, googleClientId };
};

const get_ga_clientid = () => {
    const cookie: Record<string, unknown> = {};
    document.cookie.split(';').forEach(function (el) {
        const splitCookie = el.split('=');
        const key = splitCookie[0]?.trim();
        const value = splitCookie[1];
        if (key) {
            cookie[key] = value;
        }
    });
    const maybeValue = cookie['_ga'];
    return maybeValue ? String(maybeValue).substring(6) : undefined;
};
