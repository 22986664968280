import { LeadFormSchema } from 'QuoteForm/lead-capture/lead.schema';
import { renderPaxLeg } from 'utils/aircraft.utils';
import {
    ExtendedInquiryInDto,
    IEventCaptureLead,
    IEventRequestMembershipCard,
    IEventSendInquiry,
} from './gtag.type';
import { MembershipCardInfoInDto } from 'swagger-types';

// map dto to flat GTAG-compatible object
export const gtagNormalize = {
    lead: (form: LeadFormSchema): IEventCaptureLead => {
        return form;
    },
    membershipCard: (form: MembershipCardInfoInDto): IEventRequestMembershipCard => {
        return {
            firstName: form.contactForm.firstName,
            lastName: form.contactForm.lastName,
            email: form.contactForm.email,
            phone: form.contactForm.phone,
            company: form.contactForm.company,
            hasJetCardAlready: form.contactForm.hasAJetCard,
            cardType: form.contactForm.cardType,
            whenToActivateMembership: form.contactForm.activateMembership,
        };
    },
    inquiry: (form: ExtendedInquiryInDto): IEventSendInquiry => {
        const { flight, totalLowBudgetRange, totalHighBudgetRange } = form.meta;
        const mainLeg = flight?.legs[0];
        const res: IEventSendInquiry = {
            // contact details
            customer_name: form.contactForm.name,
            customer_email: form.contactForm.email,
            customer_phone: form.contactForm.phone,
            customer_comment: form.contactForm.comment || undefined, // optional
            // aircraft details
            aircraft_category: flight?.aircraft.type, // optional
            aircraft_usd_price_range: [
                flight?.totalLowBudgetRange,
                ' ',
                'USD',
                ' - ',
                flight?.totalHighBudgetRange,
                ' ',
                'USD',
            ].join(''), // optional
            aircraft_currency_price_range: [
                totalLowBudgetRange.toFixed(3),
                ' ',
                form.selectedAircraft?.priceRange.currency,
                ' - ',
                totalHighBudgetRange.toFixed(3),
                ' ',
                form.selectedAircraft?.priceRange.currency,
            ].join(''), // optional
            // search details
            search_type: form.meta.searchType, // One Way, Round Trip etc
            search_currency: form.selectedAircraft?.priceRange.currency || '-',
            search_language: form.meta.resolvedLanguage,
            aircraft_main_leg_from: mainLeg?.startAirport.icaoCode || '-', // first leg
            aircraft_main_leg_to: mainLeg?.endAirport.icaoCode || '-', // first leg
            aircraft_all_legs:
                // TODO show logs from search, not from flight
                flight?.legs
                    .filter(e => !e.isFerry)
                    .map(e => renderPaxLeg(e))
                    .join(' / ') || '-', // all legs concatenated
            aircraft_departure_date: mainLeg?.date || '-',
            search_passengers: `${form.searchForm.passengers} pax, ${
                form.searchForm.pets?.map(e => e.details).join(', ') || 'no pets'
            }`,
        };
        return res;
    },
};
