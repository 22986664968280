import { UIKitCurrencyRenderer } from 'ui-kit/UIKitCurrencyRenderer';
import { createBrowserRouter, Link } from 'react-router-dom';
import { QuoteForm } from 'QuoteForm/QuoteForm';
import { mainApi } from 'api/main.api';
import { useQuery } from '@tanstack/react-query';
import { clsxm } from 'utils/clsxm';
import { useAdminWidgetConfigLabelQueryParam } from 'tmp/webtools.url.hook';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { getGlobalConfigLabel } from 'utils/config.utils';
import { IS_NOT_PROD_ENV } from 'environment/typedEnv';

const DevForm: React.FC = () => {
    return (
        <QueryParamProvider adapter={ReactRouter6Adapter}>
            <DevBody />
        </QueryParamProvider>
    );
};

const DevBody: React.FC = () => {
    const [configLabel, setConfigLabel] = useAdminWidgetConfigLabelQueryParam();
    const { data } = useQuery({
        queryKey: ['mainApi.getAllWidgetConfigs'],
        queryFn: async () => mainApi.getAllWidgetConfigs(),
    });

    return (
        <div>
            <div className="flex flex-wrap items-center gap-4 p-2">
                <div className="text-gray-400">Label:</div>
                {data?.result.map(e => (
                    <WidgetLabel
                        key={e.id}
                        label={e.label}
                        selected={e.label === configLabel}
                        onClick={() => setConfigLabel(e.label)}
                    />
                ))}
            </div>
            <QuoteForm configLabel={configLabel} key={configLabel} />
            <div className="mt-24 mb-4">
                <div className="bg-white/50 p-2">
                    <Link to="/ui-kit">UI Kit</Link>
                </div>
            </div>
        </div>
    );
};

const WidgetLabel: React.FC<{
    label: string;
    selected?: boolean;
    className?: string;
    onClick?: () => void;
}> = ({ label, selected, className, onClick }) => {
    return (
        <div
            className={clsxm(
                'rounded bg-gray-200 px-2 py-1 font-bold',
                selected && 'bg-accent',
                onClick && 'cursor-pointer',
                className,
            )}
            onClick={onClick}
            aria-hidden>
            {label}
        </div>
    );
};

export const router = createBrowserRouter([
    {
        path: '/ui-kit',
        element: <UIKitCurrencyRenderer />,
    },
    {
        path: '*',
        element: IS_NOT_PROD_ENV ? <DevForm /> : <QuoteForm configLabel={getGlobalConfigLabel()} />,
    },
]);
