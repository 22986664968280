export const convertCentToUnit = (cents: number) => cents * 0.01;
export const convertUnitToCent = (usd: number) => usd * 100;

/**
 * Safari-compatible solution
 */
export const numberWithCommas = (
    number: number | string,
    dec = 0,
    dsep?: string,
    tsep?: string,
) => {
    if (isNaN(+number) || number == null) {
        return '';
    }

    number = (typeof number === 'string' ? parseFloat(number) : number).toFixed(~~dec);
    tsep = typeof tsep == 'string' ? tsep : ',';

    const parts = number.split('.');
    const fnums = parts[0] || '';
    const decimals = parts[1] ? (dsep || '.') + parts[1] : '';

    return fnums.replace(/(\d)(?=(?:\d{3})+$)/g, `$1${tsep}`) + decimals;
};

export const formatDollars = (amount: number | string, fractions = 0) => {
    let num = +amount;
    if (Number.isNaN(num)) {
        num = 0;
    }
    return `$${numberWithCommas(num.toFixed(fractions))}`;
};
