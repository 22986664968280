import React from 'react';
import { Typography } from '@mui/material';
import { AircraftIcon } from '../assets/icons';
import { useTranslation } from 'react-i18next';
import { PixelConversionSignal } from 'components/PixelConversionSignal';

export const InquirySentBlock: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="mt-3 flex justify-center rounded-[1.25rem] bg-white p-8 shadow-custom">
            <div className="flex flex-col">
                <div className="flex flex-col items-center gap-4 md:flex-row">
                    <div className="flex h-[48px] w-[48px] items-center justify-center rounded-full bg-accent">
                        <AircraftIcon className="rotate-90" />
                    </div>
                    <div className="flex flex-col gap-1">
                        <Typography className="text-xl font-semibold uppercase text-gray-800">
                            {t('inquirySent')}
                        </Typography>
                        <Typography className="text-sm text-gray-600">
                            {t('weWillGetBack')}
                        </Typography>
                    </div>
                </div>
                <PixelConversionSignal />
            </div>
        </div>
    );
};
