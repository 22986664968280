import React, { useMemo } from 'react';
import clsx from 'clsx';
import { capitalize, isNull } from 'lodash';
import ReactDatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import 'react-datepicker/dist/react-datepicker.min.css';

import { useUncontrolled } from '../../hooks/useUncontrolled.hook';
import styles from './DatePicker.module.scss';
import { DatepickerNavigationNextIcon, DatepickerNavigationPreviousIcon } from '../../assets/icons';
import { useTranslation } from 'react-i18next';

registerLocale('es', es); // register it with the name you want

export type DatePickerProps = Omit<
    ReactDatePickerProps,
    'inline' | 'formatWeekDay' | 'renderCustomHeader' | 'dayClassName' | 'onChange'
> & {
    customWeekDayClassName?: string;
    customDayClassName?: string;
    customMonthClassName?: string;
    size?: 'medium' | 'large';
    onChange?(date: Date): void;
};

const stylesTyped: Record<string, any> = styles;

export const DatePicker: React.FC<DatePickerProps> = ({
    size = 'medium',
    calendarClassName,
    selectsRange,
    selected,
    customWeekDayClassName,
    customMonthClassName,
    customDayClassName,
    onChange,
    monthsShown,
    ...rest
}) => {
    const {
        t,
        i18n: { language },
    } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [selectedState, handleDateChange] = useUncontrolled({
        value: selected,
        rule: val => !!val || isNull(val),
        onChange,
    });
    const localeLanguage = useMemo(() => (language === es.code ? language : 'en'), [language]);

    return (
        <ReactDatePicker
            locale={localeLanguage}
            timeCaption={t('time')}
            renderCustomHeader={({ monthDate, decreaseMonth, increaseMonth }) => (
                <div className="p-6">
                    <button
                        aria-label="Previous Month"
                        className={
                            'react-datepicker__navigation react-datepicker__navigation--previous'
                        }
                        type="button"
                        onClick={decreaseMonth}>
                        <span
                            className={
                                'react-datepicker__navigation-icon react-datepicker__navigation-icon--previous'
                            }>
                            <DatepickerNavigationPreviousIcon />
                        </span>
                    </button>
                    <span className="react-datepicker__current-month">
                        {monthDate.toLocaleString(localeLanguage, {
                            month: 'long',
                            year: 'numeric',
                        })}
                    </span>
                    <button
                        aria-label="Next Month"
                        className={
                            'react-datepicker__navigation react-datepicker__navigation--next'
                        }
                        type="button"
                        onClick={increaseMonth}>
                        <span
                            className={
                                'react-datepicker__navigation-icon react-datepicker__navigation-icon--next'
                            }>
                            <DatepickerNavigationNextIcon />
                        </span>
                    </button>
                </div>
            )}
            formatWeekDay={day => (
                <Typography
                    className={clsx(
                        styles.WeekDay,
                        customWeekDayClassName,
                        stylesTyped[capitalize(size)],
                    )}
                    variant="subtitle1">
                    {day.substring(0, 3)}
                </Typography>
            )}
            calendarClassName={clsx(styles.DatePickerWrapper, calendarClassName)}
            wrapperClassName={clsx(styles.InputDatePickerWrapper)}
            dayClassName={() => {
                return clsx(
                    styles.DatePickerDay,
                    customDayClassName,
                    stylesTyped[capitalize(size)],
                );
            }}
            monthClassName={() =>
                clsx(styles.DatePickerMonth, customMonthClassName, stylesTyped[capitalize(size)])
            }
            portalId="datepicker-portal"
            shouldCloseOnSelect
            disabledKeyboardNavigation
            selected={selectedState}
            selectsRange={selectsRange}
            onChange={handleDateChange}
            monthsShown={isMobile ? 1 : monthsShown}
            {...rest}
        />
    );
};
