import React, { useState, useCallback } from 'react';
import LuggageIcon from '@mui/icons-material/Luggage';
import { Chip, IconButton, Link, Typography } from '@mui/material';
import { StyledButton } from '../components/StyledButton';
import { AircraftIcon, InfoIcon, SeatIcon } from '../assets/icons';
import { Carousel } from '../components/carousel/Carousel';
import { Dialog } from '../components/dialog/Dialog';
import { clsxm } from '../utils/clsxm';
import { InquireForm } from '../InquireForm/InquireForm';
import {
    EDefaultTab,
    SearchFormInDto,
    SearchHitsOutDto,
    WidgetConfigInDto,
} from '../swagger-types';
import { useTranslation } from 'react-i18next';
import { ButtonUnstyled } from '@mui/base';
import { renderPaxLeg } from '../utils/aircraft.utils';
import { CurrencyIcon, CurrencyRenderer } from '../exchange-manager/CurrencyRenderer';
import { CURRENCY_KEY } from '../exchange-manager/exchange-manager';
import { typedEnv } from 'environment/typedEnv';
import { useHandler } from 'hooks/useHandler.hook';
import { gtag } from 'gtag/gtag';
import { TRANSLATION_KEYS } from 'constants/translation';
import { getSEOClassNameForInquireButton } from 'gtag/analytics.seo';

export const Flight: React.FC<{
    flight: SearchHitsOutDto;
    tab: EDefaultTab;
    doNotDiscloseEstimate: boolean;
    inquired: boolean;
    showContactBeforeEstimate: boolean;
    onConfirm: () => void;
    currency: CURRENCY_KEY;
    configLabel: string;
    widgetConfig: WidgetConfigInDto;
    searchForm: SearchFormInDto;
}> = ({
    flight,
    tab,
    doNotDiscloseEstimate,
    inquired,
    showContactBeforeEstimate,
    onConfirm,
    currency,
    configLabel,
    widgetConfig,
    searchForm,
}) => {
    const { t } = useTranslation();
    const {
        aircraft: {
            lowPax,
            highPax,
            type,
            description,
            pictureIds,
            luggage,
            features = [],
            webpageUrl,
        },
        totalLowBudgetRange,
        totalHighBudgetRange,
        legs,
    } = flight;
    const [openDialog, setOpenDialog] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [showAllFeatures, setShowAllFeatures] = useState(false);
    const [formRef, setFormRef] = useState<HTMLDivElement | null>(null);

    const handleDialogClose = useCallback(() => {
        setOpenDialog(false);
    }, []);

    const handleDialogOpen = useCallback(() => {
        setOpenDialog(true);
    }, []);

    const handleFormClose = useCallback(() => {
        setOpenForm(false);
    }, []);

    const handleFormOpen = useHandler(() => {
        setOpenForm(true);
        gtag.openInquiryForm(
            {
                aicraftType: type,
            },
            { configLabel, withTotalEvent: true },
        );
        setTimeout(() => {
            formRef?.scrollIntoView(true);
        }, 100);
    });

    return (
        <>
            <div className="grid gap-y-3 rounded-[1.25rem] bg-white py-4 px-2 shadow-custom md:grid-cols-2 md:p-6">
                <div className="md:px-2">
                    <div className="flex items-center justify-between gap-3">
                        <div className="flex items-center gap-3">
                            <Typography className="h-fit rounded-xl bg-gray-900 px-5 py-1.5 font-semibold uppercase text-white">
                                {type}
                            </Typography>
                            {description && (
                                <IconButton onClick={handleDialogOpen}>
                                    <InfoIcon />
                                </IconButton>
                            )}
                        </div>
                        {webpageUrl && (
                            <Link
                                href={webpageUrl}
                                underline="hover"
                                target="_blank"
                                className="pr-2 font-semibold !text-gray-800 transition-all">
                                {t('readMore')}
                            </Link>
                        )}
                    </div>
                    <Carousel
                        images={
                            pictureIds?.map(pictureId => ({
                                url: `${typedEnv.REACT_APP_API_BASE_URL}public/files/${pictureId}`,
                            })) || []
                        }
                    />
                    <div className="flex flex-wrap items-center gap-2">
                        {features.slice(0, 2).map(({ id, name }) => (
                            <Chip key={id} label={name} />
                        ))}
                        {features.length > 2 && !showAllFeatures && (
                            <ButtonUnstyled
                                onClick={() => setShowAllFeatures(true)}
                                className={clsxm(
                                    'bg-transparent p-2 text-xs font-semibold transition',
                                    'hover:underline',
                                    'active:underline',
                                )}>
                                {t('showMore')}
                            </ButtonUnstyled>
                        )}
                        {showAllFeatures &&
                            features.slice(2).map(({ id, name }) => <Chip key={id} label={name} />)}
                    </div>
                </div>
                <div
                    className={clsxm(
                        'flex flex-col justify-between gap-6 border-t border-t-gray-400 py-6',
                        'md:border-t-0 md:border-l md:border-l-gray-400/40 md:px-6',
                    )}>
                    <div className="flex flex-col gap-6">
                        <div className="flex justify-between gap-3.5">
                            <div className="flex gap-3.5">
                                <div className="flex w-[28px] items-center justify-center">
                                    <SeatIcon />
                                </div>
                                <div className="flex flex-col gap-1">
                                    <Typography className="text-sm uppercase text-gray-600">
                                        {t('seats')}
                                    </Typography>
                                    <Typography className="font-semibold text-gray-800">{`${lowPax} - ${highPax}`}</Typography>
                                </div>
                            </div>
                            {luggage && (
                                <div className="flex min-w-[50%] gap-3.5">
                                    <div className="flex w-[28px] items-center justify-center">
                                        <LuggageIcon fontSize="large" />
                                    </div>
                                    <div className="flex flex-col gap-1">
                                        <Typography className="text-sm uppercase text-gray-600">
                                            {t('luggage')}
                                        </Typography>
                                        <Typography className="font-semibold text-gray-800">
                                            {luggage}
                                        </Typography>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="flex gap-3.5">
                            <div className="mt-2.5 flex w-[28px] justify-center">
                                <AircraftIcon />
                            </div>
                            <div className="flex flex-col gap-1">
                                <Typography className="text-sm uppercase text-gray-600">
                                    {t('flightTime')}
                                </Typography>
                                {legs
                                    .filter(({ isFerry }) => !isFerry)
                                    .map((leg, index) => (
                                        <Typography
                                            key={index}
                                            className="font-semibold text-gray-800">
                                            {leg.fuelStopsNeeded ? (
                                                <span>
                                                    {renderPaxLeg(leg)}
                                                    {' - '}
                                                    ⚠️ {t('fuelStopsNeeded')}
                                                </span>
                                            ) : (
                                                renderPaxLeg(leg)
                                            )}
                                        </Typography>
                                    ))}
                            </div>
                        </div>
                        {!doNotDiscloseEstimate && (
                            <div className="flex gap-3.5">
                                <div className="flex w-[28px] items-center justify-center">
                                    <CurrencyIcon currency={currency} className="scale-150" />
                                </div>

                                <div className="flex flex-col gap-1">
                                    <Typography className="text-sm uppercase text-gray-600">
                                        {t('priceRange')}
                                    </Typography>
                                    {showContactBeforeEstimate ? (
                                        <ButtonUnstyled
                                            className="text-left font-semibold text-neutral-800 underline transition-colors hover:text-gold-text"
                                            onClick={handleFormOpen}>
                                            {openForm
                                                ? t(
                                                      TRANSLATION_KEYS.ENTER_DETAILS_BELOW_TO_SHOW_ESTIMATE,
                                                  )
                                                : t('clickToView')}
                                        </ButtonUnstyled>
                                    ) : (
                                        <Typography
                                            component="div"
                                            className="flex gap-1.5 font-semibold text-gray-800">
                                            <CurrencyRenderer
                                                usdAmount={totalLowBudgetRange}
                                                asCurrency={currency}
                                            />
                                            {' - '}
                                            <CurrencyRenderer
                                                usdAmount={totalHighBudgetRange}
                                                asCurrency={currency}
                                            />
                                        </Typography>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="mt-4 grid items-center gap-5 md:grid-cols-[60%,40%]">
                        <StyledButton
                            className={clsxm('w-full', getSEOClassNameForInquireButton(type))}
                            onClick={handleFormOpen}
                            disabled={inquired}>
                            {t('inquire')}
                        </StyledButton>
                        <Typography className="text-xs text-gray-600">
                            * {t('estimatedPriceBeforeTaxes')}.
                        </Typography>
                    </div>
                </div>
            </div>
            {/* ref needed to scroll this element into view when form opened */}
            <div ref={setFormRef} />
            <InquireForm
                configLabel={configLabel}
                tab={tab}
                flight={flight}
                currency={currency}
                open={openForm}
                onClose={handleFormClose}
                onConfirm={onConfirm}
                inquired={inquired}
                widgetConfig={widgetConfig}
                searchForm={searchForm}
            />
            <Dialog
                open={openDialog}
                onClose={handleDialogClose}
                classes={{
                    paper: 'w-full',
                }}>
                <Typography className="!mb-4 text-2xl font-semibold text-[#3C3C3C]">
                    {type}
                </Typography>
                <Typography className="text-base text-[#3C3C3C]">{description}</Typography>
            </Dialog>
        </>
    );
};
