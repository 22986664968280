import { LanguageSwitcher } from 'components/LanguageSwitcher';
import { clsxm } from 'utils/clsxm';
import { MembershipCardForm } from './MembershipCardForm';
import { useState } from 'react';
import { MembershipCardSentBlock } from './MembershipCardSentBlock';

export const MembershipCardFlow: React.FC<{
    configLabel: string;
}> = ({ configLabel }) => {
    const [isSent, setIsSent] = useState(false);

    const renderStep = () => {
        if (isSent) {
            return (
                <div className="flex w-full justify-center">
                    <MembershipCardSentBlock />
                </div>
            );
        }

        return (
            <div className="flex w-full flex-col items-center gap-8 py-16 text-white @container">
                <div className="text-center text-3xl tracking-wider @3xl:tracking-widest">
                    Get started on flying the right way, today.
                </div>
                <MembershipCardForm
                    configLabel={configLabel}
                    onComplete={() => {
                        setIsSent(true);
                    }}
                />
            </div>
        );
    };

    return (
        <div
            className={clsxm(
                '@container',
                'relative flex flex-col overflow-hidden rounded-[1.25rem] px-2 py-4 shadow-custom md:px-6',
                'bg-main-dark',
            )}>
            <div className="flex justify-end">
                <LanguageSwitcher configLabel={configLabel} darkMode />
            </div>
            <div className="flex w-full flex-col gap-4 md:flex-row lg:gap-14">{renderStep()}</div>
        </div>
    );
};
