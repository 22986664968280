import React, { createContext, useContext, useMemo, useState } from 'react';
import { ContactFormInDto } from 'swagger-types';
import { WithOptionalKeys } from 'utils/type.utils';
import { boolean, object, string } from 'yup';

type OverrideContactFormInDto = WithOptionalKeys<ContactFormInDto, 'hasFlownBefore'>;

/**
 * hook requires provider @type {PersistantLeadProvider}
 */
export const useSharedLeadState = () => {
    const manager = useContext(LeadContext);
    if (!manager) {
        throw new Error('provider not found for lead state');
    }
    return manager;
};
const ContactFormInDtoSchema = object({
    email: string().default(''),
    phone: string().default(''),
    name: string().default(''),
    // company: string().default(''),
    // comment: string().default(''),
    hasFlownBefore: boolean(),
});

const readPersistedLeadState = () => {
    const defaultValue = ContactFormInDtoSchema.validateSync({});
    try {
        const raw = localStorage.getItem('lead-state');
        if (!raw) {
            return defaultValue;
        }
        const val = JSON.parse(raw);
        const valid = ContactFormInDtoSchema.validateSync(val);
        return valid;
    } catch {
        return defaultValue;
    }
};
const writePersistedLeadState = (value: OverrideContactFormInDto) => {
    const defaultValue = ContactFormInDtoSchema.validateSync({});
    try {
        const shrinkedValue = { ...value };
        delete shrinkedValue.hasFlownBefore;
        const raw = JSON.stringify(shrinkedValue);
        localStorage.setItem('lead-state', raw);
    } catch {
        return defaultValue;
    }
};

const useLeadState = () => {
    const defaultLead = useMemo(() => readPersistedLeadState(), []);
    const [state, _setState] = useState<OverrideContactFormInDto>(defaultLead);
    return useMemo(() => {
        const setState: typeof _setState = val => {
            if (typeof val !== 'object') {
                console.error('lead value not an object', val);
                throw new Error('lead value not an object');
            }
            writePersistedLeadState(val);
            _setState(val);
        };
        return [state, setState] as const;
    }, [state, _setState]);
};

const LeadContext = createContext<ReturnType<typeof useLeadState> | undefined>(undefined);

export const PersistantLeadProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const leadManager = useLeadState();
    return <LeadContext.Provider value={leadManager}>{children}</LeadContext.Provider>;
};
