import { yupResolver } from '@hookform/resolvers/yup';
import { InputAdornment, RadioGroup } from '@mui/material';
import { useCreateLeadCapture } from 'api/main.api.hook';
import { EmailIcon, UserIcon } from 'assets/icons';
import { Input } from 'components/input/Input';
import { PhoneInput } from 'components/phone-input/PhoneInput';
import { Radio } from 'components/radio/Radio';
import { StyledButton } from 'components/StyledButton';
import { gtag } from 'gtag/gtag';
import { useDeviceDetails } from 'gtag/useDeviceDetails';
import { useGoogleId } from 'gtag/useGoogleId';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LeadFormSchema } from './lead.schema';
import { useSharedLeadState } from './useSharedLeadState';

export const LeadForm: React.FC<{
    configLabel: string;
    onComplete: () => void;
}> = ({ configLabel, onComplete }) => {
    const { t } = useTranslation();
    const [sharedLead, setSharedLead] = useSharedLeadState();

    const {
        register,
        formState: { errors },
        handleSubmit,
        control,
        getValues,
    } = useForm<LeadFormSchema>({
        resolver: yupResolver(LeadFormSchema),
        defaultValues: sharedLead,
    });

    const inputRegister: typeof register = (key, ...args) => {
        const reg = register(key, ...args);
        return {
            ...reg,
            onBlur: e => {
                if (key === 'name' && e?.target) {
                    gtag.enterName({ value: e.target.value }, { configLabel });
                }
                if (key === 'email' && e?.target) {
                    gtag.enterEmail({ value: e.target.value }, { configLabel });
                }
                if (key === 'comment' && e?.target) {
                    gtag.enterComment({ value: e.target.value }, { configLabel });
                }
                if (!e?.target) {
                    return Promise.resolve(false);
                }
                setSharedLead(getValues());
                return reg.onBlur(e);
            },
            errorMessage: t(errors[key]?.message?.toString() || ''),
            disabled: isLoading,
        };
    };

    const { mutate: captureLeadMutation, isLoading } = useCreateLeadCapture();
    const { googleClickId, googleClientId } = useGoogleId();
    const deviceDetails = useDeviceDetails();

    const onSubmit: SubmitHandler<LeadFormSchema> = data => {
        captureLeadMutation(
            { dto: { ...data, googleClickId, googleClientId, ...deviceDetails }, configLabel },
            {
                onSuccess: () => {
                    onComplete();
                },
            },
        );
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <div className="flex flex-col gap-6">
                <div className="grid gap-6 md:grid-cols-2">
                    <Input
                        {...inputRegister('name')}
                        label={t('name')}
                        placeholder={t('enterName')}
                        startAdornment={
                            <InputAdornment position="start">
                                <UserIcon />
                            </InputAdornment>
                        }
                    />
                    <Controller
                        name="phone"
                        control={control}
                        render={({ field, fieldState }) => {
                            return (
                                <PhoneInput
                                    {...field}
                                    onBlur={e => {
                                        if (e?.target) {
                                            gtag.enterPhone(
                                                {
                                                    value: (e.target as HTMLInputElement).value,
                                                },
                                                { configLabel },
                                            );
                                            setSharedLead(getValues());
                                        }
                                        return field.onBlur();
                                    }}
                                    international
                                    defaultCountry="US"
                                    label={t('phoneNumber')}
                                    placeholder={'+1 000 000 0000'}
                                    disabled={isLoading}
                                    errorMessage={t(fieldState.error?.message || '')}
                                />
                            );
                        }}
                    />
                </div>
                <div className="grid items-end gap-6 md:grid-cols-2">
                    <div>
                        <div className="text-sm text-gray-500">
                            {t('leadCapture.emailLabelDescription')}
                        </div>
                        <Input
                            {...inputRegister('email')}
                            placeholder={t('enterEmail')}
                            startAdornment={
                                <InputAdornment position="start">
                                    <EmailIcon />
                                </InputAdornment>
                            }
                        />
                    </div>
                    <div className="flex items-center justify-center gap-6">
                        <div>{t('leadCapture.haveYouFlownBefore')}</div>
                        <Controller
                            name="hasFlownBefore"
                            control={control}
                            render={({ field: { value, onChange, ...field }, fieldState }) => {
                                const hasError = Boolean(fieldState.error?.message || '');
                                return (
                                    <RadioGroup
                                        {...field}
                                        onChange={e => {
                                            onChange(e.target.value === 'true');
                                            setSharedLead(getValues());
                                        }}
                                        value={value || ''}
                                        className="flex-col">
                                        <div className="flex flex-row justify-around">
                                            <Radio
                                                value={true}
                                                label={
                                                    <span className="text-normal">{t('yes')}</span>
                                                }
                                                checked={value === true}
                                            />
                                            <Radio
                                                value={false}
                                                label={
                                                    <span className="text-normal">{t('no')}</span>
                                                }
                                                checked={value === false}
                                            />
                                        </div>
                                        {hasError && (
                                            <div className="border-t border-red-500 pt-0.5 text-xs text-red-500">
                                                {t(fieldState.error?.message || '')}
                                            </div>
                                        )}
                                    </RadioGroup>
                                );
                            }}
                        />
                    </div>
                </div>
                <div className="flex items-center justify-between">
                    <div className="text-xs text-gray-400">
                        {t('leadCapture.byClickingAgreeToBeContacted')}
                    </div>
                    <StyledButton loading={isLoading} type="submit" className="px-12">
                        {t('next')}
                    </StyledButton>
                </div>
            </div>
        </form>
    );
};
