import { AttachMoney, CurrencyBitcoin, CurrencyPound, Euro } from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';
import { checkIsCryptoCurrency, CURRENCY_KEY, exchangeManager } from './exchange-manager';
import { NumericFormat } from 'react-number-format';
import { SiEthereum } from 'react-icons/si';
import { SolanaIconSvg } from './SolanaIconSvg';
import { Tooltip } from '@mui/material';

export const CurrencyRenderer: React.FC<{
    // not coins
    usdAmount: number;
    asCurrency: CURRENCY_KEY;
    showIcon?: boolean;
    roundStep?: number;
}> = ({ usdAmount, asCurrency, showIcon = true, roundStep = 50 }) => {
    const { data: { isCrypto, roundedAmount } = {} } = useQuery(
        ['currency_renderer', { usdAmount, asCurrency }],
        async () => {
            const data = await exchangeManager.getCurrencyPrice(usdAmount, asCurrency);
            const isCrypto = checkIsCryptoCurrency(asCurrency);
            const roundedAmount = isCrypto ? data : Math.round(data / roundStep) * roundStep;
            return {
                isCrypto,
                roundedAmount,
            };
        },
    );

    return (
        <div className="inline-flex items-center">
            {showIcon && <CurrencyIcon currency={asCurrency} />}
            <Tooltip
                title={
                    <>
                        <NumericFormat
                            value={roundedAmount}
                            thousandSeparator
                            displayType="text"
                            decimalScale={
                                isCrypto ? CRYPTO_FRACTIONS_TO_SHOW : FIAT_FRACTIONS_TO_SHOW
                            }
                        />{' '}
                        {asCurrency}
                    </>
                }
                classes={{
                    tooltip: /*tw*/ 'bg-zinc-800 p-1 text-white',
                }}>
                <div>
                    <NumericFormat
                        value={roundedAmount}
                        thousandSeparator
                        displayType="text"
                        decimalScale={isCrypto ? CRYPTO_FRACTIONS_TO_SHOW : FIAT_FRACTIONS_TO_SHOW}
                    />
                </div>
            </Tooltip>
        </div>
    );
};

const CRYPTO_FRACTIONS_TO_SHOW = 4;
const FIAT_FRACTIONS_TO_SHOW = 0;

export const useCurrencyRender = (usdAmount: number, asCurrency: CURRENCY_KEY, roundStep = 50) => {
    const { data = { formattedString: '0', roundedAmount: 0 } } = useQuery(
        ['use_currency_renderer', { usdAmount, asCurrency, roundStep }],
        async () => {
            const data = await exchangeManager.getCurrencyPrice(usdAmount, asCurrency);
            const isCrypto = checkIsCryptoCurrency(asCurrency);
            const roundedAmount = isCrypto ? data : Math.round(data / roundStep) * roundStep;
            const formattedString = new Intl.NumberFormat('en-US', {
                maximumFractionDigits: isCrypto ? CRYPTO_FRACTIONS_TO_SHOW : FIAT_FRACTIONS_TO_SHOW,
            }).format(roundedAmount);
            return { formattedString, roundedAmount };
        },
    );
    return data;
};

export const CurrencyIcon: React.FC<{
    currency: CURRENCY_KEY;
    className?: string;
}> = ({ currency, className }) => {
    const getIcon = (currency: CURRENCY_KEY) => {
        if (currency === 'USD') {
            return <AttachMoney className={className} />;
        }
        if (currency === 'EUR') {
            return <Euro className={className} />;
        }
        if (currency === 'GBP') {
            return <CurrencyPound className={className} />;
        }
        if (currency === 'BTC') {
            return <CurrencyBitcoin className={className} />;
        }
        if (currency === 'ETH') {
            return <SiEthereum size={24} className={className} />;
        }
        if (currency === 'SOL') {
            return <SolanaIconSvg className={className} />;
        }
    };
    return <>{getIcon(currency)}</>;
};
