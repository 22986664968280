import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { stringify } from 'query-string';
import { toast } from 'react-toastify';

import {
    ServerErrorData,
    ApiError,
    ApiValidationError,
    ApiForbiddenError,
    toastifyValidationError,
} from './../error-manager';

import { IS_PROD_ENV, typedEnv } from './../../environment/typedEnv';

type AdditionalParams = {
    silent?: boolean;
};

// TODO idea: show global non-interactive overlay when count of pending requests 2+
// to avoid making new pending requests from UI

export class ApiService {
    private baseAxiosConfig: AxiosRequestConfig = {
        baseURL: typedEnv.REACT_APP_API_BASE_URL,
        paramsSerializer: params => stringify(params),
    };

    public axiosInstance: AxiosInstance;

    constructor(axiosConfig?: AxiosRequestConfig) {
        this.axiosInstance = axios.create(axiosConfig || this.baseAxiosConfig);

        if (axiosConfig) {
            this.baseAxiosConfig = { ...this.baseAxiosConfig, ...axiosConfig };
        }

        if (!axiosConfig) {
            // EVERY REQUEST
            this.axiosInstance.interceptors.request.use(
                async req => {
                    return req;
                },
                err => {
                    Promise.reject(err);
                },
            );

            // EVERY RESPONSE
            this.axiosInstance.interceptors.response.use(
                response => {
                    return response;
                },
                async (error: AxiosError<ServerErrorData>) => {
                    const { config, response } = error;
                    if (!response) {
                        toast.error('No connection with the server.');
                        throw new ApiError();
                    }

                    const { status } = response;

                    // VALIDATION SERVER ERROR
                    if (status === 400) {
                        toastifyValidationError(new ApiError(response.data));
                        throw new ApiValidationError(response.data);
                    }

                    if (status === 401) {
                        throw new ApiError(response.data);
                    }

                    if (status === 403) {
                        const err = new ApiForbiddenError(response.data);
                        const { errors } = err.serializeError();
                        errors.forEach(err => {
                            toast.error(err.message);
                        });
                        return Promise.reject(err);
                    }

                    // ANY OTHER SERVER ERROR
                    const params: AdditionalParams | undefined = config.params;
                    if (!IS_PROD_ENV) {
                        console.log('err', response.data);
                    }
                    const err = new ApiError(response.data);

                    if (!params?.silent) {
                        const { errors } = err.serializeError();

                        errors.forEach(err => {
                            toast.error(err.message || 'Unknown server error');
                        });
                    }
                    return Promise.reject(err);
                },
            );
        }
    }

    get<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this.axiosInstance.get<T>(url, config);
    }

    post<ResponseDto = unknown, RequestDto = unknown>(
        url: string,
        data?: RequestDto,
        config?: AxiosRequestConfig,
    ): Promise<AxiosResponse<ResponseDto>> {
        return this.axiosInstance.post<RequestDto, AxiosResponse<ResponseDto>>(url, data, config);
    }

    patch<ResponseDto = unknown, RequestDto = unknown>(
        url: string,
        data?: RequestDto,
        config?: AxiosRequestConfig,
    ): Promise<AxiosResponse<ResponseDto>> {
        return this.axiosInstance.patch<RequestDto, AxiosResponse<ResponseDto>>(url, data, config);
    }

    put<ResponseDto = unknown, RequestDto = unknown>(
        url: string,
        data?: RequestDto,
        config?: AxiosRequestConfig,
    ): Promise<AxiosResponse<ResponseDto>> {
        return this.axiosInstance.put<RequestDto, AxiosResponse<ResponseDto>>(url, data, config);
    }

    delete<T = unknown>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this.axiosInstance.delete(url, config);
    }
}
