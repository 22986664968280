import { ERROR_KEY } from 'constants/errors';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { object, string, InferType, boolean } from 'yup';

export const LeadFormSchema = object({
    name: string()
        .required(ERROR_KEY.REQUIRED_FIELD)
        .test('last-name-required', ERROR_KEY.LAST_NAME_REQUIRED, name => {
            return Boolean(name.match(/^[a-z\.]+ [a-z]+/i));
        }),
    email: string().email(ERROR_KEY.INVALID_EMAIL).required(ERROR_KEY.REQUIRED_FIELD),
    phone: string()
        .required(ERROR_KEY.REQUIRED_FIELD)
        .test('phone-number', ERROR_KEY.INVALID_PHONE_NUMBER, phone =>
            isValidPhoneNumber(phone || ''),
        ),
    comment: string(),
    hasFlownBefore: boolean().required(ERROR_KEY.REQUIRED_FIELD),
});

export type LeadFormSchema = InferType<typeof LeadFormSchema>;
