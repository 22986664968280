import { LegOutDto } from '../swagger-types';

/**
 * time e.g. "05:15"
 */
export const formatFlightDuration = (time: string) => {
    const [h = 0, min = 0] = time.split(':');
    return `${+h} h ${+min} min`;
};

export const renderPaxLeg = ({ startAirport, endAirport, duration }: LegOutDto) => {
    return `${startAirport.icaoCode} - ${endAirport.icaoCode} - ${formatFlightDuration(duration)}`;
};
