/**
 * This files includes class names used to track actions on WP site
 * for analytics purposes
 */

// for search form button
export const TRACKING_CLASSNAME_FOR_VIEW_AND_INQUIRY_BUTTON = 'view_estimate_inquire';

// for contact form button
export const TRACKING_CLASSNAME_FOR_SEND_INQUIRY_BUTTON = 'send_inquiry';

/**
 * for aircraft (from search results) button to open contact form
 * @param cabinSize user friendly type of aircraft like "LIGHT JET"
 */
export const getSEOClassNameForInquireButton = (cabinSize: string) =>
    `inquire_${normalizeCabinSize(cabinSize)}`;

const normalizeCabinSize = (size: string) => size.replaceAll(/\s+/gi, '_').toLowerCase();
